<template>
  <div v-if="pendingStatus || isLoading">
    <Loader />
  </div>
  <div class="layout-px-spacing">
    <form enctype="multipart/form-data" @submit.prevent="handleSubmit">
      <div class="row align-items-center">
        <div
          id="card_1"
          class="col-lg-6 offset-lg-3 layout-spacing layout-top-spacing"
        >
          <div class="statbox widget box box-shadow">
            <div class="widget-content widget-content-area">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">New Password</label>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control"
                  />
                  <small
                    v-if="errors.password"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.password[0] }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Confirm Password</label>
                  <input
                    v-model="password_confirmation"
                    type="password"
                    class="form-control"
                  />
                  <small
                    v-if="errors.password"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.password[0] }}</small
                  >
                </div>
              </div>
              <div class="col">
                <button type="button" class="btn btn-warning mr-2 btn-sm">
                  <router-link to="/dashboard">Cancel</router-link>
                </button>
                <button type="submit" class="btn btn-primary mr-2 btn-sm">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import { mapGetters, mapState } from "vuex";
import Request from "@/apis/Request";
import Notify from "../helpers/Notify";
export default {
  components: { Loader },
  data() {
    return {
      password: "",
      password_confirmation: "",
      errors: {},
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"]),
    ...mapState(["baseURL"])
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("user");
    let user = this.$store.getters.user;
    let id = Number(this.$route.params.id);
    if (user.user_type === "superAdmin" || user.id === id) {
      this.$router.push({ name: "Change_Password" });
      this.isLoading = false;
    } else {
      this.$router.push({ name: "NotFound" });
      this.isLoading = false;
    }
  },
  methods: {
    handleSubmit() {
      const data = {
        password: this.password,
        password_confirmation: this.password_confirmation
      };
      Request.POST_REQ(data, `/change-password/${this.user.id}`)
        .then(() => {
          Notify.success("Password Changed");
          this.$store.dispatch("userLogout");
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          console.log(err);
        });
    }
  }
};
</script>
