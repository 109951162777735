<template>
  <div v-if="pendingStatus || isLoading">
    <Loader />
  </div>
  <div v-else class="layout-px-spacing">
    <form
      enctype="multipart/form-data"
      @submit.prevent="handleSubmit"
      @change="errors.clear($event.target.name)"
    >
      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row mb-4">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="d-flex align-items-start justify-content-between">
                  <h4>Add Course Information</h4>
                  <router-link :to="{ name: 'Courses' }">
                    <button class="btn btn-info mb-2 mr-4 btn-rounded">
                      View Courses
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-eye"
                      >
                        <path
                          d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                        ></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </button>
                  </router-link>
                </div>

                <br />
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Select Institute</label>
                  <select
                    v-model="institute_id"
                    class="form-control"
                    name="institute_id"
                    @change="errors.clear('institute_id')"
                  >
                    <option disabled value=""
                      >Select Represent Institute</option
                    >
                    <option
                      v-for="institute in institutes"
                      :key="institute.id"
                      :value="institute.id"
                      >{{ institute.institute_name }}</option
                    >
                  </select>
                  <small
                    v-if="errors.has('institute_id')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("institute_id") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Course Name</label>
                  <input
                    v-model="course_name"
                    type="text"
                    class="form-control"
                    name="course_name"
                  />
                  <small
                    v-if="errors.has('course_name')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("course_name") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Course Category</label>
                  <select
                    v-model="course_category_id"
                    class="form-control"
                    @change="errors.clear('course_category_id')"
                  >
                    <option disabled value="">Select a Category</option>
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                      >{{ category.category_name }}</option
                    >
                  </select>
                  <small
                    v-if="errors.has('course_category_id')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("course_category_id") }}</small
                  >
                </div>
              </div>

              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Course Level</label>
                  <select
                    v-model="course_level_id"
                    class="form-control"
                    @change="errors.clear('course_level_id')"
                  >
                    <option disabled value="">Select Course Level</option>
                    <option
                      v-for="course_level in course_levels"
                      :key="course_level.id"
                      :value="course_level.id"
                      >{{ course_level.course_level_name }}</option
                    >
                  </select>
                  <small
                    v-if="errors.has('institute_id')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("institute_id") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Course Duration</label>
                  <input
                    v-model="course_duration"
                    type="text"
                    class="form-control"
                    name="course_duration"
                  />
                  <small
                    v-if="errors.has('course_duration')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("course_duration") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Course Intake</label>
                  <input
                    v-model="course_intake"
                    type="text"
                    class="form-control"
                    name="course_intake"
                  />
                  <small
                    v-if="errors.has('course_intake')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("course_intake") }}</small
                  >
                </div>
              </div>

              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Awarding Body</label>
                  <input
                    v-model="awarding_body"
                    type="text"
                    class="form-control"
                    name="awarding_body"
                  />
                  <small
                    v-if="errors.has('awarding_body')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("awarding_body") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1"
                    >Is Language Mendatory</label
                  >
                  <input
                    v-model="is_lang_mendatory"
                    type="text"
                    class="form-control"
                    placeholder="Yes or No"
                    name="is_lang_mendatory"
                  />
                  <small
                    v-if="errors.has('is_lang_mendatory')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("is_lang_mendatory") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1"
                    >Language Requirements</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="lang_requirements"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                    name="lang_requirements"
                  ></textarea>
                  <small
                    v-if="errors.has('lang_requirements')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("lang_requirements") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1"
                    >Course Benifits</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="course_benifits"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                    name="course_benifits"
                  ></textarea>
                  <small
                    v-if="errors.has('course_benifits')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("course_benifits") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1">
                    Part Time Work Details</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="per_time_work_details"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                    name="per_time_work_details"
                  ></textarea>
                  <small
                    v-if="errors.has('per_time_work_details')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("per_time_work_details") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1"
                    >General Eligibility</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="general_eligibility"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                    name="general_eligibility"
                  ></textarea>
                  <small
                    v-if="errors.has('general_eligibility')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("general_eligibility") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1">
                    Additional Information of Course</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="addtional_info_course"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                    name="addtional_info_course"
                  ></textarea>
                  <small
                    v-if="errors.has('addtional_info_course')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("addtional_info_course") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="row d-flex align-items-center">
                  <div class="col col-md-8">
                    <div class="form-group mb-4">
                      <label>Course Prospectus</label>
                      <label class="custom-file-container__custom-file">
                        <input
                          type="file"
                          class="form-control-file"
                          @change="onChangeProspectus"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="row d-flex align-items-center">
                  <div class="col col-md-8">
                    <div class="form-group mb-4">
                      <label>Course Module PDF</label>
                      <label class="custom-file-container__custom-file">
                        <input
                          type="file"
                          class="form-control-file"
                          @change="onChangeCourseModulePdf"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row mb-4">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <h4>Additional Information</h4>
                <br />
              </div>
              <div class="col col-md-12">
                <div
                  v-for="(course_additional, index) in course_additionals"
                  :key="index"
                  class="row"
                >
                  <div class="col col-md-10">
                    <div class="form-group mb-4">
                      <label for="personName"
                        >Course Additional Information ( If have )</label
                      >
                      <textarea
                        id="exampleFormControlTextarea1"
                        v-model="course_additional.course_additional_infos"
                        class="form-control"
                        rows="3"
                        spellcheck="false"
                        :name="
                          `course_additionals.${index}.course_additional_infos`
                        "
                      ></textarea>
                      <small
                        v-if="
                          errors.has(
                            `course_additionals.${index}.course_additional_infos`
                          )
                        "
                        id="sh-text1"
                        class="form-text text-danger"
                        >{{
                          errors.get(
                            `course_additionals.${index}.course_additional_infos`
                          )
                        }}</small
                      >
                    </div>
                  </div>

                  <div class="col col-md-12 text-right">
                    <div class="row ml-4">
                      <div v-show="index == course_additionals.length - 1">
                        <button
                          class="btn btn-warning mb-2 mr-2 btn-rounded"
                          @click.prevent="add"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-plus-circle"
                          >
                            <circle cx="12" cy="12" r="10" />
                            <line x1="12" y1="8" x2="12" y2="16" />
                            <line x1="8" y1="12" x2="16" y2="12" />
                          </svg>
                        </button>
                      </div>
                      <div
                        v-show="
                          index || (!index && course_additionals.length > 1)
                        "
                      >
                        <button
                          class="btn btn-danger mb-2 mr-2 btn-rounded"
                          @click.prevent="remove"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-x-circle"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="15" y1="9" x2="9" y2="15"></line>
                            <line x1="9" y1="9" x2="15" y2="15"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row mb-4">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <h4>Upload Additional Multiple file</h4>
                <br />
              </div>

              <div class="col col-md-12">
                <div class="form-group mb-4">
                  <div class="form-group mb-4">
                    <label class="custom-file-container__custom-file">
                      <input
                        ref="files"
                        type="file"
                        class="form-control-file"
                        multiple
                        @change="onChangeAdditionalFiles"
                      />
                    </label>
                    <div class="custom-file-container__image-preview"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row mb-4">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <h4>Key Modules</h4>
                <br />
              </div>
              <div class="col col-md-12">
                <div
                  v-for="(key_module, index) in key_modules"
                  :key="index"
                  class="row"
                >
                  <div class="col col-md-8">
                    <div class="form-group mb-4">
                      <label for="exampleFormControlInput1"
                        >Module Title {{ index + 1 }}</label
                      >
                      <input
                        v-model="key_module.module_title"
                        type="text"
                        class="form-control"
                        :name="`key_modules.${index}.module_title`"
                      />
                      <small
                        v-if="errors.has(`key_modules.${index}.module_title`)"
                        id="sh-text1"
                        class="form-text text-danger"
                        >{{
                          errors.get(`key_modules.${index}.module_title`)
                        }}</small
                      >
                    </div>
                    <div class="form-group mb-4">
                      <label for="personName"
                        >Module Description {{ index + 1 }}</label
                      >
                      <textarea
                        id="exampleFormControlTextarea1"
                        v-model="key_module.module_description"
                        class="form-control"
                        rows="3"
                        spellcheck="false"
                        :name="`key_modules.${index}.module_description`"
                      ></textarea>
                      <small
                        v-if="
                          errors.has(`key_modules.${index}.module_description`)
                        "
                        id="sh-text1"
                        class="form-text text-danger"
                        >{{
                          errors.get(`key_modules.${index}.module_description`)
                        }}</small
                      >
                    </div>
                  </div>

                  <div class="col col-md-12 text-right">
                    <div class="row ml-4">
                      <div v-show="index == key_modules.length - 1">
                        <button
                          class="btn btn-warning mb-2 mr-2 btn-rounded"
                          @click.prevent="add_key_modules"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-plus-circle"
                          >
                            <circle cx="12" cy="12" r="10" />
                            <line x1="12" y1="8" x2="12" y2="16" />
                            <line x1="8" y1="12" x2="16" y2="12" />
                          </svg>
                        </button>
                      </div>
                      <div v-show="index || (!index && key_modules.length > 1)">
                        <button
                          class="btn btn-danger mb-2 mr-2 btn-rounded"
                          @click.prevent="remove_key_modules"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-x-circle"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="15" y1="9" x2="9" y2="15"></line>
                            <line x1="9" y1="9" x2="15" y2="15"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row">
              <div class="col text-right">
                <div class="row">
                  <div class="col">
                    <router-link :to="{ name: 'Courses' }">
                      <button type="submit" class="btn btn-warning mr-2 btn-lg">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      type="submit"
                      class="btn btn-primary mr-2 btn-lg"
                      :disabled="errors.any()"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import { mapGetters } from "vuex";
import Notify from "../../helpers/Notify";
import Request from "../../apis/Request";

class Errors {
  constructor() {
    this.errors = {};
  }

  has(field) {
    return Object.prototype.hasOwnProperty.call(this.errors, field);
  }

  any() {
    return Object.keys(this.errors).length > 0;
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  record(errors) {
    this.errors = errors.errors;
  }

  clear(field) {
    delete this.errors[field];
  }
}

export default {
  name: "AddCourse",
  components: { Loader },
  data() {
    return {
      institute_id: "",
      course_name: "",
      course_category_id: "",
      course_level_id: "",
      course_duration: "",
      course_intake: "",
      awarding_body: "",
      is_lang_mendatory: "",
      lang_requirements: "",
      course_benifits: "",
      per_time_work_details: "",
      general_eligibility: "",
      addtional_info_course: "",
      course_prospectus: "",
      course_module_pdf: "",
      institutes: [],
      categories: [],
      course_levels: [],
      course_additionals: [
        {
          course_additional_infos: ""
        }
      ],
      files: [],
      key_modules: [
        {
          module_title: "",
          module_description: ""
        }
      ],

      errors: new Errors(),
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"])
  },

  mounted() {
    Request.GET_REQ("/all-active-institutes").then(res => {
      this.institutes = res.data;
    });

    Request.GET_REQ("/total-active-categories").then(res => {
      this.categories = res.data;
    });

    Request.GET_REQ("/total-active-course-level").then(res => {
      this.course_levels = res.data;
    });
  },

  methods: {
    handleSubmit() {
      this.isLoading = true;
      let data = new FormData();

      data.append("institute_id", this.institute_id);
      data.append("course_name", this.course_name);
      data.append("course_category_id", this.course_category_id);
      data.append("course_level_id", this.course_level_id);
      data.append("course_duration", this.course_duration);
      data.append("course_intake", this.course_intake);
      data.append("awarding_body", this.awarding_body);
      data.append("is_lang_mendatory", this.is_lang_mendatory);
      data.append("lang_requirements", this.lang_requirements);
      data.append("course_benifits", this.course_benifits);
      data.append("per_time_work_details", this.per_time_work_details);
      data.append("general_eligibility", this.general_eligibility);
      data.append("addtional_info_course", this.addtional_info_course);
      data.append("course_prospectus", this.course_prospectus);
      data.append("course_module_pdf", this.course_module_pdf);
      data.append(
        "course_additionals",
        JSON.stringify(this.course_additionals)
      );

      data.append("key_modules", JSON.stringify(this.key_modules));

      var self = this;
      for (let i = 0; i < this.files.length; i++) {
        let file = self.files[i];
        data.append("files[" + i + "]", file);
      }

      Request.POST_REQ(data, "/add-course")
        .then(() => {
          this.isLoading = false;
          this.files = [];

          this.$router.push({ name: "Courses" });
          Notify.success("The Course Successfully Created");
        })
        .catch(error => {
          this.errors.record(error.response.data);

          this.files = [];
          this.isLoading = false;

          this.errors.has("institute_id") &&
            Notify.error(this.errors.get("institute_id"));

          this.errors.has("institute_name") &&
            Notify.error(this.errors.get("institute_name"));

          this.errors.has("campus_name") &&
            Notify.error(this.errors.get("campus_name"));

          this.errors.has("course_category_id") &&
            Notify.error(this.errors.get("course_category_id"));

          this.errors.has("course_level_id") &&
            Notify.error(this.errors.get("course_level_id"));

          this.errors.has("course_duration") &&
            Notify.error(this.errors.get("course_duration"));

          this.errors.has("course_intake") &&
            Notify.error(this.errors.get("course_intake"));

          this.errors.has("awarding_body") &&
            Notify.error(this.errors.get("awarding_body"));

          this.errors.has("is_lang_mendatory") &&
            Notify.error(this.errors.get("is_lang_mendatory"));

          this.errors.has("lang_requirements") &&
            Notify.error(this.errors.get("lang_requirements"));

          this.errors.has("course_benifits") &&
            Notify.error(this.errors.get("course_benifits"));
          this.errors.has("per_time_work_details") &&
            Notify.error(this.errors.get("per_time_work_details"));

          this.errors.has("general_eligibility") &&
            Notify.error(this.errors.get("general_eligibility"));
          this.errors.has("addtional_info_course") &&
            Notify.error(this.errors.get("addtional_info_course"));
        });
    },
    onChangeProspectus(event) {
      let file = event.target.files[0];

      if (file.size > 1048770) {
        console.log("file is bigger than 2mb");
      } else {
        this.course_prospectus = event.target.files[0];
      }
    },
    onChangeCourseModulePdf(event) {
      let file = event.target.files[0];

      if (file.size > 1048770) {
        console.log("file is bigger than 2mb");
      } else {
        this.course_module_pdf = event.target.files[0];
      }
    },

    onChangeAdditionalFiles() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.files.push(this.$refs.files.files[i]);
      }
    },

    add() {
      this.course_additionals.push({
        course_additional_infos: ""
      });
    },
    remove(index) {
      this.course_additionals.splice(index, 1);
    },

    add_key_modules() {
      this.key_modules.push({
        module_title: "",
        module_description: ""
      });
    },
    remove_key_modules(index) {
      this.key_modules.splice(index, 1);
    }
  }
};
</script>
<style scoped lang="scss">
.component-card_1 {
  width: auto;
}
body.dark .office-logo img {
  border: 1px solid #1b2e4b;
}
.office-logo img {
  width: 100px;
  border: 1px solid #bfc9d4;
  border-radius: 5px;
  padding: 5px;
}
.office-logo {
  width: 100%;
  text-align: right;
}
</style>
