import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import AddBranch from "../views/branch/AddBranch.vue";
import EditBranch from "../views/branch/EditBranch.vue";
import Branches from "../views/branch/Branch.vue";
import Dashbaord from "../views/Dashboard.vue";
import NotFound from "../views/NotFound.vue";
import AddCounselor from '../views/counselor/AddCounselor.vue';
import Counselors from '../views/counselor/Counselor.vue';
import EditCounselor from '../views/counselor/EditCounselor.vue';
import AddCountry from '../views/country/AddCountry.vue';
import Countries from '../views/country/Countries.vue';
import EditCountry from '../views/country/EditCountry.vue';
import AddInstitute from '../views/institute/AddInstitute.vue';
import EditInstitute from '../views/institute/EditInstitute.vue';
import Institutes from '../views/institute/Institute.vue';
import Categories from '../views/category/Categories.vue';
import CourseLevel from '../views/course-level/CourseLevel.vue';
import AddCourse from '../views/courses/AddCourse.vue';
import EditCourse from '../views/courses/EditCourse.vue';
import Courses from '../views/courses/Courses.vue';
import LeadSource from '../views/leads/LeadSource.vue';
import AddLead from '../views/leads/AddLead.vue';
import EditLead from '../views/leads/EditLead.vue';
import Leads from '../views/leads/Leads.vue';
import AgentLeads from '../views/leads/AgentLeads.vue';
import ApplicationLeads from '../views/leads/ApplicationLeads.vue';
import SendApplicationEmail from '../views/leads/SendApplicationEmail.vue';
import ApplicationLeadInfo from '../views/leads/ApplicationLeadInfo.vue';
import LeadStatus from '../views/lead-status/LeadStatus.vue';
import ViewCountry from "../components/CountryDetails.vue";
import ViewInstitute from '../components/institutes/InstituteDetailsComponent.vue';
import AddFrontOffice from '../views/front-office/AddFrontOffice.vue';
import FrontOffices from '../views/front-office/FrontOffices.vue';
import EditFrontOffice from '../views/front-office/EditFrontOffice.vue';
import AddAgent from '../views/agent/AddAgent.vue';
import Agents from '../views/agent/Agents.vue';
import EditAgent from '../views/agent/EditAgent.vue';
import ChangePassword from '../components/ChangePassword.vue';
import ImportInstitute from '../components/import/InstituteImport.vue';
import ImportCourses from '../components/import/CoursesImport.vue';
import ImportLeads from '../components/import/LeadsImport.vue';
import Chat from '../views/chat/Chat.vue';

const requireAuthRole = (to, from, next) => {
  let user = localStorage.getItem("user");
      user = JSON.parse(user);
      if (user.user_type == 'superAdmin' || user.user_type == 'branchManager') {
        next();
      } else {
        next({
          path: "/dashboard",
          query: { redirect: to.fullPath }
        });
      }
};
const requireSuperAdminAuthRole = (to, from, next) => {
  let user = localStorage.getItem("user");
      user = JSON.parse(user);
      if (user.user_type == 'superAdmin') {
        next();
      } else {
        next({
          path: "/dashboard",
          query: { redirect: to.fullPath }
        });
      }
};
const counselorAuthRole = (to, from, next) => {
  let user = localStorage.getItem("user");
      user = JSON.parse(user);
      if ((user.user_type == 'superAdmin') || (user.user_type == 'branchManager') || (user.user_type == 'counselor')) {
        next();
      } else {
        next({
          path: "/dashboard",
          query: { redirect: to.fullPath }
        });
      }
};
const frontOffierAuthRole = (to, from, next) => {
  let user = localStorage.getItem("user");
      user = JSON.parse(user);
      if (user.user_type == 'superAdmin' || user.user_type == 'branchManager' || user.user_type == 'counselor' || user.user_type == 'frontOfficer') {
        next();
      } else {
        next({
          path: "/dashboard",
          query: { redirect: to.fullPath }
        });
      }
};



const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { guestOnly: true },
    redirect: "/login",
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { guestOnly: true }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { guestOnly: true }
  },
  {
    path: "/dashboard",
    name: "Dashbaord",
    component: Dashbaord,
    props: true,
    meta: { authOnly: true },
    
  },
  {
    path: "/add-branch",
    name: "AddBranch",
    component: AddBranch,
    meta: { authOnly: true },
    beforeEnter: requireSuperAdminAuthRole
  },
  {
    path: "/branches",
    name: "Branches",
    component: Branches,
    meta: { authOnly: true },
    beforeEnter: counselorAuthRole
  },
  {
    path: "/branches/:slug",
    name: "EditBranch",
    component: EditBranch,
    props: true,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },
  {
    path: "/add-counselor",
    name: "Add_Counselor",
    component: AddCounselor,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
    
  },
  {
    path: "/counselors",
    name: "Counselors",
    component: Counselors,
    meta: { authOnly: true },
    beforeEnter: counselorAuthRole
  },
  {
    path: "/counselor/:slug",
    name: "EditCounselor",
    component: EditCounselor,
    props: true,
    meta: { authOnly: true },
    beforeEnter: counselorAuthRole
  },
  {
    path: "/add-country",
    name: "Add_Country",
    component: AddCountry,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },
  {
    path: "/country/:slug",
    name: "Edit_Country",
    props: true,
    component: EditCountry,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },
  {
    path: "/country-view/:slug",
    name: "View_Country",
    props: true,
    component: ViewCountry,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },

  {
    path: "/countries",
    name: "Countries",
    component: Countries,
    meta: { authOnly: true }
  },
  {
    path: "/add-institute",
    name: "Add_Institute",
    component: AddInstitute,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },
  {
    path: "/institute/:id",
    name: "Edit_Institute",
    component: EditInstitute,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },
  {
    path: "/view-institute/:id",
    name: "View_Institute",
    component: ViewInstitute,
    meta: { authOnly: true },
    // beforeEnter: requireAuthRole
  },
  {
    path: "/institute",
    name: "Institutes",
    component: Institutes,
    meta: { authOnly: true }
  },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
    meta: { authOnly: true }
  },
  {
    path: "/course-level",
    name: "CourseLevel",
    component: CourseLevel,
    meta: { authOnly: true }
  },
  {
    path: "/add-course",
    name: "Add_Course",
    component: AddCourse,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },
  {
    path: "/course/:id",
    name: "Edit_Course",
    component: EditCourse,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },
  {
    path: "/courses",
    name: "Courses",
    component: Courses,
    meta: { authOnly: true },
    beforeEnter: frontOffierAuthRole
  },
  {
    path: "/lead-source",
    name: "Lead_Source",
    component: LeadSource,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },
  {
    path: "/add-lead",
    name: "Add_Lead",
    component: AddLead,
    meta: { authOnly: true },
    // beforeEnter: requireAuthRole
  },
  {
    path: "/leads",
    name: "Leads",
    component: Leads,
    meta: { authOnly: true },
    beforeEnter: frontOffierAuthRole
    // beforeEnter: requireAuthRole
  },
  {
    path: "/agent-leads",
    name: "AgentLeads",
    component: AgentLeads,
    meta: { authOnly: true },
    // beforeEnter: requireAuthRole
  },
  {
    path: "/application-leads",
    name: "Application_Leads",
    component: ApplicationLeads,
    meta: { authOnly: true },
    // beforeEnter: requireAuthRole
  },
  {
    path: "/leads/:id",
    name: "Edit_Lead",
    component: EditLead,
    meta: { authOnly: true },
    // beforeEnter: requireAuthRole
  },
  {
    path: "/email-leads/:id",
    name: "Send_Application_Email",
    component: SendApplicationEmail,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },
  {
    path: "/lead-status",
    name: "Lead_Status",
    component: LeadStatus,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },
  {
    path: "/application-lead-info/:id",
    name: "Application_Lead_Info",
    component: ApplicationLeadInfo,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },
  {
    path: "/add-front-office",
    name: "Add_Front_Office",
    component: AddFrontOffice,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },
  {
    path: "/front-offices",
    name: "Front_Offices",
    component: FrontOffices,
    meta: { authOnly: true },
    beforeEnter: counselorAuthRole
  },
  {
    path: "/front-offices/:slug",
    name: "Edit_Front_Offices",
    component: EditFrontOffice,
    meta: { authOnly: true },
    beforeEnter: frontOffierAuthRole
  },
  {
    path: "/add-agent",
    name: "Add_Agent",
    component: AddAgent,
    meta: { authOnly: true },
    beforeEnter: requireAuthRole
  },
  {
    path: "/agents",
    name: "Agents",
    component: Agents,
    meta: { authOnly: true },
    beforeEnter: counselorAuthRole
  },
  {
    path: "/agents/:slug",
    name: "Edit_Agent",
    component: EditAgent,
    meta: { authOnly: true },
    // beforeEnter: requireAuthRole
  },
  {
    path: "/change-password/:id",
    name: "Change_Password",
    component: ChangePassword,
    meta: { authOnly: true },
  },
  {
    path: "/import-institute",
    name: "Import_Institute",
    component: ImportInstitute,
    meta: { authOnly: true },
  },
  {
    path: "/import-courses",
    name: "Import_Courses",
    component: ImportCourses,
    meta: { authOnly: true },
  },
  {
    path: "/import-leads",
    name: "Import_Leads",
    component: ImportLeads,
    meta: { authOnly: true },
  },
  {
    path: "/chat",
    name: "Chat",
    component: Chat,
    meta: { authOnly: true },
  },

  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

function isLoggedIn() {
  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  return user.auth;
}

router.beforeEach((to, from, next) => {
  
  if (to.matched.some(record => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn()) {
      next({
        path: "/dashboard",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});


// superAdmin_branchManger();

export default router;
