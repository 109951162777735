<template>
  <div v-if="pendingStatus || isLoading">
    <Loader />
  </div>
  <div v-else class="layout-px-spacing">
    <div
      v-if="
        (user && user.user_type == 'superAdmin') ||
          (user && user.user_type == 'branchManager')
      "
      class="text-right layout-top-spacing"
    >
      <button
        v-if="categoryAddBtn == true"
        class="btn btn-info mb-2 mr-4 btn-rounded"
        @click="catAddBtn"
      >
        Add Category
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-plus-circle"
        >
          <circle cx="12" cy="12" r="10" />
          <line x1="12" y1="8" x2="12" y2="16" />
          <line x1="8" y1="12" x2="16" y2="12" />
        </svg>
      </button>

      <div
        v-if="isNewCategory == true"
        id="card_1"
        class="col-lg-12 layout-spacing layout-top-spacing"
      >
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div v-if="isNewCategory == true" class="row">
              <div class="col-md-9">
                <div>
                  <input
                    v-model="category_name"
                    class="form-control"
                    placeholder="Type New Category"
                    @keydown="errorClear"
                  />
                  <small
                    v-if="errors.category_name"
                    id="sh-text1"
                    class="form-text text-danger text-left"
                    >{{ errors.category_name[0] }}</small
                  >
                </div>
              </div>
              <div class="col-md-3">
                <button
                  class="btn btn-success mb-2 mr-4 btn-rounded"
                  @click="handleSubmit"
                >
                  Submit
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-plus-circle"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <line x1="12" y1="8" x2="12" y2="16" />
                    <line x1="8" y1="12" x2="16" y2="12" />
                  </svg>
                </button>

                <button
                  class="btn btn-warning mb-2 mr-4 btn-rounded"
                  @click="cancelBtn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="isEdit == true"
        id="card_1"
        class="col-lg-12 layout-spacing layout-top-spacing"
      >
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div v-if="isEdit == true" class="row">
              <div class="col-md-9">
                <div>
                  <input
                    id="scroll-target"
                    v-model="category_name"
                    class="form-control"
                    placeholder="Type New Category"
                    @keydown="errorClear"
                  />
                  <small
                    v-if="errors.category_name"
                    id="sh-text1"
                    class="form-text text-danger text-left"
                    >{{ errors.category_name[0] }}</small
                  >
                </div>
              </div>
              <div class="col-md-3">
                <button
                  v-if="isEdit == true"
                  class="btn btn-success mb-2 mr-4 btn-rounded"
                  @click="handleUdate"
                >
                  Update
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-plus-circle"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <line x1="12" y1="8" x2="12" y2="16" />
                    <line x1="8" y1="12" x2="16" y2="12" />
                  </svg>
                </button>

                <button
                  class="btn btn-warning mb-2 mr-4 btn-rounded"
                  @click="cancelBtn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
      <div class="statbox widget box box-shadow">
        <div class="widget-content widget-content-area">
          <div class="row">
            <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
              <div class="statbox widget box box-shadow">
                <div class="widget-header">
                  <div class="row">
                    <div class="col-xl-12 col-md-12 col-sm-12 col-12 p-0">
                      <h4>Category List</h4>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th class="text-center">#</th>
                        <th>Category Name</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th
                          v-if="
                            user.user_type == 'superAdmin' ||
                              user.user_type == 'branchManager'
                          "
                          class="text-center"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(category, index) in categories.data"
                        :key="index"
                      >
                        <td class="text-center">{{ index + 1 }}</td>
                        <td>{{ category.category_name }}</td>
                        <td>
                          <div class="text-left">
                            <span
                              :class="
                                category.active == 1
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                              >{{
                                category.active == 1 ? "Active" : "Deactive"
                              }}</span
                            >
                          </div>
                        </td>
                        <td>
                          <p class="text-secondary">
                            {{ dateTime(category.created_at) }}
                          </p>
                        </td>
                        <td
                          v-if="
                            user.user_type == 'superAdmin' ||
                              user.user_type == 'branchManager'
                          "
                          class="text-center"
                        >
                          <ul class="table-controls">
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit"
                                class="badge badge-pill badge-secondary custom-btn-branch mr-2"
                                @click="handleEdit(category.id)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-edit-3"
                                >
                                  <path d="M12 20h9"></path>
                                  <path
                                    d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"
                                  ></path>
                                </svg>
                              </a>
                            </li>
                            <li>
                              <label
                                class="switch s-icons s-outline s-outline-success mr-2"
                              >
                                <input
                                  type="checkbox"
                                  :checked="
                                    category && +category.active !== 1
                                      ? true
                                      : false
                                  "
                                  @change="processStatus(category.id)"
                                />
                                <span class="slider round"></span>
                              </label>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <div class="col col-md-12">
                    <div class="lms-pagination">
                      <pagination
                        v-model="page"
                        :records="total_categories"
                        :per-page="10"
                        class="custom-pagination"
                        @paginate="allCategories"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import Request from "../../apis/Request";
import Notify from "../../helpers/Notify";
export default {
  name: "Categories",
  components: {
    Loader
  },
  data() {
    return {
      categories: [],
      total_categories: 0,
      errors: {},
      isLoading: false,
      isNewCategory: false,
      isEdit: false,
      category_name: "",
      catid: "",
      page: 1,
      categoryAddBtn: true
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"]),
    ...mapState(["baseURL"])
  },

  mounted() {
    this.isLoading = true;
    this.allCategories();
    this.totalCategories();

    this.emitter.on("reload", () => {
      this.allCategories();
      this.totalCategories();
    });
  },
  methods: {
    handleSubmit() {
      Request.POST_REQ({ category_name: this.category_name }, "/add-category")
        .then(() => {
          this.category_name = "";
          this.emitter.emit("reload");
          Notify.success("Category Added");
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.errors.category_name &&
            Notify.error(this.errors.category_name[0]);
        });
    },
    dateTime(value) {
      return moment(value).format("MMMM Do YYYY, h:mm:ss a");
    },
    allCategories() {
      Request.GET_REQ(`/categories?page=${this.page}`)
        .then(res => {
          this.categories = res.data;
          this.isLoading = false;
          this.isNewCategory = false;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });
    },
    totalCategories() {
      Request.GET_REQ(`/total-categories`)
        .then(res => {
          this.total_categories = res.data.length;
          this.isLoading = false;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });
    },
    processStatus(id) {
      Request.GET_REQ(`/category-status/${id}`)
        .then(() => {
          this.emitter.emit("reload");
          Notify.success("Category Updated...");
        })
        .catch(err => {
          this.err && Notify.error(err.response.data.errors);
        });
    },
    handleEdit(id) {
      this.isNewCategory = false;
      this.isEdit = true;
      this.catid = id;

      if (this.catid > 0 && this.isEdit == true) {
        Request.GET_REQ(`/edit-category/${this.catid}`)
          .then(response => {
            this.category_name = response.data.category_name;
          })
          .catch(err => {
            console.log(err);
          });
      }
      let mange_app = document.getElementById("scroll-target");
      mange_app.scrollIntoView({ behavior: "smooth", block: "end" });
    },
    catAddBtn() {
      this.isNewCategory = true;
      this.isEdit = false;
      this.categoryAddBtn = false;
    },
    cancelBtn() {
      this.isNewCategory = false;
      this.isEdit = false;
      this.categoryAddBtn = true;
      this.category_name = "";
    },
    errorClear() {
      this.errors = "";
    },
    handleUdate() {
      Request.POST_REQ(
        { category_name: this.category_name },
        `/update-category/${this.catid}`
      )
        .then(() => {
          this.category_name = "";
          this.emitter.emit("reload");
          Notify.success("Category Updated...");
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.errors.category_name &&
            Notify.error(this.errors.category_name[0]);
        });
    }
  }
};
</script>
<style scoped lang="scss">
.component-card_4 {
  width: auto;
  .user-info {
    width: 100%;
    padding: 5px 0 20px 0px;
  }
  .logo-title-wrap {
    img {
      border-radius: 50%;
      height: 60px;
    }
  }
  .custom-btn-branch {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  .custom-list-group-branch .list-group-item {
    background: transparent;
    padding: 0;
    border: unset;
    position: relative;
    margin: 7px 0;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background: #e0e6ed;
      left: 0;
      bottom: -7px;
    }
    &::last-child::before {
      background: transparent !important;
      content: unset;
    }
    h6 {
      margin: 0;
      position: relative;
      padding-left: 20px;
      font-size: 14px;

      &::before {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        left: 0;
        border-radius: 3px;
      }
    }
    h6.count-primary {
      &::before {
        background: #1b55e2;
      }
    }
    h6.count-info {
      &::before {
        background: #2196f3;
      }
    }
    h6.count-success {
      &::before {
        background: #8dbf42;
      }
    }
    h6.count-warning {
      &::before {
        background: #e2a03f;
      }
    }
    h6.count-danger {
      &::before {
        background: #e7515a;
      }
    }
    h6.count-secondary {
      &::before {
        background: #5c1ac3;
      }
    }
  }
  .card-user_name {
    font-size: 24px;
  }
}

body.dark .component-card_4 {
  .custom-list-group-branch .list-group-item {
    &::before {
      background: #1b2e4b;
    }
  }
}

ul.list-group.custom-list-group-branch li:last-child:before {
  background: transparent !important;
}
.switch.s-icons {
  height: 7px;
}
</style>
