<template>
  <div v-if="pendingStatus || isLoading">
    <Loader />
  </div>
  <div v-else class="layout-px-spacing">
    <form
      enctype="multipart/form-data"
      @submit.prevent="handleUpdate"
      @change="errors.clear($event.target.name)"
    >
      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row mb-4">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="d-flex align-items-start justify-content-between">
                  <h4>Edit Lead</h4>
                  <router-link :to="{ name: 'Leads' }">
                    <button class="btn btn-info mb-2 mr-4 btn-rounded">
                      View Leads
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-eye"
                      >
                        <path
                          d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                        ></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </button>
                  </router-link>
                </div>

                <br />
              </div>
              <div v-if="user && user.user_type == 'superAdmin'" class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Select Branch</label>
                  <select
                    v-model="branch_id"
                    class="form-control"
                    @change="onChangeBranch($event)"
                  >
                    <option disabled value="">Select a Branch</option>
                    <option
                      v-for="branch in branches"
                      :key="branch.id"
                      :value="branch.id"
                      >{{ branch.branch_name }}</option
                    >
                  </select>
                  <small
                    v-if="errors.has('branch_id')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("branch_id") }}</small
                  >
                </div>
              </div>
              <div
                v-if="
                  (user && user.user_type == 'superAdmin') ||
                    (user && user.user_type == 'branchManager')
                "
                class="col"
              >
                <div class="form-group mb-4">
                  <label
                    for="exampleFormControlInput1"
                    class="d-flex align-center justify-content-between"
                    >Counserlor Name
                    <div class="d-flex align-center justify-content-between">
                      <span v-if="lazyLoadBranch">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-loader spin mr-2"
                        >
                          <line x1="12" y1="2" x2="12" y2="6"></line>
                          <line x1="12" y1="18" x2="12" y2="22"></line>
                          <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                          <line
                            x1="16.24"
                            y1="16.24"
                            x2="19.07"
                            y2="19.07"
                          ></line>
                          <line x1="2" y1="12" x2="6" y2="12"></line>
                          <line x1="18" y1="12" x2="22" y2="12"></line>
                          <line
                            x1="4.93"
                            y1="19.07"
                            x2="7.76"
                            y2="16.24"
                          ></line>
                          <line
                            x1="16.24"
                            y1="7.76"
                            x2="19.07"
                            y2="4.93"
                          ></line>
                        </svg>
                      </span>
                      <small
                        v-if="user && user.user_type == 'superAdmin'"
                        v-show="lazyLoadBranch"
                        class="form-text text-info"
                        >Please Select a Branch</small
                      >
                    </div>
                  </label>
                  <select
                    v-model="counselor_id"
                    class="form-control"
                    :disabled="lazyLoadBranch"
                    @change="errors.clear('counselor_id')"
                  >
                    <option disabled value="">Select a Counselor</option>
                    <option
                      v-for="counselor in counselors"
                      :key="counselor.id"
                      :value="counselor.id"
                      >{{ counselor.counselor_name }}</option
                    >
                  </select>
                  <small
                    v-if="errors.has('counselor_id')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("counselor_id") }}</small
                  >
                </div>
              </div>
              <div
                v-if="
                  (user && user.user_type == 'superAdmin') ||
                    (user && user.user_type == 'branchManager') ||
                    (user && user.user_type == 'counselor') ||
                    (user && user.user_type == 'frontOfficer')
                "
                class="col"
              >
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1"
                    >Lead Source
                    <span v-if="lazyLoading">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-loader spin mr-2"
                      >
                        <line x1="12" y1="2" x2="12" y2="6"></line>
                        <line x1="12" y1="18" x2="12" y2="22"></line>
                        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                        <line
                          x1="16.24"
                          y1="16.24"
                          x2="19.07"
                          y2="19.07"
                        ></line>
                        <line x1="2" y1="12" x2="6" y2="12"></line>
                        <line x1="18" y1="12" x2="22" y2="12"></line>
                        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                        <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                      </svg> </span
                  ></label>
                  <select
                    v-model="lead_source_id"
                    class="form-control"
                    :disabled="lazyLoading"
                    @change="errors.clear('lead_source_id')"
                  >
                    <option disabled value="">Select a Source</option>
                    <option
                      v-for="lead_source in lead_sources"
                      :key="lead_source.id"
                      :value="lead_source.id"
                      >{{ lead_source.source_name }}</option
                    >
                  </select>
                  <small
                    v-if="errors.has('lead_source_id')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("lead_source_id") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Category</label>
                  <select
                    v-model="category_id"
                    class="form-control"
                    @change="errors.clear('category_id')"
                  >
                    <option disabled value="">Select a Category</option>
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                      :disabled="lazyLoading"
                      >{{ category.category_name }}</option
                    >
                  </select>
                  <small
                    v-if="errors.has('category_id')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("category_id") }}</small
                  >
                </div>
              </div>

              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Course Level</label>
                  <select
                    v-model="course_level_id"
                    class="form-control"
                    @change="errors.clear('course_level_id')"
                  >
                    <option disabled value="">Select a Course Level</option>
                    <option
                      v-for="courselevel in courselevels"
                      :key="courselevel.id"
                      :value="courselevel.id"
                      :disabled="lazyLoading"
                      >{{ courselevel.course_level_name }}</option
                    >
                  </select>
                  <small
                    v-if="errors.has('course_level_id')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("course_level_id") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Intake Year</label>
                  <input
                    v-model="intake_info"
                    type="month"
                    class="form-control"
                    name="intake_info"
                  />
                  <small
                    v-if="errors.has('intake_info')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("intake_info") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">First Name</label>
                  <input
                    v-model="first_name"
                    type="text"
                    class="form-control"
                    name="first_name"
                  />
                  <small
                    v-if="errors.has('first_name')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("first_name") }}</small
                  >
                </div>
              </div>

              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Last Name</label>
                  <input
                    v-model="last_name"
                    type="text"
                    class="form-control"
                    name="last_name"
                  />
                  <small
                    v-if="errors.has('last_name')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("last_name") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Phone</label>
                  <input
                    v-model="phone"
                    type="text"
                    class="form-control"
                    name="phone"
                  />
                  <small
                    v-if="errors.has('phone')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("phone") }}</small
                  >
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Email</label>
                  <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    name="email"
                  />
                  <small
                    v-if="errors.has('email')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("email") }}</small
                  >
                </div>
              </div>

              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Alternate Phone</label>
                  <input
                    v-model="alternate_phone"
                    type="text"
                    class="form-control"
                    name="alternate_phone"
                  />
                  <small
                    v-if="errors.has('alternate_phone')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("alternate_phone") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Date of Birth</label>
                  <input
                    v-model="data_of_birth"
                    type="date"
                    class="form-control"
                    name="data_of_birth"
                  />
                  <small
                    v-if="errors.has('data_of_birth')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("data_of_birth") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Living Country</label>
                  <select
                    v-model="p_address_country"
                    class="form-control"
                    @change="errors.clear('p_address_country')"
                  >
                    <option disabled value="">Select a Country</option>
                    <option
                      v-for="countri in allcountries"
                      :key="countri.id"
                      :value="countri.id"
                      >{{ countri.name }}</option
                    >
                  </select>
                  <small
                    v-if="errors.has('p_address_country')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("p_address_country") }}</small
                  >
                </div>
              </div>

              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Living City</label>
                  <input
                    v-model="p_address_city"
                    type="text"
                    class="form-control"
                    name="p_address_city"
                  />
                  <small
                    v-if="errors.has('p_address_city')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("p_address_city") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col mr-0 pr-0">
                    <div class="form-group mb-4">
                      <label for="exampleFormControlInput1">Living State</label>
                      <input
                        v-model="p_address_state"
                        type="text"
                        class="form-control"
                        name="p_address_state"
                      />
                      <small
                        v-if="errors.has('p_address_state')"
                        id="sh-text1"
                        class="form-text text-danger"
                        >{{ errors.get("p_address_state") }}</small
                      >
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group mb-4">
                      <label for="exampleFormControlInput1">Zip Code</label>
                      <input
                        v-model="p_address_zip"
                        type="text"
                        class="form-control"
                        name="p_address_zip"
                      />
                      <small
                        v-if="errors.has('p_address_zip')"
                        id="sh-text1"
                        class="form-text text-danger"
                        >{{ errors.get("p_address_zip") }}</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1"
                    >Address Details</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="p_address_details"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                    name="p_address_details"
                  ></textarea>
                  <small
                    v-if="errors.has('p_address_details')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("p_address_details") }}</small
                  >
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col col-md-4">
                <div class="row d-flex align-items-center">
                  <div class="col col-md-8">
                    <div class="form-group mb-4">
                      <label>Photo</label>
                      <label class="custom-file-container__custom-file">
                        <input
                          type="file"
                          class="form-control-file"
                          accept="image/*"
                          name="photo"
                          @change="onFileChange"
                        />
                      </label>
                      <small
                        v-if="errors.has('photo')"
                        id="sh-text1"
                        class="form-text text-danger"
                        >{{ errors.get("photo") }}</small
                      >
                      <div class="custom-file-container__image-preview"></div>
                    </div>
                  </div>
                  <div class="col col-md-4">
                    <div v-if="photo" class="office-logo">
                      <img alt="Application Photo" :src="baseURL + photo" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col col-md-8">
                <div class="row d-flex align-items-center">
                  <div class="col col-md-12">
                    <div class="form-group mb-4">
                      <label
                        >Multiple Files Upload ( SOP/IELTS/TOFEL/PTE/GMAT
                        Certificate/ Work Experience/ Reference Letter/
                        Additional Documents )
                      </label>
                      <label class="custom-file-container__custom-file">
                        <input
                          ref="files"
                          type="file"
                          class="form-control-file"
                          accept="image/*"
                          name="files"
                          multiple
                          @change="onChangeMultipleFiles"
                        />
                      </label>
                      <small
                        v-if="errors.has('files')"
                        id="sh-text1"
                        class="form-text text-danger"
                        >{{ errors.get("files") }}</small
                      >
                      <div class="custom-file-container__image-preview"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row mb-4">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <h4>Interested Course Information</h4>
                <br />
              </div>
              <div class="col col-md-12">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1"
                    >Interested Country</label
                  >
                  <select
                    v-model="interested_country_id"
                    class="form-control"
                    @change="onChangeInterestCountry($event)"
                  >
                    <option disabled value="">Select a Country</option>
                    <option
                      v-for="countri in countries"
                      :key="countri.id"
                      :value="countri.id"
                      >{{ countri.country.name }}</option
                    >
                  </select>
                  <small
                    v-if="errors.has('interested_country_id')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("interested_country_id") }}</small
                  >
                </div>
              </div>
              <div class="col col-md-12">
                <div class="row">
                  <div class="col col-md-6">
                    <div class="form-group mb-4">
                      <label for="personName"
                        >Interested Universities
                        <span v-if="lazyLoadIns">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-loader spin mr-2"
                          >
                            <line x1="12" y1="2" x2="12" y2="6"></line>
                            <line x1="12" y1="18" x2="12" y2="22"></line>
                            <line
                              x1="4.93"
                              y1="4.93"
                              x2="7.76"
                              y2="7.76"
                            ></line>
                            <line
                              x1="16.24"
                              y1="16.24"
                              x2="19.07"
                              y2="19.07"
                            ></line>
                            <line x1="2" y1="12" x2="6" y2="12"></line>
                            <line x1="18" y1="12" x2="22" y2="12"></line>
                            <line
                              x1="4.93"
                              y1="19.07"
                              x2="7.76"
                              y2="16.24"
                            ></line>
                            <line
                              x1="16.24"
                              y1="7.76"
                              x2="19.07"
                              y2="4.93"
                            ></line>
                          </svg> </span
                      ></label>
                      <vue-select
                        v-model="institute_id_1"
                        :options="institutes_1"
                        label-by="institute_name"
                        close-on-select
                        placeholder="Select a Country Before"
                        search-placeholder="Select a University"
                        searchable
                        dropdown-item
                        value-by="id"
                        @update:modelValue="onChangeUniversity(1)"
                      ></vue-select>
                    </div>
                  </div>
                  <div class="col col-md-6">
                    <div class="form-group mb-4">
                      <label for="email">Interested Course</label>

                      <vue-select
                        v-model="course_id_1"
                        :options="courses"
                        label-by="course_name"
                        close-on-select
                        placeholder="Select a University Before"
                        search-placeholder="Select a Course"
                        searchable
                        dropdown-item
                        value-by="id"
                      ></vue-select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col col-md-12">
                <div class="row">
                  <div class="col col-md-6">
                    <div class="form-group mb-4">
                      <label for="personName"
                        >Interested Universities ( 2nd Choice )
                        <span v-if="lazyLoadIns">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-loader spin mr-2"
                          >
                            <line x1="12" y1="2" x2="12" y2="6"></line>
                            <line x1="12" y1="18" x2="12" y2="22"></line>
                            <line
                              x1="4.93"
                              y1="4.93"
                              x2="7.76"
                              y2="7.76"
                            ></line>
                            <line
                              x1="16.24"
                              y1="16.24"
                              x2="19.07"
                              y2="19.07"
                            ></line>
                            <line x1="2" y1="12" x2="6" y2="12"></line>
                            <line x1="18" y1="12" x2="22" y2="12"></line>
                            <line
                              x1="4.93"
                              y1="19.07"
                              x2="7.76"
                              y2="16.24"
                            ></line>
                            <line
                              x1="16.24"
                              y1="7.76"
                              x2="19.07"
                              y2="4.93"
                            ></line>
                          </svg> </span
                      ></label>
                      <vue-select
                        v-model="institute_id_2"
                        :options="institutes_2"
                        label-by="institute_name"
                        close-on-select
                        placeholder="Select a Country Before"
                        search-placeholder="Select a University"
                        searchable
                        dropdown-item
                        value-by="id"
                        @update:modelValue="onChangeUniversity(2)"
                      ></vue-select>
                    </div>
                  </div>
                  <div class="col col-md-6">
                    <div class="form-group mb-4">
                      <label for="email"
                        >Interested Course ( 2nd Choice )</label
                      >

                      <vue-select
                        v-model="course_id_2"
                        :options="courses_2"
                        label-by="course_name"
                        close-on-select
                        placeholder="Select a University Before"
                        search-placeholder="Select a Course"
                        searchable
                        dropdown-item
                        value-by="id"
                      ></vue-select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col col-md-12">
                <div class="row">
                  <div class="col col-md-6">
                    <div class="form-group mb-4">
                      <label for="personName"
                        >Interested Universities ( 3rd Choice )
                        <span v-if="lazyLoadIns">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-loader spin mr-2"
                          >
                            <line x1="12" y1="2" x2="12" y2="6"></line>
                            <line x1="12" y1="18" x2="12" y2="22"></line>
                            <line
                              x1="4.93"
                              y1="4.93"
                              x2="7.76"
                              y2="7.76"
                            ></line>
                            <line
                              x1="16.24"
                              y1="16.24"
                              x2="19.07"
                              y2="19.07"
                            ></line>
                            <line x1="2" y1="12" x2="6" y2="12"></line>
                            <line x1="18" y1="12" x2="22" y2="12"></line>
                            <line
                              x1="4.93"
                              y1="19.07"
                              x2="7.76"
                              y2="16.24"
                            ></line>
                            <line
                              x1="16.24"
                              y1="7.76"
                              x2="19.07"
                              y2="4.93"
                            ></line>
                          </svg> </span
                      ></label>
                      <vue-select
                        v-model="institute_id_3"
                        :options="institutes_3"
                        label-by="institute_name"
                        close-on-select
                        placeholder="Select a Country Before"
                        search-placeholder="Select a University"
                        searchable
                        dropdown-item
                        value-by="id"
                        @update:modelValue="onChangeUniversity(3)"
                      ></vue-select>
                    </div>
                  </div>
                  <div class="col col-md-6">
                    <div class="form-group mb-4">
                      <label for="email"
                        >Interested Course ( 3rd Choice )</label
                      >

                      <vue-select
                        v-model="course_id_3"
                        :options="courses_3"
                        label-by="course_name"
                        close-on-select
                        placeholder="Select a University Before"
                        search-placeholder="Select a Course"
                        searchable
                        dropdown-item
                        value-by="id"
                      ></vue-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div
            class="widget-content widget-content-area text-center"
            :class="lead_application_status == 1 ? 'app-success-bg' : ''"
          >
            <div class="row mb-4">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <h4>Form is Ready To Go with Application ?</h4>
                <br />
                <div class="n-chk">
                  <label
                    class="new-control new-radio square-radio new-radio-text radio-success"
                  >
                    <input
                      v-model="lead_application_status"
                      type="radio"
                      class="new-control-input"
                      name="application_status"
                      value="1"
                    />
                    <span class="new-control-indicator"></span
                    ><span class="new-radio-content">Yes</span>
                  </label>

                  <label
                    class="new-control new-radio square-radio new-radio-text radio-danger"
                  >
                    <input
                      v-model="lead_application_status"
                      type="radio"
                      class="new-control-input"
                      name="application_status"
                      value="0"
                    />
                    <span class="new-control-indicator"></span
                    ><span class="new-radio-content">No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row">
              <div class="col text-right">
                <div class="row">
                  <div class="col">
                    <router-link :to="{ name: 'Leads' }">
                      <button type="submit" class="btn btn-warning mr-2 btn-lg">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      type="submit"
                      class="btn btn-primary mr-2 btn-lg"
                      :disabled="errors.any()"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import { mapGetters, mapState } from "vuex";
import Notify from "../../helpers/Notify";
import Request from "../../apis/Request";
import baseURL from "../../apis/baseURL";

class Errors {
  constructor() {
    this.errors = {};
  }

  has(field) {
    return Object.prototype.hasOwnProperty.call(this.errors, field);
  }

  any() {
    return Object.keys(this.errors).length > 0;
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  record(errors) {
    this.errors = errors.errors;
  }

  clear(field) {
    delete this.errors[field];
  }
}

export default {
  name: "EditLead",
  components: { Loader },
  data() {
    return {
      branch_id: "",
      counselor_id: "",
      front_office_id: "",
      agent_id: "",
      lead_source_id: "",
      category_id: "",
      course_level_id: "",
      intake_info: "",
      lead_application_status: 0,
      first_name: "",
      last_name: "",
      data_of_birth: "",
      email: "",
      phone: "",
      alternate_phone: "",
      photo: null,
      p_address_country: "",
      p_address_city: "",
      p_address_state: "",
      p_address_zip: "",
      p_address_details: "",
      interested_country_id: "",
      files: [],
      countries: [],
      allcountries: [],
      branches: [],
      counselors: [],
      lead_sources: [],
      categories: [],
      courselevels: [],
      institutes_1: [],
      institutes_2: [],
      institutes_3: [],
      courses: [],
      courses_2: [],
      courses_3: [],
      interests: [
        {
          institute_id: "",
          course_id: ""
        }
      ],

      institute_id_1: "",
      institute_id_2: "",
      institute_id_3: "",
      course_id_1: "",
      course_id_2: "",
      course_id_3: "",

      errors: new Errors(),
      isLoading: false,
      lazyLoading: false,
      lazyLoadBranch: false,
      lazyLoadIns: false,
      getOwnBranchId: ""
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"]),
    ...mapState(["baseURL"])
  },
  watch: {
    interested_country_id: {
      handler() {
        let countryId = this.interested_country_id;
        Request.GET_REQ(`/country-institutes/${countryId}`).then(res => {
          this.institutes_1 = res.data;
          this.institutes_2 = res.data;
          this.institutes_3 = res.data;
          this.lazyLoadIns = false;
        });
        this.errors.clear("interested_country_id");
      }
    },
    institute_id_1: {
      handler() {
        let universityId = this.institute_id_1;
        Request.GET_REQ(`/institute-course/${universityId}`)
          .then(res => {
            this.courses = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    institute_id_2: {
      handler() {
        let universityId = this.institute_id_2;
        Request.GET_REQ(`/institute-course/${universityId}`)
          .then(res => {
            this.courses_2 = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    institute_id_3: {
      handler() {
        let universityId = this.institute_id_3;
        Request.GET_REQ(`/institute-course/${universityId}`)
          .then(res => {
            this.courses_3 = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    branch_id: {
      handler() {
        let branchId = this.branch_id;
        Request.GET_REQ(`/branch-counselor/${branchId}`).then(res => {
          this.counselors = res.data;
          this.lazyLoadBranch = false;
        });
        this.errors.clear("branch_id");
      }
    }
  },

  async mounted() {
    this.isLoading = true;
    const id = this.$route.params.id;

    await Request.GET_REQ(`/edit-lead/${id}`)
      .then(res => {
        this.getOwnBranchId = +res.data.branch_id;
      })
      .catch(err => {
        console.log(err);
      });

    if (
      this.user.user_type === "superAdmin" ||
      this.getOwnBranchId === +this.user.branch_id
    ) {
      this.lazyLoadBranch = true;
      this.lazyLoading = true;
      await this.$store.dispatch("user");
      let user = this.$store.state.user;

      if (user.user_type == "superAdmin") {
        this.lazyLoadBranch = true;
      } else {
        Request.GET_REQ(`/branch-counselor/${user.branch_id}`).then(res => {
          this.counselors = res.data;
          this.lazyLoadBranch = false;
        });
      }

      Request.GET_REQ("/active-lead-sources").then(res => {
        this.lead_sources = res.data;
        this.lazyLoading = false;
      });

      Request.GET_REQ("/active-represent-countries").then(res => {
        this.countries = res.data;
      });
      Request.GET_REQ("/countries").then(res => {
        this.allcountries = res.data;
      });

      Request.GET_REQ("/active-categories").then(res => {
        this.categories = res.data;
      });

      Request.GET_REQ("/active-branches").then(res => {
        this.branches = res.data;
      });

      Request.GET_REQ("/active-course-levels").then(res => {
        this.courselevels = res.data;
      });

      await Request.GET_REQ(`/edit-lead/${id}`)
        .then(res => {
          (this.branch_id = res.data.branch_id),
            (this.counselor_id = res.data.counselor_id),
            (this.front_office_id = res.data.front_office_id),
            (this.agent_id = res.data.agent_id),
            (this.lead_source_id = res.data.lead_source_id),
            (this.category_id = res.data.category_id),
            (this.course_level_id = res.data.course_level_id),
            (this.intake_info = res.data.intake_info),
            (this.p_address_zip = res.data.p_address_zip),
            (this.lead_application_status = res.data.lead_application_status),
            (this.first_name = res.data.first_name),
            (this.last_name = res.data.last_name),
            (this.data_of_birth = res.data.data_of_birth),
            (this.email = res.data.email),
            (this.phone = res.data.phone),
            (this.alternate_phone = res.data.alternate_phone),
            (this.photo = res.data.photo),
            (this.p_address_country = res.data.p_address_country),
            (this.p_address_city = res.data.p_address_city),
            (this.p_address_state = res.data.p_address_state),
            (this.p_address_zip = res.data.p_address_zip),
            (this.p_address_details = res.data.p_address_details),
            (this.interested_country_id = res.data.interested_country_id),
            (this.isLoading = false);
        })
        .catch(err => {
          console.log(err);
        });

      await Request.GET_REQ(`/edit-lead-interested-course/${id}`)
        .then(res => {
          (this.institute_id_1 = res.data[0] ? res.data[0].institute : null),
            (this.institute_id_2 = res.data[1] ? res.data[1].institute : null),
            (this.institute_id_3 = res.data[2] ? res.data[2].institute : null),
            setTimeout(() => {
              (this.course_id_1 = res.data[0] ? res.data[0].course : null),
                (this.course_id_2 = res.data[1] ? res.data[1].course : null),
                (this.course_id_3 = res.data[2] ? res.data[2].course : null);
            }, 3000);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.$router.push({ name: "Leads" });
    }
  },

  methods: {
    handleUpdate() {
      const id = this.$route.params.id;
      this.isLoading = true;
      let user = this.$store.state.user;
      this.$store.dispatch("baseurl", baseURL.baseURL);

      let data = new FormData();

      data.append("branch_id", this.branch_id || user.branch_id);
      data.append("counselor_id", JSON.stringify(this.counselor_id));
      data.append("front_office_id", JSON.stringify(this.front_office_id));
      data.append("agent_id", JSON.stringify(this.agent_id));
      data.append("lead_source_id", JSON.stringify(this.lead_source_id));
      data.append("category_id", JSON.stringify(this.category_id));
      data.append("course_level_id", JSON.stringify(this.course_level_id));
      data.append("intake_info", JSON.stringify(this.intake_info));
      data.append("p_address_zip", JSON.stringify(this.p_address_zip));
      data.append(
        "lead_application_status",
        JSON.stringify(this.lead_application_status)
      );
      data.append("first_name", JSON.stringify(this.first_name));
      data.append("last_name", JSON.stringify(this.last_name));
      data.append("data_of_birth", JSON.stringify(this.data_of_birth));
      data.append("email", JSON.stringify(this.email));
      data.append("phone", JSON.stringify(this.phone));
      data.append("alternate_phone", JSON.stringify(this.alternate_phone));
      data.append("photo", JSON.stringify(this.photo));
      data.append("p_address_country", JSON.stringify(this.p_address_country));
      data.append("p_address_city", JSON.stringify(this.p_address_city));
      data.append("p_address_state", JSON.stringify(this.p_address_state));
      data.append("p_address_details", JSON.stringify(this.p_address_details));
      data.append(
        "interested_country_id",
        JSON.stringify(this.interested_country_id)
      );
      data.append("institute_id_1", JSON.stringify(this.institute_id_1));
      data.append("course_id_1", JSON.stringify(this.course_id_1));
      data.append("institute_id_2", JSON.stringify(this.institute_id_2));
      data.append("course_id_2", JSON.stringify(this.course_id_2));
      data.append("institute_id_3", JSON.stringify(this.institute_id_3));
      data.append("course_id_3", JSON.stringify(this.course_id_3));
      // data.append("interests", JSON.stringify(this.interests));

      var self = this;
      for (let i = 0; i < this.files.length; i++) {
        const file = self.files[i];
        data.append("files[" + i + "]", file);
      }

      Request.POST_REQ(data, `/update-lead/${id}`)
        .then(() => {
          this.isLoading = false;
          this.files = [];
          // this.resetForm();
          this.$router.push({ name: "Leads" });
          Notify.success("The Lead Successfully Updated");
        })
        .catch(error => {
          this.errors.record(error.response.data);
          console.log(this.errors);
          this.files = [];

          this.isLoading = false;

          this.errors.has("first_name") &&
            Notify.error(this.errors.get("first_name"));
          this.errors.has("last_name") &&
            Notify.error(this.errors.get("last_name"));
          this.errors.has("phone") && Notify.error(this.errors.get("phone"));
        });
    },
    onChangeBranch(event) {
      this.lazyLoadBranch = true;
      let branchId = event.target.value;
      Request.GET_REQ(`/branch-counselor/${branchId}`).then(res => {
        this.counselors = res.data;
        this.lazyLoadBranch = false;
      });
      this.errors.clear("branch_id");
    },

    onChangeInterestCountry(event) {
      this.lazyLoadIns = true;
      let countryId = event.target.value;
      Request.GET_REQ(`/country-institutes/${countryId}`).then(res => {
        this.institutes_1 = res.data;
        this.institutes_2 = res.data;
        this.institutes_3 = res.data;
        this.lazyLoadIns = false;
      });
      this.errors.clear("interested_country_id");
    },

    onChangeUniversity(serial) {
      let number = serial;

      switch (number) {
        case 1:
          Request.GET_REQ(`/institute-course/${this.institute_id_1}`).then(
            res => {
              this.courses = res.data;
            }
          );
          break;
        case 2:
          Request.GET_REQ(`/institute-course/${this.institute_id_2}`).then(
            res => {
              this.courses_2 = res.data;
            }
          );
          break;
        case 3:
          Request.GET_REQ(`/institute-course/${this.institute_id_3}`).then(
            res => {
              this.courses_3 = res.data;
            }
          );

          break;
      }
    },
    /* onChangeCourse(event) {
      let courseValue = event.target.value;
    }, */

    getErrors(index, field) {
      let property = `interests.${index}.${field}`;
      return this.errors[property];
    },
    onFileChange(event) {
      this.$store.dispatch("baseurl", "");
      this.photo = null;
      let file = event.target.files[0];
      if (file.size > 1048770) {
        Notify.warning("file is bigger than 2mb");
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.photo = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    onChangeMultipleFiles() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.files.push(this.$refs.files.files[i]);
      }
    },
    add() {
      this.interests.push({
        institute_id: "",
        course_id: ""
      });
    },
    remove(index) {
      this.interests.splice(index, 1);
    }
  }
};
</script>
<style scoped lang="scss">
.component-card_1 {
  width: auto;
}
body.dark .office-logo img {
  border: 1px solid #1b2e4b;
}
.office-logo img {
  width: 100px;
  border: 1px solid #bfc9d4;
  border-radius: 5px;
  padding: 5px;
}
.office-logo {
  width: 100%;
  text-align: right;
}
.n-chk {
  .new-control {
    font-size: 18px;
  }
  .new-control.new-radio .new-control-indicator {
    top: 7px;
  }
}

.widget-content.widget-content-area.text-center.app-success-bg {
  background: midnightblue;
}
</style>
