<template>
  <div v-if="pendingStatus || isLoading">
    <Loader />
  </div>
  <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
    <div class="statbox widget box box-shadow">
      <div class="widget-content widget-content-area">
        <div class="chat-section">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12">
              <div class="chat-system">
                <div class="hamburger">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-menu mail-menu d-lg-none"
                  >
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                  </svg>
                </div>
                <!-- user list box here -->

                <UserSelection
                  :contacts="contacts"
                  @selectedUser="startConversationWith"
                />

                <UserMessage
                  :is-user-selected="isSelectUser"
                  :selected-user-id="selectedUserId"
                  :messages="messages"
                  :send-user="sendUser"
                  @reloadMessage="saveNewMessage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserSelection from "@/views/chat/UserSelection.vue";
import UserMessage from "@/views/chat/UserMessage.vue";
import { mapGetters, mapState } from "vuex";
import Request from "@/apis/Request";
export default {
  components: {
    UserSelection,
    UserMessage
  },
  data() {
    return {
      isLoading: false,
      contacts: [],
      messages: [],
      isSelectUser: false,
      selectedUserId: "",
      sendUser: ""
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"]),
    ...mapState(["baseURL"])
  },
  async mounted() {
    await this.$store.dispatch("user");
    this.allUsers();

    window.Echo.channel(`chat-with.${this.user.id}`).listen(
      "MessageSendEvent",
      e => {
        this.handleIncoming(e.message);
      }
    );
  },
  methods: {
    allUsers() {
      this.isLoading = true;
      Request.GET_REQ(`/contact-list/${this.user.id}`)
        .then(res => {
          this.contacts = res.data;
          console.log("Users>>", this.contacts);
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });
    },
    async startConversationWith(contact) {
      this.updateUnreadCount(contact, true);
      this.selectedUserId = contact.id;
      await Request.GET_REQ(`user-message/${contact.id}`)
        .then(res => {
          if (res.status === 200) {
            this.messages = res.data.messages.data;
            this.sendUser = res.data.user;
            this.isSelectUser = true;
            this.$store.dispatch("unreadMsg");
          }
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });

      this.selectedCssStyle();
    },
    saveNewMessage(msg) {
      console.log("message>>", msg);
      this.messages.push(msg);
    },
    handleIncoming(message) {
      if (this.selectedUserId && message.from == this.selectedUserId) {
        this.saveNewMessage(message);
        return;
      }
      this.updateUnreadCount(message.from_contact, false);
    },
    updateUnreadCount(contact, reset) {
      this.contacts ==
        this.contacts.map(single => {
          if (single.id != contact.id) {
            return single;
          }
          if (reset) {
            single.unread = 0;
          } else {
            single.unread += 1;
          }

          return single;
        });
    },
    selectedCssStyle() {
      this.$nextTick(() => {
        $(document).ready(function() {
          $(".chat-box .chat-box-inner").css("height", "100%");
          $(".chat-box .overlay-phone-call").css("display", "block");
          $(".chat-box .overlay-video-call").css("display", "block");
          $(this).addClass("active");
          $(".chat-meta-user").addClass("chat-active");
          $(".chat-box").css("height", "calc(100vh - 250px)");
          $(".chat-footer").addClass("chat-active");
        });
      });
    }
  }
};
</script>
