import { createStore } from "vuex";
import User from "../apis/User";
import router from "../router";
import baseURL from "../apis/baseURL"
import Request from "@/apis/Request"
import _ from "lodash";
export default createStore({
  state: {
    user: null,
    pendingStatus: false,
    baseURL: baseURL.baseURL,
    branch: null,
    unreadMsg: null,
    unreadNotify: null
  },
  getters: {
    user: state => {
      return state.user;
    },
    pendingStatus: state => {
      return state.pendingStatus;
    },
    baseURL: state => {
      return state.baseURL;
    },
    branch: state => {
      return state.branch
    },
    unreadMsg: state => {
      return state.unreadMsg
    },
    unreadNotify: state => {
      return state.unreadNotify
    }
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    userLogout(state, userLogout) {
      state.user = userLogout;
    },
    pendingStatus(state, newPendingStatus) {
      state.pendingStatus = newPendingStatus;
    },
    baseurl(state, newUrl){
      state.baseURL = newUrl
    },
    branch(state, branch) {
      state.branch = branch
    },
    unreadMsg(state, unreadMsg) {
      state.unreadMsg = unreadMsg
    },
    unreadNotify(state, unreadNotify) {
      state.unreadNotify = unreadNotify
    }
  },
  actions: {
    async user({ commit }) {
      commit("pendingStatus", true);
     await User.auth()
        .then(res => {
          commit("user", res.data);
          commit("pendingStatus", false);
        })
        .catch(err => {
          
          if (err.response.status === 401) {
            localStorage.setItem("user", false);
            router.push({ name: "Login" });
          }
        });
    },
    userLogout({ commit }) {
      commit("pendingStatus", true);

      User.logout().then(() => {
        commit("pendingStatus", true);
        localStorage.removeItem("auth");
        localStorage.removeItem("user");
        localStorage.removeItem("branch");
        localStorage.setItem("user", false);
        commit("userLogout", null);
        commit("branch", null);
        router.push({ name: "Login" });
        commit("pendingStatus", false);
      });
    },
    baseurl({ commit }, newUrl) {
      commit("baseurl", newUrl)
    },
    async branch({ commit, state }) {
      commit("pendingStatus", true);
      if (state.user) {
        setTimeout(() => {
            Request.GET_REQ(`/branch-info/${state.user && state.user.branch_id}`)
              .then(res => {
                localStorage.setItem("branch", JSON.stringify(res.data));
                commit("branch", res.data);
            })
            .catch(err => {
              console.log(err);
            });
          }, 2000)
      }
      commit("pendingStatus", false);
    },
    async unreadMsg({ commit, state }) {
      commit("pendingStatus", true);
      if (state.user) {
        setTimeout(() => {
            Request.GET_REQ(`/contact-list/${state.user && state.user.id} `)
        .then(res => {
          // commit('unreadMsg', res.data)
          if (res.status === 200) {
            let contacts = res.data;
            let totalUnread =  _.sumBy(contacts, function (contact) {
              return contact.unread;
            });
            commit('unreadMsg', totalUnread)
          }
          
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });
          }, 2000)
      }
      commit("pendingStatus", false);
    },
    async unreadNotify({ commit, state }) {
      commit("pendingStatus", true);
      if (state.user) {
        setTimeout(() => {
            Request.GET_REQ(`/counselor-notification-unread/${state.user && state.user.counselor_id} `)
        .then(res => {
          // commit('unreadMsg', res.data)
          if (res.status === 200) {
            commit('unreadNotify', res.data)
            console.log("unreadNotify", res.data);
          }
          
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });
          }, 2000)
      }
      commit("pendingStatus", false);
    }

  },
  modules: {}
});
