<template>
  <div v-if="pendingStatus || isLoading">
    <Loader />
  </div>
  <div v-else class="layout-px-spacing layout-top-spacing">
    <div class="row p-2">
      <div class="col-md-4">
        <div class="form-group mb-4">
          <select
            v-model="country_name"
            class="form-control"
            @change="searchCountryInstitute($event.target.value)"
          >
            <option value="" disabled>Search Country</option>
            <option
              v-for="country in total_countries"
              :key="country.id"
              :value="country.id"
              >{{ country.country.name }}</option
            >
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mb-4">
          <input
            v-model="search"
            type="text"
            class="form-control"
            placeholder="Search by Institute Name"
            style="position: relative"
            @keyup="searchit"
          />
          <span v-if="searchLoading" class="search-icon">
            <FeatherLoaderSpinSvg />
          </span>
          <span v-if="!searchLoading" class="search-icon">
            <FeatherSearchSvg />
          </span>
        </div>
      </div>
      <div class="col-md-4">
        <div
          v-if="
            (user && user.user_type == 'superAdmin') ||
              (user && user.user_type == 'branchManager')
          "
          class="text-right"
        >
          <router-link :to="{ name: 'Add_Institute' }">
            <button class="btn btn-info mb-2 mr-4 btn-rounded">
              Add Institute
              <FeatherPlusCircleSvg />
            </button>
          </router-link>
        </div>
      </div>
    </div>

    <div id="card_1" class="col-lg-12 layout-spacing">
      <div class="statbox widget box box-shadow">
        <div class="widget-content widget-content-area">
          <div class="row">
            <div class="col-md-12 d-flex pb-2">
              <div
                v-if="user && user.user_type == 'superAdmin'"
                class="text-right"
              >
                <a
                  :href="baseURL + 'api/export-institute'"
                  download="files.xlsx"
                  type="button"
                >
                  <button class="btn btn-secondary mb-2 mr-4 btn-rounded">
                    Export Institute

                    <FeatherFileTextSvg />
                  </button>
                </a>
              </div>

              <div
                v-if="user && user.user_type == 'superAdmin'"
                class="text-right"
              >
                <router-link to="/import-institute">
                  <button class="btn btn-primary mb-2 mr-4 btn-rounded">
                    Import Institute
                    <FeatherUploadCloudSvg />
                  </button>
                </router-link>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              v-for="institute in institutes"
              :key="institute.id"
              class="col col-md-4 mb-4"
            >
              <div class="card component-card_4">
                <div class="card-body">
                  <div class="user-info">
                    <div
                      class="d-flex align-items-start justify-content-between pl-4"
                    >
                      <div class="logo-title-wrap">
                        <img
                          v-if="institute.institute_logo"
                          :src="baseURL + `${institute.institute_logo}`"
                          class=""
                          alt="..."
                        />
                        <img
                          v-else
                          src="@/assets/img/no-image.png"
                          class=""
                          alt="..."
                        />
                      </div>
                      <div
                        class="action-wrap d-flex align-items-center justify-content-between"
                      >
                        <span
                          class="badge badge-pill badge-warning custom-btn-branch"
                        >
                          <router-link
                            :to="{
                              name: 'View_Institute',
                              params: { id: institute.id }
                            }"
                          >
                            <FeeatherEyeSvg />
                          </router-link>
                        </span>

                        <router-link
                          v-if="
                            (user && user.user_type == 'superAdmin') ||
                              (user && user.user_type == 'branchManager')
                          "
                          :to="{
                            name: 'Edit_Institute',
                            params: { id: institute.id }
                          }"
                        >
                          <span
                            class="badge badge-pill badge-secondary custom-btn-branch"
                          >
                            <FeatherEdit3Svg />
                          </span>
                        </router-link>
                        <label
                          v-if="
                            (user && user.user_type == 'superAdmin') ||
                              (user && user.user_type == 'branchManager')
                          "
                          class="switch s-icons s-outline s-outline-success mr-2"
                        >
                          <input
                            type="checkbox"
                            :checked="
                              institute && +institute.active === 1
                                ? true
                                : false
                            "
                            @change="status(institute.id)"
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <div class="card-info-lists px-4">
                      <div class="title-subtitle-wrap mt-4">
                        <h3 class="card-user_name">
                          {{ institute.institute_name }}
                        </h3>
                        <p class="card-user_occupation">
                          {{
                            institute.represent_country &&
                              institute.represent_country.country.name
                          }}
                        </p>
                      </div>

                      <ul class="list-group custom-list-group-branch">
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          <h6 class="count-primary">Total Courses</h6>
                          <span class="badge badge-primary">{{
                            institute.courses_count
                          }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          <h6 class="count-info">Monthly Living Costs</h6>
                          <span class="badge badge-info">{{
                            institute.monthly_living_cost
                          }}</span>
                        </li>

                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          <h6 class="count-success">Is Language Mendatory</h6>
                          <span class="badge badge-success">{{
                            institute.is_lang_mendatory
                          }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          <h6 class="count-warning">Currency</h6>
                          <span class="badge badge-warning">{{
                            institute.currency
                          }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          <router-link
                            v-if="
                              (user && user.user_type == 'superAdmin') ||
                                (user && user.user_type == 'branchManager')
                            "
                            :to="{
                              name: 'Edit_Institute',
                              params: { id: institute.id }
                            }"
                          >
                            <h6 class="count-danger">Manage Institute</h6>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-12">
              <div class="lms-pagination">
                <pagination
                  v-if="!isSearch"
                  v-model="page"
                  :records="all_institutes"
                  :per-page="20"
                  class="custom-pagination"
                  @paginate="myCallback"
                />
                <pagination
                  v-if="isSearch"
                  v-model="page"
                  :records="all_institutes_search"
                  :per-page="20"
                  class="custom-pagination"
                  @paginate="searchCountryInstitute(countryId)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import FeatherLoaderSpinSvg from "@/svg/FeatherLoaderSpinSvg.vue";
import FeatherSearchSvg from "@/svg/FeatherSearchSvg.vue";
import FeatherPlusCircleSvg from "@/svg/FeatherPlusCircleSvg.vue";
import FeatherFileTextSvg from "@/svg/FeatherFileTextSvg.vue";
import FeatherUploadCloudSvg from "@/svg/FeatherUploadCloudSvg.vue";
import FeeatherEyeSvg from "@/svg/FeeatherEyeSvg.vue";
import FeatherEdit3Svg from "@/svg/FeatherEdit3Svg.vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import Request from "../../apis/Request";
import Notify from "../../helpers/Notify";
export default {
  name: "Institutes",
  components: {
    Loader,
    FeatherLoaderSpinSvg,
    FeatherSearchSvg,
    FeatherFileTextSvg,
    FeatherUploadCloudSvg,
    FeeatherEyeSvg,
    FeatherEdit3Svg,
    FeatherPlusCircleSvg
  },
  data() {
    return {
      form: {
        country: "",
        branch_name: ""
      },
      country_name: "",
      institutes: [],
      total_countries: [],
      errors: {},
      isLoading: false,
      isSearch: false,
      page: 1,
      all_institutes: 0,
      all_institutes_search: 0,
      searchLoading: false,
      countryId: ""
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"]),
    ...mapState(["baseURL"])
  },
  mounted() {
    this.getTotalInstitute();
    this.getInstitute();
    this.totalCountry();
    this.emitter.on("institute-reload", () => {
      this.getTotalInstitute();
      this.getInstitute();
    });
  },
  methods: {
    getInstitute() {
      this.isLoading = true;
      this.searchLoading = false;
      Request.GET_REQ(`/institutes?page=${this.page}`)
        .then(res => {
          this.institutes = res.data.data;
          this.isLoading = false;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });
    },
    getTotalInstitute() {
      Request.GET_REQ("/all-institutes")
        .then(res => {
          this.all_institutes = res.data;
          this.isLoading = false;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });
    },
    myCallback() {
      Request.GET_REQ(`/institutes?page=${this.page}`)
        .then(res => {
          this.isSearch = false;
          this.institutes = res.data.data;
          this.isLoading = false;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });
    },
    totalCountry() {
      this.isLoading = true;
      Request.GET_REQ("/active-represent-countries")
        .then(res => {
          this.total_country = res.data.length;
          this.total_countries = res.data;
          this.isLoading = false;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });
    },
    searchCountryInstitute(getcountryId) {
      let countryId = getcountryId;
      this.countryId = countryId;
      this.isSearch = true;
      Request.GET_REQ(`/country-institute/${countryId}?page=${this.page}`)
        .then(res => {
          this.institutes = res.data.data;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });

      Request.GET_REQ(`/total-country-institute-serch/${countryId}`)
        .then(res => {
          this.all_institutes_search = res.data;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });
    },
    searchit: _.debounce(function() {
      this.searchLoading = true;
      const query = this.search;
      if (query.length > 0) {
        this.isSearch = true;
        if (this.countryId) {
          Request.GET_REQ(`/institute-search/${this.countryId}?q=${query}`)
            .then(res => {
              this.searchLoading = false;
              this.institutes = res.data;
            })
            .catch(err => {
              this.errors = err.res.data.errors;
            });
        } else {
          Request.GET_REQ(`/institute-search-name?q=${query}`)
            .then(res => {
              this.institutes = res.data;
              this.searchLoading = false;
            })
            .catch(err => {
              this.errors = err.res.data.errors;
            });
        }
      } else {
        this.isSearch = false;
        this.getTotalInstitute();
        this.getInstitute();
      }
    }, 2000),
    status(id) {
      Request.GET_REQ(`/institue-change-status/${id}`)
        .then(() => {
          this.emitter.emit("institute-reload");
          Notify.success("Institute Status Updated...");
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });
    }
  }
};
</script>
<style scoped lang="scss">
.component-card_4 {
  width: auto;
  .user-info {
    width: 100%;
    padding: 5px 0 20px 0px;
  }
  .logo-title-wrap {
    img {
      border-radius: unset;
      height: 80px;
    }
  }
  .custom-btn-branch {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  .custom-list-group-branch .list-group-item {
    background: transparent;
    padding: 0;
    border: unset;
    position: relative;
    margin: 7px 0;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background: #e0e6ed;
      left: 0;
      bottom: -7px;
    }
    &::last-child::before {
      background: transparent !important;
      content: unset;
    }
    h6 {
      margin: 0;
      position: relative;
      padding-left: 20px;
      font-size: 14px;

      &::before {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        left: 0;
        border-radius: 3px;
      }
    }
    h6.count-primary {
      &::before {
        background: #1b55e2;
      }
    }
    h6.count-info {
      &::before {
        background: #2196f3;
      }
    }
    h6.count-success {
      &::before {
        background: #8dbf42;
      }
    }
    h6.count-warning {
      &::before {
        background: #e2a03f;
      }
    }
    h6.count-danger {
      &::before {
        background: #e7515a;
      }
    }
    h6.count-secondary {
      &::before {
        background: #5c1ac3;
      }
    }
  }
  .card-user_name {
    font-size: 24px;
  }
}

body.dark .component-card_4 {
  .custom-list-group-branch .list-group-item {
    &::before {
      background: #1b2e4b;
    }
  }
}

ul.list-group.custom-list-group-branch li:last-child:before {
  background: transparent !important;
}
.page-link {
  background-color: #0e1726 !important;
}
.search-icon {
  position: absolute;
  right: 5%;
  top: 15%;
}
</style>
