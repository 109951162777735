/* eslint-disable */
import mitt from "mitt";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
const emitter = mitt();
import jQuery from "jquery";
import Pagination from 'v-pagination-3';
import moment from 'moment'
import _ from 'lodash';
global.jQuery = jQuery;
global.$ = jQuery;
// window.$ = $;
import "popper.js";
import "bootstrap";
import select2 from "select2";
window.select2 = select2;
import VueSelect from 'vue-next-select'
import 'vue-next-select/dist/index.min.css'
import "@/assets/main.scss";
import VueApexCharts from "vue3-apexcharts";
import ApexCharts from 'apexcharts';
import Toastify from 'toastify-js'
window.Toastify = Toastify
import "toastify-js/src/toastify.css"

window._ = _;

// window._ = require('lodash');


import Request from "./apis/Request"
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
    key: process.env.VUE_APP_PUSHER_KEY,
    wsPort: 6001,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authEndpoint: "/api/broadcasting/auth",
    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                
                Request.POST_REQ('/api/broadcasting/auth', {
                    socket_id: socketId,
                    channel_name: channel.name
                })
                .then(response => {
                    callback(false, response.data);
                })
                .catch(error => {
                    callback(true, error);
                });
            }
        };
    },
})

let app = createApp(App);
app.use(router);
app.use(store);
app.use(moment);
app.component('vue-select', VueSelect)
app.use(VueApexCharts);
app.component('pagination', Pagination);
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$apexcharts = ApexCharts;
app.mount("#app");
