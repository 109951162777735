<template>
  <div v-if="user" class="header-container">
    <header class="header navbar navbar-expand-sm">
      <a
        href="javascript:void(0);"
        class="sidebarCollapse"
        data-placement="bottom"
        ><FeatherMenuSvg
      /></a>

      <div class="nav-logo align-self-center">
        <router-link to="/dashboard">
          <img src="@/assets/img/logo.webp" height="40" />
        </router-link>
      </div>

      <ul class="navbar-item topbar-navigation">
        <!--  BEGIN TOPBAR  -->
        <div class="topbar-nav header navbar" role="banner">
          <nav id="topbar">
            <ul class="navbar-nav theme-brand flex-row  text-center">
              <li class="nav-item theme-logo">
                <router-link to="/dashboard">
                  <img
                    src="assets/img/90x90.jpg"
                    class="navbar-logo"
                    alt="logo"
                  />
                </router-link>
              </li>
              <li class="nav-item theme-text">
                <router-link to="/dashboard" class="nav-link">
                  LMS
                </router-link>
              </li>
            </ul>

            <ul id="topAccordion" class="list-unstyled menu-categories">
              <li class="menu single-menu">
                <router-link to="/dashboard">
                  <div class="">
                    <DashboardSvg />
                    <span>Dashboard</span>
                  </div>
                </router-link>
              </li>
              <li
                v-if="
                  (user && user.user_type === 'superAdmin') ||
                    (user && user.user_type === 'branchManager') ||
                    (user && user.user_type == 'counselor') ||
                    (user && user.user_type == 'frontOfficer')
                "
                class="menu single-menu"
              >
                <a
                  href="#countries"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle autodroprown"
                  :class="
                    routePath == 'Add_Country' || routePath == 'Countries'
                      ? 'router-link-active router-link-exact-active'
                      : ''
                  "
                >
                  <div class="">
                    <CountrySvg />
                    <span>Countries</span>
                  </div>
                </a>
                <ul
                  id="countries"
                  class="collapse submenu list-unstyled animated fadeInUp"
                  data-parent="#topAccordion"
                >
                  <li
                    v-if="
                      (user && user.user_type == 'superAdmin') ||
                        (user && user.user_type == 'branchManager')
                    "
                  >
                    <router-link :to="{ name: 'Add_Country' }"
                      >Add Country</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'Countries' }">
                      View Country
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                v-if="
                  (user && user.user_type === 'superAdmin') ||
                    (user && user.user_type === 'branchManager') ||
                    (user && user.user_type == 'counselor') ||
                    (user && user.user_type == 'frontOfficer')
                "
                class="menu single-menu"
              >
                <a
                  href="#institute"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle autodroprown"
                  :class="
                    routePath == 'Add_Institute' || routePath == 'Institutes'
                      ? 'router-link-active router-link-exact-active'
                      : ''
                  "
                >
                  <div class="">
                    <InstituteSvg />
                    <span>Institution</span>
                  </div>
                </a>
                <ul
                  id="institute"
                  class="collapse submenu list-unstyled animated fadeInUp"
                  data-parent="#topAccordion"
                >
                  <li
                    v-if="
                      (user && user.user_type == 'superAdmin') ||
                        (user && user.user_type == 'branchManager')
                    "
                  >
                    <router-link :to="{ name: 'Add_Institute' }"
                      >Add Institue</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'Institutes' }">
                      View Institute
                    </router-link>
                  </li>
                </ul>
              </li>

              <li
                v-if="
                  (user && user.user_type === 'superAdmin') ||
                    (user && user.user_type === 'branchManager') ||
                    (user && user.user_type == 'counselor') ||
                    (user && user.user_type == 'frontOfficer')
                "
                class="menu single-menu"
              >
                <a
                  href="#courses"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle autodroprown"
                  :class="
                    routePath == 'Add_Course' ||
                    routePath == 'Courses' ||
                    routePath == 'Categories' ||
                    routePath == 'CourseLevel'
                      ? 'router-link-active router-link-exact-active'
                      : ''
                  "
                >
                  <div class="">
                    <CourseSvg />
                    <span>Courses</span>
                  </div>
                </a>
                <ul
                  id="courses"
                  class="collapse submenu list-unstyled animated fadeInUp"
                  data-parent="#topAccordion"
                >
                  <li class="sub-sub-submenu-list">
                    <a
                      href="#courses-sub"
                      data-toggle="collapse"
                      aria-expanded="true"
                      class="dropdown-toggle"
                      :class="
                        routePath == 'Add_Course' || routePath == 'Courses'
                          ? 'router-link-active router-link-exact-active'
                          : ''
                      "
                    >
                      Courses
                      <ChevronRightSvg />
                    </a>
                    <ul
                      id="courses-sub"
                      class="collapse list-unstyled sub-submenu animated fadeInUp"
                      data-parent="#courses"
                    >
                      <li
                        v-if="
                          (user && user.user_type == 'superAdmin') ||
                            (user && user.user_type == 'branchManager')
                        "
                      >
                        <router-link to="/add-course"
                          >Add New Course</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/courses">View Courses</router-link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <router-link :to="{ name: 'Categories' }"
                      >Course Category</router-link
                    >
                  </li>

                  <li>
                    <router-link :to="{ name: 'CourseLevel' }"
                      >Course Level</router-link
                    >
                  </li>
                </ul>
              </li>

              <li
                v-if="
                  (user && user.user_type === 'superAdmin') ||
                    (user && user.user_type === 'branchManager') ||
                    (user && user.user_type == 'counselor')
                "
                class="menu single-menu"
              >
                <a
                  href="#offices"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle"
                  :class="
                    routePath == 'AddBranch' ||
                    routePath == 'Branches' ||
                    routePath == 'Add_Front_Office' ||
                    routePath == 'Front_Offices' ||
                    routePath == 'Add_Agent' ||
                    routePath == 'Agents'
                      ? 'router-link-active router-link-exact-active'
                      : ''
                  "
                >
                  <div class="">
                    <HomeSvg />
                    <span>Offices</span>
                  </div>
                </a>
                <ul
                  id="offices"
                  class="collapse submenu list-unstyled animated fadeInUp"
                  data-parent="#topAccordion"
                >
                  <li class="sub-sub-submenu-list">
                    <a
                      href="#branches"
                      data-toggle="collapse"
                      aria-expanded="true"
                      class="dropdown-toggle"
                      :class="
                        routePath == 'AddBranch' || routePath == 'Branches'
                          ? 'router-link-active router-link-exact-active'
                          : ''
                      "
                    >
                      Branch Offices
                      <ChevronRightSvg />
                    </a>
                    <ul
                      id="branches"
                      class="collapse list-unstyled sub-submenu animated fadeInUp"
                      data-parent="#offices"
                    >
                      <li v-if="user && user.user_type == 'superAdmin'">
                        <router-link to="/add-branch"
                          >Add New Branch</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/branches">View Branches</router-link>
                      </li>
                    </ul>
                  </li>

                  <li class="sub-sub-submenu-list">
                    <a
                      href="#branches"
                      data-toggle="collapse"
                      aria-expanded="true"
                      class="dropdown-toggle"
                      :class="
                        routePath == 'Add_Front_Office' ||
                        routePath == 'Front_Offices'
                          ? 'router-link-active router-link-exact-active'
                          : ''
                      "
                    >
                      Front Offices
                      <ChevronRightSvg />
                    </a>
                    <ul
                      id="branches"
                      class="collapse list-unstyled sub-submenu animated fadeInUp"
                      data-parent="#offices"
                    >
                      <li
                        v-if="
                          (user && user.user_type == 'superAdmin') ||
                            (user && user.user_type == 'branchManager')
                        "
                      >
                        <router-link to="/add-front-office">
                          Add New Front Office
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/front-offices">
                          View Front Offices
                        </router-link>
                      </li>
                    </ul>
                  </li>

                  <li class="sub-sub-submenu-list">
                    <a
                      href="#branches"
                      data-toggle="collapse"
                      aria-expanded="true"
                      class="dropdown-toggle"
                      :class="
                        routePath == 'Add_Agent' || routePath == 'Agents'
                          ? 'router-link-active router-link-exact-active'
                          : ''
                      "
                    >
                      Sub Agent
                      <ChevronRightSvg />
                    </a>
                    <ul
                      id="branches"
                      class="collapse list-unstyled sub-submenu animated fadeInUp"
                      data-parent="#offices"
                    >
                      <li
                        v-if="
                          (user && user.user_type == 'superAdmin') ||
                            (user && user.user_type == 'branchManager')
                        "
                      >
                        <router-link to="/add-agent"
                          >Add New Sub Agent</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/agents"> View Sub Agent</router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li
                v-if="
                  (user && user.user_type === 'superAdmin') ||
                    (user && user.user_type === 'branchManager') ||
                    (user && user.user_type == 'counselor')
                "
                class="menu single-menu"
              >
                <a
                  href="#counselors"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle"
                  :class="
                    routePath == 'Add_Counselor' || routePath == 'Counselors'
                      ? 'router-link-active router-link-exact-active'
                      : ''
                  "
                >
                  <div class="">
                    <CounselorsSvg />
                    <span>Counselors</span>
                  </div>
                </a>
                <ul
                  id="counselors"
                  class="collapse submenu list-unstyled animated fadeInUp"
                  data-parent="#topAccordion"
                >
                  <li
                    v-if="
                      (user && user.user_type == 'superAdmin') ||
                        (user && user.user_type == 'branchManager')
                    "
                  >
                    <router-link :to="{ name: 'Add_Counselor' }"
                      >Add Courselor</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'Counselors' }">
                      View Couselor
                    </router-link>
                  </li>
                  <li class="sub-sub-submenu-list">
                    <a
                      href="#counselors-tools"
                      data-toggle="collapse"
                      aria-expanded="true"
                      class="dropdown-toggle"
                    >
                      Counselors Tools
                      <ChevronRightSvg />
                    </a>
                    <ul
                      id="counselors-tools"
                      class="collapse list-unstyled sub-submenu animated fadeInUp"
                      data-parent="#counselors"
                    >
                      <li>
                        <a href="#"> Find Course </a>
                      </li>
                      <li>
                        <a href="#"> Student Quotation </a>
                      </li>

                      <li>
                        <a href="#"> Search Student </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li class="menu single-menu">
                <a
                  href="#leads"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle autodroprown"
                  :class="
                    routePath == 'Add_Lead' ||
                    routePath == 'Leads' ||
                    routePath == 'Application_Leads' ||
                    routePath == 'Lead_Source' ||
                    routePath == 'Lead_Status'
                      ? 'router-link-active router-link-exact-active'
                      : ''
                  "
                >
                  <div class="">
                    <LeadsSvg />
                    <span
                      v-if="
                        (user && user.user_type == 'superAdmin') ||
                          (user && user.user_type == 'branchManager') ||
                          (user && user.user_type == 'counselor') ||
                          (user && user.user_type == 'frontOfficer')
                      "
                    >
                      Leads</span
                    >

                    <span v-if="user.user_type == 'agent'">
                      Application Leads</span
                    >
                  </div>
                </a>
                <ul
                  id="leads"
                  class="collapse submenu list-unstyled animated fadeInUp"
                  data-parent="#topAccordion"
                >
                  <li
                    v-if="
                      (user && user.user_type == 'superAdmin') ||
                        (user && user.user_type == 'branchManager') ||
                        (user && user.user_type == 'counselor') ||
                        (user && user.user_type == 'frontOfficer')
                    "
                  >
                    <router-link :to="{ name: 'Add_Lead' }"
                      >Add New Lead</router-link
                    >
                  </li>

                  <li v-if="user.user_type == 'agent'">
                    <router-link :to="{ name: 'Add_Lead' }"
                      >Add New Application</router-link
                    >
                  </li>

                  <li
                    v-if="
                      (user && user.user_type == 'superAdmin') ||
                        (user && user.user_type == 'branchManager') ||
                        (user && user.user_type == 'counselor') ||
                        (user && user.user_type == 'frontOfficer')
                    "
                  >
                    <router-link :to="{ name: 'Leads' }">All Leads</router-link>
                  </li>

                  <li v-if="user.user_type == 'agent'">
                    <router-link :to="{ name: 'AgentLeads' }"
                      >All Application</router-link
                    >
                  </li>

                  <li
                    v-if="
                      (user && user.user_type == 'superAdmin') ||
                        (user && user.user_type == 'branchManager') ||
                        (user && user.user_type == 'counselor') ||
                        (user && user.user_type == 'frontOfficer')
                    "
                  >
                    <router-link :to="{ name: 'Application_Leads' }"
                      >Application Leads</router-link
                    >
                  </li>

                  <li
                    v-if="
                      (user && user.user_type == 'superAdmin') ||
                        (user && user.user_type == 'branchManager')
                    "
                  >
                    <router-link :to="{ name: 'Lead_Source' }"
                      >Lead Source</router-link
                    >
                  </li>
                  <li
                    v-if="
                      (user && user.user_type == 'superAdmin') ||
                        (user && user.user_type == 'branchManager')
                    "
                  >
                    <router-link :to="{ name: 'Lead_Status' }"
                      >Lead Status</router-link
                    >
                  </li>
                </ul>
              </li>
              <li
                v-if="user && user.user_type === 'superAdmin'"
                class="menu single-menu"
              >
                <a
                  href="#tasks"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle autodroprown"
                >
                  <div class="">
                    <TasksSvg />
                    <span>Tasks</span>
                  </div>
                </a>
                <ul
                  id="tasks"
                  class="collapse submenu list-unstyled animated fadeInUp"
                  data-parent="#topAccordion"
                >
                  <li>
                    <a href="#"> Add Task </a>
                  </li>
                  <li>
                    <a href="#"> Task Assigned to me </a>
                  </li>

                  <li>
                    <a href="#"> Task Assigned by me </a>
                  </li>
                </ul>
              </li>

              <li class="menu single-menu menu-extras">
                <a
                  href="#more"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle"
                >
                  <div class="">
                    <span>
                      <MoreSvg />
                      <span class="d-xl-none">More</span></span
                    >
                  </div>
                </a>
                <ul
                  id="more"
                  class="submenu list-unstyled animated fadeInUp collapse"
                  data-parent="#topAccordion"
                  style=""
                >
                  <li>
                    <router-link :to="{ name: 'Chat' }">
                      Chat
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <!--  END TOPBAR  -->
      </ul>

      <ul class="navbar-item flex-row nav-dropdowns ml-auto">
        <li class="nav-item dropdown language-dropdown more-dropdown">
          <div class="dropdown custom-dropdown-icon">
            <a
              id="customDropdown"
              class="dropdown-toggle btn"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              @click="
                user && user.user_type === 'branchManager' ? flagUpload() : ''
              "
            >
              <img
                v-if="
                  user &&
                    user.user_type === 'branchManager' &&
                    branchInfo.flag !== null &&
                    branchFlag
                "
                :src="baseURL + branchInfo.flag"
                class="flag-width"
                alt="flag"
              />
              <img
                v-if="user && user.user_type === 'branchManager' && !branchFlag"
                :src="flag"
                class="flag-width"
                alt="flag"
              />
              <img
                v-if="
                  user.user_type === 'superAdmin' || branchInfo.flag === null
                "
                src="@/assets/img/uk.png"
                class="flag-width"
                alt="flag"
              />
            </a>
            <input id="flagRef" type="file" hidden @change="onChangeFlag" />
          </div>
        </li>

        <li
          class="nav-item dropdown notification-dropdown"
          style="position: relative;"
        >
          <router-link
            id="notificationDropdown"
            :to="{ name: 'Chat' }"
            href="javascript:void(0);"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <FeatherMessageCircle />
            <span class="badge badge-success"></span>
            <small v-if="routePath !== 'Chat'" class="msg-counter">{{
              unreadMsg
            }}</small>
          </router-link>
        </li>

        <li class="nav-item dropdown notification-dropdown">
          <a
            id="notificationDropdown"
            href="javascript:void(0);"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            @click="unreadNotifyFn"
          >
            <FeatherBellSvg />
            <small class="msg-counter unread_notify">{{ unreadNotify }}</small>
            <span class="badge badge-success"></span>
          </a>
          <div
            class="dropdown-menu notification-dropdown position-absolute animated fadeInUp"
            aria-labelledby="notificationDropdown"
          >
            <div class="notification-scroll">
              <div
                v-for="(notify, index) in notifications"
                :key="index"
                class="dropdown-item"
              >
                <div class="media server-log">
                  <div class="media-body">
                    <div class="data-info">
                      <h6 class="" v-html="notify && notify.info_text"></h6>
                      <p class="" style="font-size: 12px;">
                        {{ dateTime(notify && notify.created_at) }}
                      </p>
                    </div>
                    <div
                      class="icon-status"
                      @click="deleteNofification(notify.id)"
                    >
                      <FeatherXSvg />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li class="nav-item dropdown user-profile-dropdown order-lg-0 order-1">
          <a
            id="user-profile-dropdown"
            href="javascript:void(0);"
            class="nav-link dropdown-toggle user"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="media">
              <img
                v-if="
                  user &&
                    user.user_type === 'superAdmin' &&
                    (photo === null || user.photo === null) &&
                    isPhoto
                "
                src="@/assets/img/90x90.jpg"
                class="img-fluid 3"
                alt="admin-profile"
              />
              <img
                v-if="
                  user &&
                    user.user_type === 'superAdmin' &&
                    user.photo !== null &&
                    !isUpload
                "
                :src="user && baseURL + user.photo"
                class="img-fluid 1"
                alt="admin-profile"
              />
              <img
                v-if="
                  user &&
                    user.user_type === 'superAdmin' &&
                    photo !== null &&
                    !isPhoto
                "
                :src="photo"
                class="img-fluid 2"
                alt="admin-profile"
              />

              <img
                v-if="
                  user &&
                    user.user_type === 'branchManager' &&
                    user.photo !== null
                "
                :src="user && baseURL + user.photo"
                class="img-fluid"
                alt="admin-profile"
              />

              <img
                v-if="
                  user && user.user_type === 'counselor' && user.photo !== null
                "
                :src="user && baseURL + user.photo"
                class="img-fluid"
                alt="admin-profile"
              />
              <img
                v-if="
                  user &&
                    user.user_type === 'frontOfficer' &&
                    user.photo !== null
                "
                :src="user && baseURL + user.photo"
                class="img-fluid"
                alt="admin-profile"
              />
              <img
                v-if="user && user.user_type === 'agent' && user.photo !== null"
                :src="user && baseURL + user.photo"
                class="img-fluid"
                alt="admin-profile"
              />
              <img
                v-if="
                  user &&
                    !user.user_type === 'superAdmin' &&
                    user.photo === null
                "
                src="@/assets/img/90x90.jpg"
                class="img-fluid"
                alt="admin-profile"
              />
              <img
                v-if="
                  user && user.photo === null && user.user_type !== 'superAdmin'
                "
                src="@/assets/img/90x90.jpg"
                class="img-fluid"
                alt="admin-profile"
              />
            </div>
          </a>
          <div
            class="dropdown-menu position-absolute animated fadeInUp"
            aria-labelledby="userProfileDropdown"
          >
            <div class="user-profile-section">
              <div class="media mx-auto">
                <img
                  v-if="
                    user &&
                      user.user_type === 'superAdmin' &&
                      (photo === null || user.photo === null) &&
                      isPhoto
                  "
                  src="@/assets/img/90x90.jpg"
                  class="img-fluid 3"
                  alt="admin-profile"
                />
                <img
                  v-if="
                    user &&
                      user.user_type === 'superAdmin' &&
                      user.photo !== null &&
                      !isUpload
                  "
                  :src="user && baseURL + user.photo"
                  class="img-fluid 1"
                  alt="admin-profile"
                />
                <img
                  v-if="
                    user &&
                      user.user_type === 'superAdmin' &&
                      photo !== null &&
                      !isPhoto
                  "
                  :src="photo"
                  class="img-fluid 2"
                  alt="admin-profile"
                />

                <img
                  v-if="
                    user &&
                      user.user_type === 'branchManager' &&
                      user.photo !== null
                  "
                  :src="user && baseURL + user.photo"
                  class="img-fluid"
                  alt="admin-profile"
                />

                <img
                  v-if="
                    user &&
                      user.user_type === 'counselor' &&
                      user.photo !== null
                  "
                  :src="user && baseURL + user.photo"
                  class="img-fluid"
                  alt="admin-profile"
                />
                <img
                  v-if="
                    user &&
                      user.user_type === 'frontOfficer' &&
                      user.photo !== null
                  "
                  :src="user && baseURL + user.photo"
                  class="img-fluid"
                  alt="admin-profile"
                />
                <img
                  v-if="
                    user && user.user_type === 'agent' && user.photo !== null
                  "
                  :src="user && baseURL + user.photo"
                  class="img-fluid"
                  alt="admin-profile"
                />
                <img
                  v-if="
                    user &&
                      user.photo === null &&
                      user.user_type !== 'superAdmin'
                  "
                  src="@/assets/img/90x90.jpg"
                  class="img-fluid"
                  alt="admin-profile"
                />

                <div class="media-body">
                  <h5 v-if="user" class="pl-1">{{ user.name }}</h5>
                  <p class="pl-1">{{ user.user_type }}</p>
                </div>
              </div>
            </div>
            <div
              v-if="user && user.user_type === 'superAdmin'"
              class="dropdown-item"
            >
              <a href="#" @click="onPickFile">
                <FeatherCameraSvg />
                <span>Change Photo</span>
              </a>
              <input
                ref="fileInput"
                type="file"
                accept="image/*"
                style="display:none;"
                @change="onFilePicked"
              />
            </div>
            <div class="dropdown-item">
              <a href="#">
                <FeatherUserSvg />
                <span>My Profile</span>
              </a>
            </div>
            <div class="dropdown-item">
              <a href="#" @click="EditProfile">
                <FeatherInboxSvg />
                <span>Edit Profile</span>
              </a>
            </div>
            <div class="dropdown-item">
              <router-link
                :to="{
                  name: 'Change_Password',
                  params: { id: user.id }
                }"
              >
                <FeatherLockSvg />
                <span>Change Password</span>
              </router-link>
            </div>
            <div class="dropdown-item">
              <a v-if="user" href="#" @click.prevent="logout">
                <FeatherLogOutSvg />
                <span>Log Out</span>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </header>
    <NotificationBar :notification="notification" />
  </div>
</template>
<script>
// import User from "@/apis/User.js";

import Request from "@/apis/Request";
// import baseURL from "@/apis/baseURL";
import { mapGetters, mapState } from "vuex";
import HomeSvg from "@/svg/HomeSvg.vue";
import CountrySvg from "@/svg/CountrySvg.vue";
import InstituteSvg from "@/svg/InstituteSvg.vue";
import CourseSvg from "@/svg/CourseSvg.vue";
import DashboardSvg from "@/svg/DashboardSvg.vue";
import CounselorsSvg from "@/svg/CounselorsSvg.vue";
import LeadsSvg from "@/svg/LeadsSvg.vue";
import TasksSvg from "@/svg/TasksSvg.vue";
import ChevronRightSvg from "@/svg/ChevronRightSvg.vue";
import FeatherMenuSvg from "@/svg/ChevronRightSvg.vue";
import MoreSvg from "@/svg/MoreSvg.vue";
import FeatherBellSvg from "@/svg/FeatherBellSvg.vue";
import FeatherXSvg from "@/svg/FeatherXSvg.vue";
import FeatherUserSvg from "@/svg/FeatherUserSvg.vue";
import FeatherCameraSvg from "@/svg/FeatherCameraSvg.vue";
import FeatherInboxSvg from "@/svg/FeatherInboxSvg.vue";
import FeatherLockSvg from "@/svg/FeatherLockSvg.vue";
import FeatherLogOutSvg from "@/svg/FeatherLogOutSvg.vue";
import FeatherMessageCircle from "@/svg/FeatherMessageCircle.vue";
import Notify from "../helpers/Notify";
import NotificationBar from "@/components/NotificationBar.vue";
import moment from "moment";
export default {
  components: {
    HomeSvg,
    CountrySvg,
    InstituteSvg,
    CourseSvg,
    DashboardSvg,
    CounselorsSvg,
    LeadsSvg,
    ChevronRightSvg,
    MoreSvg,
    FeatherMenuSvg,
    FeatherBellSvg,
    FeatherXSvg,
    FeatherUserSvg,
    FeatherCameraSvg,
    FeatherInboxSvg,
    FeatherLockSvg,
    FeatherLogOutSvg,
    FeatherMessageCircle,
    TasksSvg,
    NotificationBar
  },
  data() {
    return {
      photo: "",
      isPhoto: true,
      branchFlag: true,
      isUpload: false,
      image: null,
      flag: "",
      branchInfo: {},
      notifications: [],
      notification: {}
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus", "unreadMsg", "unreadNotify"]),
    ...mapState(["baseURL"]),
    routePath() {
      return this.$route.name;
    }
  },
  async mounted() {
    await this.$store.dispatch("user");
    setTimeout(() => {
      if (this.user && this.user.branch_id) {
        Request.GET_REQ(`/branch-info/${this.user.branch_id}`)
          .then(res => {
            this.branchInfo = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      }
      if (this.user && this.user.counselor_id) {
        window.Echo.channel(
          `Assign.Lead.Counselor.${this.user && this.user.counselor_id}`
        ).listen("AssignLeadCounselorEvent", e => {
          this.notification = e;
          this.$nextTick(() => {
            $(".toast").toast("show");
          });
          this.$store.dispatch("unreadNotify");
        });
      }

      window.Echo.channel(`chat-with.${this.user.id}`).listen(
        "MessageSendEvent",
        () => {
          this.$store.dispatch("unreadMsg");
        }
      );

      //Branch Manager notification

      if (this.user && this.user.user_type == "branchManager") {
        this.branchNotifications();
      }

      //Counselor notification

      if (this.user && this.user.user_type == "counselor") {
        this.counselorNotifications();
      }
    }, 1000);
  },
  methods: {
    dateTime(value) {
      // return moment(value).format("MMMM Do YYYY, h:mm:ss a");
      return moment(value).calendar();
    },
    logout() {
      this.$store.dispatch("userLogout");
      if (this.user) {
        window.Echo.leaveChannel(
          `Assign.Lead.Counselor.${this.user && this.user.counselor_id}`
        );
      }
    },
    flagUpload() {
      document.getElementById("flagRef").click();
      // this.$refs.flagRef.click();
    },
    onChangeFlag(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = event => {
        this.branchFlag = false;
        this.flag = event.target.result;
        const data = {
          flag: event.target.result
        };

        if (this.user.user_type === "branchManager") {
          Request.POST_REQ(data, `/upload-flag/${this.user.branch_id}`)
            .then(() => {
              Notify.success("Flag Updated");
            })
            .catch(err => {
              console.log(err);
            });
        }
      };
      reader.readAsDataURL(file);
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      this.isPhoto = false;
      let file = event.target.files[0];
      if (file.size > 1048770) {
        console.log("file is bigger than 2mb");
      } else {
        this.isUpload = true;
        let reader = new FileReader();
        reader.onload = event => {
          this.photo = event.target.result;
          const data = {
            photo: event.target.result
          };

          Request.POST_REQ(data, `/update-admin-photo/${this.user.id}`)
            .then(() => {
              Notify.success("Photo Updated");
            })
            .catch(err => {
              console.log(err);
            });
        };
        reader.readAsDataURL(file);
      }
    },
    EditProfile() {
      const branchId = this.user && this.user.branch_id;
      const counselorId = this.user && this.user.counselor_id;
      const frontOfficeId = this.user && this.user.front_office_id;
      const agentId = this.user && this.user.agent_id;

      if (this.user.user_type === "branchManager") {
        Request.GET_REQ(`/branch-info/${branchId}`)
          .then(res => {
            this.$router.push({
              name: "EditBranch",
              params: { slug: res.data.slug }
            });
          })
          .catch(err => {
            console.log(err);
          });
      }

      if (this.user.user_type === "counselor") {
        Request.GET_REQ(`/counselor-info/${counselorId}`)
          .then(res => {
            this.$router.push({
              name: "EditCounselor",
              params: { slug: res.data.slug }
            });
          })
          .catch(err => {
            console.log(err);
          });
      }

      if (this.user.user_type === "frontOfficer") {
        Request.GET_REQ(`/front-officer-info/${frontOfficeId}`)
          .then(res => {
            this.$router.push({
              name: "Edit_Front_Offices",
              params: { slug: res.data.slug }
            });
          })
          .catch(err => {
            console.log(err);
          });
      }

      if (this.user.user_type === "agent") {
        Request.GET_REQ(`/agent-info/${agentId}`)
          .then(res => {
            this.$router.push({
              name: "Edit_Agent",
              params: { slug: res.data.slug }
            });
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    branchNotifications() {
      Request.GET_REQ(`/branch-notification/${this.user.branch_id}`)
        .then(res => {
          this.notifications = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    counselorNotifications() {
      Request.GET_REQ(`/counselor-notification/${this.user.counselor_id}`)
        .then(res => {
          this.notifications = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteNofification(notifyId) {
      Request.GET_REQ(`/delete-notification/${notifyId}`)
        .then(res => {
          if (res.status === 200) {
            this.notifications = this.notifications.filter(
              item => item.id !== notifyId
            );
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    unreadNotifyFn() {
      if (this.user && this.user.user_type == "counselor") {
        Request.GET_REQ(
          `/udpate-unread-counselor-notification/${this.user.counselor_id}`
        )
          .then(res => {
            if (res.status === 200) {
              this.$store.dispatch("unreadNotify");
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
      if (this.user && this.user.user_type == "frontOffice") {
        Request.GET_REQ(
          `/update_unread_front_office_notifications/${this.user.front_office_id}`
        )
          .then(() => {})
          .catch(err => {
            console.log(err);
          });
      }
    }
  }
};
</script>

<style lang="scss">
.header-container .navbar .navbar-item .nav-item.dropdown .dropdown-menu {
  width: 11rem;
}
body.dark .topbar-nav.header nav#topbar ul.menu-categories li.menu > a {
  padding: 0 7px 0 7px;
}
.topbar-nav.header nav#topbar ul.menu-categories li.menu > a {
  padding: 0 7px 0 7px !important;
}
#customDropdown img {
  border-radius: 50% !important;
}
small.msg-counter {
  background: #2196f3;
  border-radius: 20px calc(40px / 2) 20px 0;
  color: #fff;
  font-weight: bold;
  position: absolute;
  bottom: 5px;
  text-align: center;
  line-height: 1;
  padding: 1px 3px;
  left: 15px;
}
small.msg-counter.unread_notify {
  background: #ffa500;
  left: 20px;
}
</style>
