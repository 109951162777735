<template>
  <div v-if="pendingStatus || isLoading">
    <Loader />
  </div>
  <div v-else class="layout-px-spacing layout-top-spacing">
    <div
      v-if="
        (user && user.user_type == 'superAdmin') ||
          (user && user.user_type == 'branchManager')
      "
      class="text-right"
    >
      <router-link :to="{ name: 'Add_Lead' }">
        <button class="btn btn-info mb-2 mr-4 btn-rounded">
          Add Lead
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-plus-circle"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="12" y1="8" x2="12" y2="16" />
            <line x1="8" y1="12" x2="16" y2="12" />
          </svg>
        </button>
      </router-link>

      <router-link :to="{ name: 'Leads' }">
        <button class="btn btn-info mb-2 mr-4 btn-rounded">
          View Lead
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-eye"
          >
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
            <circle cx="12" cy="12" r="3"></circle>
          </svg>
        </button>
      </router-link>
    </div>
    <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
      <div class="statbox widget box box-shadow">
        <div class="widget-content widget-content-area">
          <div class="row">
            <div class="col col-md-4">
              <select
                v-model="country_name"
                class="form-control"
                @change="searchCountryApplication($event.target.value)"
              >
                <option value="" disabled>Select Country</option>
                <option
                  v-for="country in total_countries"
                  :key="country.id"
                  :value="country.id"
                  >{{ country.country.name }}</option
                >
              </select>
            </div>
            <div class="col col-md-4">
              <select
                v-model="application_status_search"
                class="form-control"
                :disabled="LoadAppStatus"
                @change="searchAppStatusLeads($event.target.value)"
              >
                <option
                  v-if="countrySearchStatues.length === 0"
                  value=""
                  disabled
                  >Before Select a Country
                </option>
                <option v-if="countrySearchStatues.length > 0" value="" disabled
                  >Search with Application Status
                </option>
                <option
                  v-for="countryStatus in countrySearchStatues"
                  :key="countryStatus.id"
                  :value="countryStatus.id"
                  >{{ countryStatus.status_name }}</option
                >
              </select>
            </div>
            <div class="col col-md-4">
              <div class="form-group mb-4">
                <input
                  v-model="search"
                  type="text"
                  class="form-control"
                  placeholder="Search by First or Last Name, Email, Phone"
                  @keyup="searchit"
                />
                <span v-if="searchLoading" class="search-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-loader spin mr-2"
                  >
                    <line x1="12" y1="2" x2="12" y2="6"></line>
                    <line x1="12" y1="18" x2="12" y2="22"></line>
                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                    <line x1="2" y1="12" x2="6" y2="12"></line>
                    <line x1="18" y1="12" x2="22" y2="12"></line>
                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                  </svg>
                </span>
                <span v-if="!searchLoading" class="search-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </span>
              </div>
            </div>
            <div v-if="isAssign" class="col col-md-12 ">
              <div>
                <strong>
                  <span class="badge badge-secondary badge-pill">
                    {{ totalSelected }}
                  </span>
                  Selected
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7"></polyline>
                    <polyline points="6 17 11 12 6 7"></polyline>
                  </svg>
                </strong>
                <strong
                  class="assign-text"
                  type="button"
                  @click="assigntoCounselor"
                  >Assign to Counselor</strong
                >

                <div class="text-center">
                  <!-- Modal -->
                  <div
                    id="counselorAssignModal"
                    class="modal fade"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <form @submit.prevent="handleAssignSubmit">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 id="exampleModalLabel" class="modal-title">
                              Assign to Counselor
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-x"
                              >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div v-if="user && user.user_type === 'superAdmin'">
                              <div class="form-group">
                                <label style="display: block; text-align: left;"
                                  >Select Branch to assign Counselor</label
                                >
                                <select
                                  v-model="selected_a_branch"
                                  class="form-control"
                                  @change="activeBranchId($event.target.value)"
                                >
                                  <option value="" disabled
                                    >Choose Branch</option
                                  >
                                  <option
                                    v-for="branch in activeBranches"
                                    :key="branch.id"
                                    :value="branch.id"
                                    >{{ branch.branch_name }}</option
                                  >
                                </select>
                              </div>
                              <div v-if="isSelectBranch" class="form-group">
                                <label style="display: block; text-align: left;"
                                  >Assign selected Items to counselor</label
                                >
                                <select
                                  v-model="selected_counselor"
                                  class="form-control"
                                  @change="counselorId($event)"
                                >
                                  <option value="" disabled
                                    >Choose Counselor</option
                                  >
                                  <option
                                    v-for="counselor in counselors"
                                    :key="counselor.id"
                                    :value="counselor.id"
                                    >{{ counselor.counselor_name }}</option
                                  >
                                </select>
                              </div>
                            </div>
                            <div v-else>
                              <div class="form-group">
                                <label style="display: block; text-align: left;"
                                  >Assign selected Items to counselor</label
                                >
                                <select
                                  v-model="selected_counselor"
                                  class="form-control"
                                  @change="counselorId($event)"
                                >
                                  <option value="" disabled
                                    >Choose Counselor</option
                                  >
                                  <option
                                    v-for="counselor in counselors"
                                    :key="counselor.id"
                                    :value="counselor.id"
                                    >{{ counselor.counselor_name }}</option
                                  >
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer text-left">
                            <button class="btn btn-sm" data-dismiss="modal">
                              Cancel
                            </button>
                            <button type="submit" class="btn btn-sm btn-info">
                              Save
                              <span v-if="lazyLoadAssign">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-loader spin mr-2"
                                >
                                  <line x1="12" y1="2" x2="12" y2="6"></line>
                                  <line x1="12" y1="18" x2="12" y2="22"></line>
                                  <line
                                    x1="4.93"
                                    y1="4.93"
                                    x2="7.76"
                                    y2="7.76"
                                  ></line>
                                  <line
                                    x1="16.24"
                                    y1="16.24"
                                    x2="19.07"
                                    y2="19.07"
                                  ></line>
                                  <line x1="2" y1="12" x2="6" y2="12"></line>
                                  <line x1="18" y1="12" x2="22" y2="12"></line>
                                  <line
                                    x1="4.93"
                                    y1="19.07"
                                    x2="7.76"
                                    y2="16.24"
                                  ></line>
                                  <line
                                    x1="16.24"
                                    y1="7.76"
                                    x2="19.07"
                                    y2="4.93"
                                  ></line>
                                </svg>
                              </span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col col-md-12">
              <div id="tableSimple" class="col-lg-12 col-12 p-0">
                <div class="table-responsive">
                  <table
                    id="manage_app_process"
                    class="table table-bordered mb-4"
                  >
                    <thead>
                      <tr>
                        <th class="checkbox-column">
                          <input
                            v-model="selectAll"
                            type="checkbox"
                            @click="select"
                          />
                        </th>
                        <th></th>
                        <th></th>
                        <th>No.</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Branch</th>
                        <th style="text-align: center">Applicant Ready</th>
                        <th
                          v-if="
                            (user && user.user_type === 'superAdmin') ||
                              (user && user.user_type === 'branchManager') ||
                              (user && user.user_type === 'counselor')
                          "
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(lead, index) in leads" :key="lead.id">
                        <tr class="lead-row">
                          <td>
                            <input
                              v-model="selected"
                              type="checkbox"
                              :value="lead.id"
                              @click="selectSingle(lead.id)"
                            />
                          </td>
                          <td>
                            <span
                              style="cursor: pointer;"
                              @click="toggle(index)"
                            >
                              <svg
                                :class="index == isOpenIndex ? 'plus-off' : ''"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-plus-square"
                              >
                                <rect
                                  x="3"
                                  y="3"
                                  width="18"
                                  height="18"
                                  rx="2"
                                  ry="2"
                                ></rect>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                              </svg>

                              <svg
                                v-if="index == isOpenIndex"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-minus-square"
                              >
                                <rect
                                  x="3"
                                  y="3"
                                  width="18"
                                  height="18"
                                  rx="2"
                                  ry="2"
                                ></rect>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                              </svg>
                            </span>
                          </td>
                          <td>
                            <span
                              class="badge badge-pill badge-sm"
                              :style="{
                                background:
                                  lead.lead_application_status_id &&
                                  lead.application_status.status_color
                                    ? lead.application_status.status_color
                                    : '#1b55e2'
                              }"
                              style="position: relative;"
                              @click="
                                (user && user.user_type === 'superAdmin') ||
                                (user && user.user_type === 'branchManager') ||
                                (user && user.user_type === 'counselor')
                                  ? changeAppStatus(
                                      lead.interested_country_id,
                                      lead.id,
                                      lead.lead_application_status_id
                                    )
                                  : ''
                              "
                            >
                              {{
                                lead.lead_application_status_id
                                  ? lead.application_status &&
                                    lead.application_status.status_name
                                  : "Set Status"
                              }}
                              <span
                                v-if="
                                  (user && user.user_type === 'superAdmin') ||
                                    (user &&
                                      user.user_type === 'branchManager') ||
                                    (user && user.user_type === 'counselor')
                                "
                                class="app-edit-visible"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-edit-3 icon custom-edit-icon"
                                  data-v-0920824e=""
                                >
                                  <path d="M12 20h9" data-v-0920824e=""></path>
                                  <path
                                    d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"
                                    data-v-0920824e=""
                                  ></path>
                                </svg>
                              </span>
                            </span>
                          </td>
                          <td>
                            {{ page * 15 - 15 + index + 1 }}
                          </td>
                          <td
                            style="position: relative;"
                            @click="openAppLeadInfo(lead.id)"
                          >
                            {{ lead.first_name }}
                            {{ lead.last_name }}
                            <span
                              class="preview-lead"
                              @click="openAppLeadInfo(lead.id)"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-eye"
                                data-v-26430ed1=""
                              >
                                <path
                                  d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                                  data-v-26430ed1=""
                                ></path>
                                <circle
                                  cx="12"
                                  cy="12"
                                  r="3"
                                  data-v-26430ed1=""
                                ></circle>
                              </svg>
                            </span>
                          </td>

                          <td>
                            {{ lead.phone }}
                          </td>
                          <td>
                            {{ lead.email }}
                          </td>
                          <td>
                            {{ lead.branch && lead.branch.branch_name }}
                          </td>
                          <td style="text-align: center">
                            <span
                              :class="
                                lead.lead_application_status == 1
                                  ? 'badge badge-pill badge-success'
                                  : 'badge badge-pill badge-danger'
                              "
                            >
                              {{
                                lead.lead_application_status == 1 ? "Yes" : "No"
                              }}
                            </span>
                          </td>

                          <td
                            v-if="
                              (user && user.user_type === 'superAdmin') ||
                                (user && user.user_type === 'branchManager') ||
                                (user && user.user_type === 'counselor')
                            "
                          >
                            <router-link
                              :to="{
                                name: 'Edit_Lead',
                                params: { id: lead.id }
                              }"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-edit-3 icon custom-edit-icon"
                              >
                                <path d="M12 20h9"></path>
                                <path
                                  d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"
                                ></path>
                              </svg>
                            </router-link>
                            <router-link
                              :to="{
                                name: 'Send_Application_Email',
                                params: { id: lead.id }
                              }"
                            >
                              <span class="ml-2"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-mail"
                                >
                                  <path
                                    d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                                  ></path>
                                  <polyline
                                    points="22,6 12,13 2,6"
                                  ></polyline></svg
                                ><br />
                              </span>
                            </router-link>
                          </td>
                        </tr>
                        <tr v-if="index == isOpenIndex" class="sub-row-th">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>Category</th>
                          <th>Course Level</th>
                          <th>Intersted Country</th>
                          <th>Assigned Counselor</th>
                          <th>Lead Source</th>
                        </tr>
                        <tr v-if="index == isOpenIndex">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {{ lead.category && lead.category.category_name }}
                          </td>
                          <td>
                            {{
                              lead.course_level &&
                                lead.course_level.course_level_name
                            }}
                          </td>
                          <td>
                            {{ lead.country && lead.country.country.name }}
                          </td>

                          <td>
                            {{
                              lead.counselor && lead.counselor.counselor_name
                            }}
                          </td>

                          <td>
                            {{
                              lead.lead_source && lead.lead_source.source_name
                            }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <div class="text-center">
                    <!-- Modal -->
                    <div
                      id="applicationStatusModal"
                      class="modal fade"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="applicationStatusLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <form @submit.prevent="handleAppStatusSubmit">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5
                                id="applicationStatusLabel"
                                class="modal-title"
                              >
                                Choose Application Status
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <svg
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-x"
                                >
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div class="form-group">
                                <label style="display: block; text-align: left;"
                                  >Select Application Present Status
                                  <span v-if="LoadAppStatus">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-loader spin mr-2"
                                    >
                                      <line
                                        x1="12"
                                        y1="2"
                                        x2="12"
                                        y2="6"
                                      ></line>
                                      <line
                                        x1="12"
                                        y1="18"
                                        x2="12"
                                        y2="22"
                                      ></line>
                                      <line
                                        x1="4.93"
                                        y1="4.93"
                                        x2="7.76"
                                        y2="7.76"
                                      ></line>
                                      <line
                                        x1="16.24"
                                        y1="16.24"
                                        x2="19.07"
                                        y2="19.07"
                                      ></line>
                                      <line
                                        x1="2"
                                        y1="12"
                                        x2="6"
                                        y2="12"
                                      ></line>
                                      <line
                                        x1="18"
                                        y1="12"
                                        x2="22"
                                        y2="12"
                                      ></line>
                                      <line
                                        x1="4.93"
                                        y1="19.07"
                                        x2="7.76"
                                        y2="16.24"
                                      ></line>
                                      <line
                                        x1="16.24"
                                        y1="7.76"
                                        x2="19.07"
                                        y2="4.93"
                                      ></line>
                                    </svg> </span
                                ></label>
                                <select
                                  v-model="application_status"
                                  class="form-control"
                                  :disabled="LoadAppStatus"
                                  @change="onChangeAppStatusId($event)"
                                >
                                  <option value="" disabled
                                    >Choose Application Status
                                  </option>
                                  <option
                                    v-for="countryStatus in countryStatues"
                                    :key="countryStatus.id"
                                    :value="countryStatus.id"
                                    >{{ countryStatus.status_name }}</option
                                  >
                                </select>
                              </div>
                            </div>
                            <div class="modal-footer text-left">
                              <button class="btn btn-sm" data-dismiss="modal">
                                Cancel
                              </button>
                              <button type="submit" class="btn btn-sm btn-info">
                                Save
                                <span v-if="lazyLoadApp">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-loader spin mr-2"
                                  >
                                    <line x1="12" y1="2" x2="12" y2="6"></line>
                                    <line
                                      x1="12"
                                      y1="18"
                                      x2="12"
                                      y2="22"
                                    ></line>
                                    <line
                                      x1="4.93"
                                      y1="4.93"
                                      x2="7.76"
                                      y2="7.76"
                                    ></line>
                                    <line
                                      x1="16.24"
                                      y1="16.24"
                                      x2="19.07"
                                      y2="19.07"
                                    ></line>
                                    <line x1="2" y1="12" x2="6" y2="12"></line>
                                    <line
                                      x1="18"
                                      y1="12"
                                      x2="22"
                                      y2="12"
                                    ></line>
                                    <line
                                      x1="4.93"
                                      y1="19.07"
                                      x2="7.76"
                                      y2="16.24"
                                    ></line>
                                    <line
                                      x1="16.24"
                                      y1="7.76"
                                      x2="19.07"
                                      y2="4.93"
                                    ></line>
                                  </svg>
                                </span>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-md-12">
              <div class="lms-pagination">
                <pagination
                  v-if="notSearch"
                  v-model="page"
                  :records="total_app_leads"
                  :per-page="15"
                  class="custom-pagination"
                  @paginate="app_leads"
                />
                <pagination
                  v-if="isCountrySearch"
                  v-model="page"
                  :records="total_country_app_leads"
                  :per-page="15"
                  class="custom-pagination"
                  @paginate="searchCountryApplication(countryId)"
                />
                <pagination
                  v-if="isCountryStatus"
                  v-model="page"
                  :records="total_app_status_leads"
                  :per-page="15"
                  class="custom-pagination"
                  @paginate="searchAppStatusLeads(countryStatusId)"
                />
                <pagination
                  v-if="isSearchText"
                  v-model="page"
                  :records="total_app_status_leads_text"
                  :per-page="15"
                  class="custom-pagination"
                  @paginate="searchit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <lead-drawer
        :drawer-active="drawerActive"
        :lead-id="leadId"
        @close-drawer="closeDrawer"
        @getDrawerLead="getDrawerLead"
        @all_app_leads="all_app_leads"
      />
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";

import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import Request from "../../apis/Request";
import LeadDrawer from "./LeadDrawer.vue";
import Notify from "../../helpers/Notify";
export default {
  name: "Leads",
  components: { Loader, LeadDrawer },
  emits: ["getDrawerLead"],
  data() {
    return {
      form: {
        country: ""
      },
      leads: [],
      errors: {},
      isLoading: false,
      drawerLoading: false,
      page: 1,
      total_app_leads: 0,
      total_country_app_leads: 0,
      total_app_status_leads: 0,
      total_app_status_leads_text: 0,
      leadId: 0,
      drawerActive: false,
      selected: [],
      selectAll: false,
      isAssign: false,
      lazyLoadAssign: false,
      lazyLoadApp: false,
      LoadAppStatus: false,
      selectItems: "",
      selected_counselor: "",
      counselors: [],
      countrySearchStatues: [],
      countryStatues: [],
      isOpen: -1,
      application_status: "",
      application_status_search: "",
      app_lead_id: "",
      total_countries: [],
      country_name: "",
      isCountrySearch: false,
      isCountryStatus: false,
      searchLoading: false,
      isSearchText: false,
      notSearch: true,
      countryId: "",
      countryStatusId: "",
      isSelectBranch: false,
      activeBranches: [],
      selected_a_branch: ""
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"]),
    ...mapState(["baseURL"]),
    totalSelected() {
      return this.selectItems;
    },
    isOpenIndex() {
      return this.isOpen;
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("user");
    this.all_app_leads();
    this.totalAppLeads();
    this.getCounselor();
    this.totalCountry();
    this.getActiveBranch();

    this.emitter.on("application-reload-lead", () => {
      this.all_app_leads();
      this.totalAppLeads();
    });
  },
  methods: {
    all_app_leads() {
      let branchId = this.user && this.user.branch_id;
      Request.GET_REQ(`/all-application-leads/${branchId}?page=${this.page}`)
        .then(res => {
          this.leads = res.data.data;
          this.notSearch = true;
          this.isCountrySearch = false;
          this.isCountryStatus = false;
          this.isSearchText = false;
          this.searchLoading = false;
          console.log("Leads>>>", this.leads);
        })
        .catch(err => {
          this.errors = err.res.data.errors;
          console.log(this.errors);
        });
    },
    app_leads() {
      let branchId = this.user && this.user.branch_id;
      Request.GET_REQ(`/all-application-leads/${branchId}?page=${this.page}`)
        .then(res => {
          this.leads = res.data.data;
          console.log("Leads>>>", this.leads);
        })
        .catch(err => {
          this.errors = err.res.data.errors;
          console.log(this.errors);
        });
    },
    totalAppLeads() {
      let branchId = this.user && this.user.branch_id;
      Request.GET_REQ(`/total-application-leads/${branchId}`)
        .then(res => {
          this.total_app_leads = res.data;
          this.isLoading = false;
          console.log("total Leads>>>", this.total_app_leads);
        })
        .catch(err => {
          this.errors = err.res.data.errors;
          console.log(this.errors);
        });

      this.emitter.on("reload", () => {
        this.all_app_leads();
      });
    },
    openAppLeadInfo(leadId) {
      this.$router.push({
        name: "Application_Lead_Info",
        params: { id: leadId }
      });
    },
    closeDrawer() {
      this.drawerActive = false;
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.leads) {
          this.selected.push(this.leads[i].id);
        }
      }
      this.selectItems = this.selected.length;

      if (this.selected.length) {
        this.isAssign = true;
      } else {
        this.isAssign = false;
      }
    },
    selectSingle(leadId) {
      let arr = this.selected;
      if (arr.includes(leadId)) {
        arr.splice(arr.indexOf(leadId), 1);
        this.selectItems = this.selected.length;

        if (this.selected.length) {
          this.isAssign = true;
        } else {
          this.isAssign = false;
        }
        return;
      }
      arr.push(leadId);
      this.selectItems = this.selected.length;
      if (this.selected.length) {
        this.isAssign = true;
      } else {
        this.isAssign = false;
      }
    },
    getCounselor() {
      if (this.user.user_type === "superAdmin") {
        Request.GET_REQ(`/active-counselor`)
          .then(res => {
            this.counselors = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      }
      if (
        this.user.user_type === "branchManager" ||
        this.user.user_type === "counselor"
      ) {
        Request.GET_REQ(`/active-counselor/${this.user.branch_id}`)
          .then(res => {
            this.counselors = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    handleAssignSubmit() {
      this.lazyLoadAssign = true;
      let data = {
        leadIds: this.selected,
        counselorId: this.selected_counselor,
        branchId: this.selected_a_branch || this.user.branch_id
      };

      Request.POST_REQ(data, `/lead-assign-counselor`)
        .then(() => {
          $("#counselorAssignModal").modal("hide");
          this.selected = [];
          this.selectAll = false;
          this.isAssign = false;
          this.emitter.emit("application-reload-lead");
          this.lazyLoadAssign = false;
          Notify.success("Successfully Assigned to Counselor");
          this.errors = {};
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        });
    },
    assigntoCounselor() {
      $("#counselorAssignModal").modal("show");
    },
    getActiveBranch() {
      Request.GET_REQ(`/active-branches`)
        .then(res => {
          this.activeBranches = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    counselorId(event) {
      let counselorId = event.target.value;
      this.selected_counselor = counselorId;
    },
    activeBranchId(branchId) {
      this.selected_a_branch = branchId;
      console.log(this.selected_a_branch);
      Request.GET_REQ(`/active-counselor/${branchId}`)
        .then(res => {
          this.counselors = res.data;
          this.isSelectBranch = true;
        })
        .catch(err => {
          console.log(err);
        });
    },
    toggle(index) {
      if (index == this.isOpen) {
        this.isOpen = -1;
      } else {
        this.isOpen = index;
      }
    },
    changeAppStatus(countryId, leadId, lead_app_status) {
      this.LoadAppStatus = true;
      $("#applicationStatusModal").modal("show");
      this.app_lead_id = leadId;

      Request.GET_REQ(`/active-application-status/${countryId}`)
        .then(res => {
          this.countryStatues = res.data;
          this.LoadAppStatus = false;
          this.application_status = lead_app_status;
        })
        .catch(err => {
          console.log(err);
        });
    },
    onChangeAppStatusId(event) {
      this.application_status = event.target.value;
    },
    handleAppStatusSubmit() {
      this.lazyLoadApp = true;
      let data = {
        lead_application_status_id: this.application_status
      };
      Request.POST_REQ(data, `/update-application-status/${this.app_lead_id}`)
        .then(() => {
          $("#applicationStatusModal").modal("hide");
          this.emitter.emit("application-reload-lead");
          this.lazyLoadApp = false;
          Notify.success("Successfully Update Application Status");
          this.errors = {};
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        });
    },
    totalCountry() {
      this.isLoading = true;
      Request.GET_REQ("/total-represent-countries")
        .then(res => {
          this.total_country = res.data.length;
          this.total_countries = res.data;
          this.isLoading = false;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
          console.log(this.errors);
        });
    },
    searchCountryApplication(countryId) {
      let branchId = this.user && this.user.branch_id;
      this.countryId = countryId;
      this.isSearch = true;

      Request.GET_REQ(`/search-active-application-status/${this.countryId}`)
        .then(res => {
          this.countrySearchStatues = res.data;
        })
        .catch(err => {
          console.log(err);
        });

      if (branchId) {
        Request.GET_REQ(
          `/country-application-leads/${branchId}/${this.countryId}?page=${this.page}`
        )
          .then(res => {
            this.leads = res.data.data;
            this.notSearch = false;
            this.isCountryStatus = false;
            this.isCountrySearch = true;
            this.isSearchText = false;
            console.log(res.data);
          })
          .catch(err => {
            this.errors = err.res.data.errors;
            console.log(this.errors);
          });

        //total country application leads

        Request.GET_REQ(
          `/total-country-application-leads/${branchId}/${this.countryId}`
        )
          .then(res => {
            this.total_country_app_leads = res.data.length;
            console.log(res.data.length);
          })
          .catch(err => {
            this.errors = err.res.data.errors;
            console.log(this.errors);
          });
      } else {
        Request.GET_REQ(
          `/country-application-leads/${this.countryId}?page=${this.page}`
        )
          .then(res => {
            this.leads = res.data.data;
            this.notSearch = false;
            this.isCountryStatus = false;
            this.isCountrySearch = true;
            this.isSearchText = false;
            console.log(res.data);
          })
          .catch(err => {
            this.errors = err.res.data.errors;
            console.log(this.errors);
          });

        //total country application leads

        Request.GET_REQ(`/total-country-application-leads/${this.countryId}`)
          .then(res => {
            this.total_country_app_leads = res.data.length;
            console.log(res.data.length);
          })
          .catch(err => {
            this.errors = err.res.data.errors;
            console.log(this.errors);
          });
      }
    },

    searchAppStatusLeads(countryStatusId) {
      let branchId = this.user && this.user.branch_id;
      this.countryStatusId = countryStatusId;
      this.isSearch = true;

      if (branchId) {
        Request.GET_REQ(
          `/country-status-app-leads/${branchId}/${this.countryId}/${this.countryStatusId}?page=${this.page}`
        )
          .then(res => {
            this.leads = res.data.data;
            this.notSearch = false;
            this.isCountrySearch = false;
            this.isCountryStatus = true;
            this.isSearchText = false;
            console.log(res.data);
          })
          .catch(err => {
            this.errors = err.res.data.errors;
            console.log(this.errors);
          });

        //total country application leads

        Request.GET_REQ(
          `/total-country-status-app-leads/${branchId}/${this.countryId}/${this.countryStatusId}`
        )
          .then(res => {
            this.total_app_status_leads = res.data.length;
            console.log(res.data.length);
          })
          .catch(err => {
            this.errors = err.res.data.errors;
            console.log(this.errors);
          });
      } else {
        Request.GET_REQ(
          `/country-status-app-leads/${this.countryId}/${this.countryStatusId}?page=${this.page}`
        )
          .then(res => {
            this.leads = res.data.data;
            this.notSearch = false;
            this.isCountrySearch = false;
            this.isCountryStatus = true;
            this.isSearchText = false;
            console.log(res.data);
          })
          .catch(err => {
            this.errors = err.res.data.errors;
            console.log(this.errors);
          });

        //total country application leads

        Request.GET_REQ(
          `/total-country-status-app-leads/${this.countryId}/${this.countryStatusId}`
        )
          .then(res => {
            this.total_app_status_leads = res.data.length;
            console.log(res.data.length);
          })
          .catch(err => {
            this.errors = err.res.data.errors;
            console.log(this.errors);
          });
      }
    },
    searchit: _.debounce(function() {
      let branchId = this.user && this.user.branch_id;
      this.searchLoading = true;
      const query = this.search;
      if (query.length > 0) {
        this.isSearch = true;
        if (branchId) {
          if (query) {
            Request.GET_REQ(
              `/search-lead-application-by-text/${branchId}?page=${this.page}&q=${query}`
            )
              .then(res => {
                this.leads = res.data.data;
                this.notSearch = false;
                this.isCountrySearch = false;
                this.isCountryStatus = false;
                this.searchLoading = false;
                this.isSearchText = true;
                console.log(res.data);
              })
              .catch(err => {
                this.errors = err.res.data.errors;
                console.log(this.errors);
              });

            Request.GET_REQ(
              `/total-search-lead-application-by-text/${branchId}?q=${query}`
            )
              .then(res => {
                this.total_app_status_leads_text = res.data.length;
                console.log(res.data.length);
              })
              .catch(err => {
                this.errors = err.res.data.errors;
                console.log(this.errors);
              });
          }
          if (this.countryId && this.countryStatusId) {
            Request.GET_REQ(
              `/search-application-by-text/${branchId}/${this.countryId}/${this.countryStatusId}?page=${this.page}&q=${query}`
            )
              .then(res => {
                this.leads = res.data.data;
                this.notSearch = false;
                this.isCountrySearch = false;
                this.isCountryStatus = false;
                this.searchLoading = false;
                this.isSearchText = true;
                console.log(res.data);
              })
              .catch(err => {
                this.errors = err.res.data.errors;
                console.log(this.errors);
              });

            Request.GET_REQ(
              `/total-search-application-by-text/${branchId}/${this.countryId}/${this.countryStatusId}?q=${query}`
            )
              .then(res => {
                this.total_app_status_leads_text = res.data.length;
                console.log(res.data.length);
              })
              .catch(err => {
                this.errors = err.res.data.errors;
                console.log(this.errors);
              });
          }
          if (this.countryId) {
            Request.GET_REQ(
              `/search-application-by-text-with-country/${branchId}/${this.countryId}?page=${this.page}&q=${query}`
            )
              .then(res => {
                this.leads = res.data.data;
                this.notSearch = false;
                this.isCountrySearch = false;
                this.isCountryStatus = false;
                this.searchLoading = false;
                this.isSearchText = true;
                console.log(res.data);
              })
              .catch(err => {
                this.errors = err.res.data.errors;
                console.log(this.errors);
              });

            Request.GET_REQ(
              `/total-search-application-by-text-with-country/${branchId}/${this.countryId}?q=${query}`
            )
              .then(res => {
                this.total_app_status_leads_text = res.data.length;
                console.log(res.data.length);
              })
              .catch(err => {
                this.errors = err.res.data.errors;
                console.log(this.errors);
              });
          }
        } else {
          if (query) {
            Request.GET_REQ(
              `/search-lead-application-by-text-superadmin?page=${this.page}&q=${query}`
            )
              .then(res => {
                this.leads = res.data.data;
                this.notSearch = false;
                this.isCountrySearch = false;
                this.isCountryStatus = false;
                this.searchLoading = false;
                this.isSearchText = true;
                console.log(res.data);
              })
              .catch(err => {
                this.errors = err.res.data.errors;
                console.log(this.errors);
              });

            Request.GET_REQ(
              `/total-search-lead-application-by-text-superadmin?q=${query}`
            )
              .then(res => {
                this.total_app_status_leads_text = res.data.length;
                console.log(res.data.length);
              })
              .catch(err => {
                this.errors = err.res.data.errors;
                console.log(this.errors);
              });
          }

          if (this.countryId && this.countryStatusId) {
            Request.GET_REQ(
              `/search-application-by-text-superadmin/${this.countryId}/${this.countryStatusId}?page=${this.page}&q=${query}`
            )
              .then(res => {
                this.leads = res.data.data;
                this.notSearch = false;
                this.isCountrySearch = false;
                this.isCountryStatus = false;
                this.searchLoading = false;
                this.isSearchText = true;
                console.log(res.data);
              })
              .catch(err => {
                this.errors = err.res.data.errors;
                console.log(this.errors);
              });

            Request.GET_REQ(
              `/total-search-application-by-text-superadmin/${this.countryId}/${this.countryStatusId}?q=${query}`
            )
              .then(res => {
                this.total_app_status_leads_text = res.data.length;
                console.log(res.data.length);
              })
              .catch(err => {
                this.errors = err.res.data.errors;
                console.log(this.errors);
              });
          }
          if (this.countryId) {
            Request.GET_REQ(
              `/search-application-by-text-with-country/${this.countryId}?page=${this.page}&q=${query}`
            )
              .then(res => {
                this.leads = res.data.data;
                this.notSearch = false;
                this.isCountrySearch = false;
                this.isCountryStatus = false;
                this.searchLoading = false;
                this.isSearchText = true;
                console.log(res.data);
              })
              .catch(err => {
                this.errors = err.res.data.errors;
                console.log(this.errors);
              });

            Request.GET_REQ(
              `/total-search-application-by-text-with-country/${this.countryId}?q=${query}`
            )
              .then(res => {
                this.total_app_status_leads_text = res.data.length;
                console.log(res.data.length);
              })
              .catch(err => {
                this.errors = err.res.data.errors;
                console.log(this.errors);
              });
          }
        }
      } else {
        this.isSearch = false;
        this.all_app_leads();
        this.totalAppLeads();
      }
    }, 2000)
  }
};
</script>
<style scoped lang="scss">
.component-card_4 {
  width: auto;
  .user-info {
    width: 100%;
    padding: 5px 0 20px 0px;
  }
  .logo-title-wrap {
    img {
      border-radius: unset;
      height: 80px;
    }
  }
  .custom-btn-branch {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  .custom-list-group-branch .list-group-item {
    background: transparent;
    padding: 0;
    border: unset;
    position: relative;
    margin: 7px 0;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background: #e0e6ed;
      left: 0;
      bottom: -7px;
    }
    &::last-child::before {
      background: transparent !important;
      content: unset;
    }
    h6 {
      margin: 0;
      position: relative;
      padding-left: 20px;
      font-size: 14px;

      &::before {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        left: 0;
        border-radius: 3px;
      }
    }
    h6.count-primary {
      &::before {
        background: #1b55e2;
      }
    }
    h6.count-info {
      &::before {
        background: #2196f3;
      }
    }
    h6.count-success {
      &::before {
        background: #8dbf42;
      }
    }
    h6.count-warning {
      &::before {
        background: #e2a03f;
      }
    }
    h6.count-danger {
      &::before {
        background: #e7515a;
      }
    }
    h6.count-secondary {
      &::before {
        background: #5c1ac3;
      }
    }
  }
  .card-user_name {
    font-size: 24px;
  }
}

body.dark .component-card_4 {
  .custom-list-group-branch .list-group-item {
    &::before {
      background: #1b2e4b;
    }
  }
}

ul.list-group.custom-list-group-branch li:last-child:before {
  background: transparent !important;
}
.page-link {
  background-color: #0e1726 !important;
}
.lead-row {
  cursor: pointer;
}
body.dark tr.lead-row:hover {
  background-color: #060818;
}
tr.lead-row:hover {
  background-color: #fafafa;
}
.assign-text {
  text-decoration: underline;
  cursor: pointer;
}
.modal-footer {
  display: block !important;
  padding: 15px 25px 15px;
}
.preview-lead {
  display: none;
  position: absolute;
  right: 0;
  top: 30%;
}
.lead-row:hover .preview-lead,
.lead-row:hover .app-edit-visible {
  display: block;
}
.plus-off {
  display: none;
}
body.dark .sub-row-th th {
  color: #d3d3d3;
}
.sub-row-th th {
  text-transform: uppercase;
  color: #1b55e2;
  font-size: 12px;
}
.search-icon {
  position: absolute;
  right: 5%;
  top: 15%;
}
.app-edit-visible {
  position: absolute;
  right: -25px;
  display: none;
  top: 0;
}
</style>
