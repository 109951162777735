<template>
  <div v-if="pendingStatus">
    <Loader />
  </div>
  <div v-else>
    <div
      v-if="user && user.user_type === 'superAdmin'"
      class="layout-px-spacing"
    >
      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row">
              <div class="col-md-4">
                <h6 class="pb-3 text-center">Branch Leads</h6>
                <branch-chart :theme-mode-data="themeModeData" />
              </div>
              <div class="col-md-4">
                <monthly-leads-chart :theme-mode-data="themeModeData" />
              </div>

              <div class="col-md-4">
                <h6 class="pb-3 text-center">Branch Application Leads</h6>
                <monthly-application-leads-chart
                  :theme-mode-data="themeModeData"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row">
              <div class="col-md-6">
                <country-application-leads :theme-mode-data="themeModeData" />
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-10">
                    <last-10-days-leads :theme-mode-data="themeModeData" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="user && user.user_type === 'branchManager'">
      <BranchDahsboard :theme-mode-data="themeModeData" />
    </div>

    <div v-if="user && user.user_type === 'counselor'">
      <CounselorDahsboard :theme-mode-data="themeModeData" />
    </div>

    <div v-if="user && user.user_type === 'frontOfficer'">
      <frontOfficerDahsboard :theme-mode-data="themeModeData" />
    </div>
    <div v-if="user && user.user_type === 'agent'">
      <AgentDahsboard :theme-mode-data="themeModeData" />
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import { mapGetters } from "vuex";
import BranchChart from "../components/chart/BranchChart.vue";
import MonthlyLeadsChart from "../components/chart/MonthlyLeadsChart.vue";
import MonthlyApplicationLeadsChart from "../components/chart/MonthlyApplicationLeadsChart.vue";
import CountryApplicationLeads from "../components/chart/CountryApplicationLeads.vue";
import Last10DaysLeads from "../components/chart/Last10DaysLeads.vue";
import BranchDahsboard from "../views/branch/BranchDashboard";
import CounselorDahsboard from "../views/counselor/CounselorDashboard";
import frontOfficerDahsboard from "../views/front-office/FrontOfficerDashboard";
import AgentDahsboard from "../views/agent/AgentDashboard";

export default {
  name: "Dashborard",
  components: {
    Loader,
    BranchChart,
    MonthlyLeadsChart,
    MonthlyApplicationLeadsChart,
    CountryApplicationLeads,
    Last10DaysLeads,
    BranchDahsboard,
    CounselorDahsboard,
    frontOfficerDahsboard,
    AgentDahsboard
  },
  props: {
    themeModeData: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"])
  },
  async mounted() {
    await this.$store.dispatch("user");
    this.isPending = false;
  }
};
</script>
<style scoped lang="scss">
.component-card_1 {
  width: auto;
}
</style>
