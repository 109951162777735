<template>
  <div v-if="pendingStatus || isLoading">
    <Loader />
  </div>
  <div v-else class="layout-px-spacing layout-top-spacing">
    <div class="text-right">
      <router-link
        v-if="
          (user && user.user_type == 'superAdmin') ||
            (user && user.user_type == 'branchManager')
        "
        :to="{ name: 'Add_Lead' }"
      >
        <button class="btn btn-info mb-2 mr-4 btn-rounded">
          Add Lead
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-plus-circle"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="12" y1="8" x2="12" y2="16" />
            <line x1="8" y1="12" x2="16" y2="12" />
          </svg>
        </button>
      </router-link>

      <router-link
        v-if="
          (user && user.user_type == 'superAdmin') ||
            (user && user.user_type == 'branchManager') ||
            (user && user.user_type == 'counselor') ||
            (user && user.user_type == 'frontOfficer')
        "
        :to="{ name: 'Application_Leads' }"
      >
        <button class="btn btn-info mb-2 mr-4 btn-rounded">
          Application Lead
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-eye"
          >
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
            <circle cx="12" cy="12" r="3"></circle>
          </svg>
        </button>
      </router-link>

      <button
        v-if="
          (user && user.user_type == 'branchManager') ||
            (user && user.user_type == 'counselor') ||
            (user && user.user_type == 'frontOfficer')
        "
        class="btn btn-primary mb-2 mr-4 btn-rounded"
        @click="myBranchLeads"
      >
        My Branch Leads
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-eye"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
          <circle cx="12" cy="12" r="3"></circle>
        </svg>
      </button>

      <button
        v-if="
          (user && user.user_type == 'branchManager') ||
            (user && user.user_type == 'counselor') ||
            (user && user.user_type == 'frontOfficer')
        "
        class="btn btn-secondary mb-2 mr-4 btn-rounded"
        @click="allBranchLeads"
      >
        All Branch Leads
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-eye"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
          <circle cx="12" cy="12" r="3"></circle>
        </svg>
      </button>
    </div>
    <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
      <div class="statbox widget box box-shadow">
        <div class="widget-content widget-content-area">
          <div class="row">
            <div class="col col-md-5 offset-md-7">
              <div class="form-group mb-4">
                <input
                  v-model="search"
                  type="text"
                  class="form-control"
                  placeholder="Search by First or Last Name, Email, Phone"
                  style="position: relative"
                  @keyup="searchit"
                />
                <span v-if="searchLoading" class="search-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-loader spin mr-2"
                  >
                    <line x1="12" y1="2" x2="12" y2="6"></line>
                    <line x1="12" y1="18" x2="12" y2="22"></line>
                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                    <line x1="2" y1="12" x2="6" y2="12"></line>
                    <line x1="18" y1="12" x2="22" y2="12"></line>
                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                  </svg>
                </span>
                <span v-if="!searchLoading" class="search-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </span>
              </div>
            </div>
            <div v-if="isAssign" class="col col-md-12 ">
              <div>
                <strong>
                  <span class="badge badge-secondary badge-pill">
                    {{ totalSelected }}
                  </span>
                  Selected
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7"></polyline>
                    <polyline points="6 17 11 12 6 7"></polyline>
                  </svg>
                </strong>
                <strong
                  class="assign-text"
                  type="button"
                  @click="assigntoCounselor"
                  >Assign to Counselor</strong
                >

                <div class="text-center">
                  <!-- Modal -->
                  <div
                    id="exampleModal"
                    class="modal fade"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <form @submit.prevent="handleAssignSubmit">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 id="exampleModalLabel" class="modal-title">
                              Assign to Counselor
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-x"
                              >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div v-if="user && user.user_type === 'superAdmin'">
                              <div class="form-group">
                                <label style="display: block; text-align: left;"
                                  >Select Branch to assign Counselor</label
                                >
                                <select
                                  v-model="selected_a_branch"
                                  class="form-control"
                                  @change="activeBranchId($event.target.value)"
                                >
                                  <option value="" disabled
                                    >Choose Branch</option
                                  >
                                  <option
                                    v-for="branch in activeBranches"
                                    :key="branch.id"
                                    :value="branch.id"
                                    >{{ branch.branch_name }}</option
                                  >
                                </select>
                              </div>
                              <div v-if="isSelectBranch" class="form-group">
                                <label style="display: block; text-align: left;"
                                  >Assign selected Items to counselor</label
                                >
                                <select
                                  v-model="selected_counselor"
                                  class="form-control"
                                  @change="counselorId($event)"
                                >
                                  <option value="" disabled
                                    >Choose Counselor</option
                                  >
                                  <option
                                    v-for="counselor in counselors"
                                    :key="counselor.id"
                                    :value="counselor.id"
                                    >{{ counselor.counselor_name }}</option
                                  >
                                </select>
                              </div>
                            </div>
                            <div v-else>
                              <div class="form-group">
                                <label style="display: block; text-align: left;"
                                  >Assign selected Items to counselor</label
                                >
                                <select
                                  v-model="selected_counselor"
                                  class="form-control"
                                  @change="counselorId($event)"
                                >
                                  <option value="" disabled
                                    >Choose Counselor</option
                                  >
                                  <option
                                    v-for="counselor in counselors"
                                    :key="counselor.id"
                                    :value="counselor.id"
                                    >{{ counselor.counselor_name }}</option
                                  >
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer text-left">
                            <button class="btn btn-sm" data-dismiss="modal">
                              Cancel
                            </button>
                            <button type="submit" class="btn btn-sm btn-info">
                              Save
                              <span v-if="lazyLoadAssign">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-loader spin mr-2"
                                >
                                  <line x1="12" y1="2" x2="12" y2="6"></line>
                                  <line x1="12" y1="18" x2="12" y2="22"></line>
                                  <line
                                    x1="4.93"
                                    y1="4.93"
                                    x2="7.76"
                                    y2="7.76"
                                  ></line>
                                  <line
                                    x1="16.24"
                                    y1="16.24"
                                    x2="19.07"
                                    y2="19.07"
                                  ></line>
                                  <line x1="2" y1="12" x2="6" y2="12"></line>
                                  <line x1="18" y1="12" x2="22" y2="12"></line>
                                  <line
                                    x1="4.93"
                                    y1="19.07"
                                    x2="7.76"
                                    y2="16.24"
                                  ></line>
                                  <line
                                    x1="16.24"
                                    y1="7.76"
                                    x2="19.07"
                                    y2="4.93"
                                  ></line>
                                </svg>
                              </span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col col-md-12">
              <div class="row">
                <div class="col-md-12 d-flex pb-2">
                  <div
                    v-if="user && user.user_type == 'superAdmin'"
                    class="text-right"
                  >
                    <a
                      :href="baseURL + 'api/export-leads'"
                      download="files.xlsx"
                      type="button"
                    >
                      <button class="btn btn-secondary mb-2 mr-4 btn-rounded">
                        Export Leads

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-file-text"
                        >
                          <path
                            d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
                          ></path>
                          <polyline points="14 2 14 8 20 8"></polyline>
                          <line x1="16" y1="13" x2="8" y2="13"></line>
                          <line x1="16" y1="17" x2="8" y2="17"></line>
                          <polyline points="10 9 9 9 8 9"></polyline>
                        </svg>
                      </button>
                    </a>
                  </div>

                  <div
                    v-if="user && user.user_type == 'superAdmin'"
                    class="text-right"
                  >
                    <router-link to="/import-leads">
                      <button class="btn btn-primary mb-2 mr-4 btn-rounded">
                        Import Leads
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-upload-cloud"
                        >
                          <polyline points="16 16 12 12 8 16"></polyline>
                          <line x1="12" y1="12" x2="12" y2="21"></line>
                          <path
                            d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"
                          ></path>
                          <polyline points="16 16 12 12 8 16"></polyline>
                        </svg>
                      </button>
                    </router-link>
                  </div>

                  <div
                    v-if="user && user.user_type == 'superAdmin'"
                    class="text-right"
                  >
                    <a
                      :href="baseURL + 'api/export-institute'"
                      download="files.xlsx"
                      type="button"
                    >
                      <button class="btn btn-info mb-2 mr-4 btn-rounded">
                        Export App Leads

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-file-text"
                        >
                          <path
                            d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
                          ></path>
                          <polyline points="14 2 14 8 20 8"></polyline>
                          <line x1="16" y1="13" x2="8" y2="13"></line>
                          <line x1="16" y1="17" x2="8" y2="17"></line>
                          <polyline points="10 9 9 9 8 9"></polyline>
                        </svg>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col col-md-12">
              <div id="tableSimple" class="col-lg-12 col-12 p-0">
                <div class="table-responsive">
                  <table
                    id="manage_app_process"
                    class="table table-bordered mb-4"
                  >
                    <thead>
                      <tr>
                        <th
                          v-if="
                            (user && user.user_type == 'branchManager') ||
                              (user && user.user_type == 'counselor') ||
                              (user && user.user_type == 'superAdmin')
                          "
                          class="checkbox-column"
                        >
                          <input
                            v-model="selectAll"
                            type="checkbox"
                            @click="select"
                          />
                        </th>
                        <th></th>
                        <th></th>
                        <th>No.</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Branch</th>
                        <th style="text-align: center">Applicant Ready</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <h3 v-if="leads.length == 0" class="mt-4">
                        No Data Found
                      </h3>
                      <template v-for="(lead, index) in leads" :key="lead.id">
                        <tr class="lead-row">
                          <td
                            v-if="
                              (user && user.user_type == 'branchManager') ||
                                (user && user.user_type == 'counselor') ||
                                (user && user.user_type == 'superAdmin')
                            "
                          >
                            <input
                              v-model="selected"
                              type="checkbox"
                              :value="lead.id"
                              @click="selectSingle(lead.id)"
                            />
                          </td>
                          <td>
                            <span
                              style="cursor: pointer;"
                              @click="toggle(index)"
                            >
                              <svg
                                :class="index == isOpenIndex ? 'plus-off' : ''"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-plus-square"
                              >
                                <rect
                                  x="3"
                                  y="3"
                                  width="18"
                                  height="18"
                                  rx="2"
                                  ry="2"
                                ></rect>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                              </svg>

                              <svg
                                v-if="index == isOpenIndex"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-minus-square"
                              >
                                <rect
                                  x="3"
                                  y="3"
                                  width="18"
                                  height="18"
                                  rx="2"
                                  ry="2"
                                ></rect>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                              </svg>
                            </span>
                          </td>
                          <td>
                            <span
                              v-if="
                                lead.lead_status && lead.lead_status.id == 1
                              "
                              class="badge badge-pill badge-sm bg-warning"
                              >{{
                                lead.lead_status && lead.lead_status.status_name
                              }}</span
                            >
                            <span
                              v-if="
                                lead.lead_status && lead.lead_status.id == 2
                              "
                              class="badge badge-pill badge-sm bg-primary"
                              >{{
                                lead.lead_status && lead.lead_status.status_name
                              }}</span
                            >
                            <span
                              v-if="
                                lead.lead_status && lead.lead_status.id == 3
                              "
                              class="badge badge-pill badge-sm bg-danger"
                              >{{
                                lead.lead_status && lead.lead_status.status_name
                              }}</span
                            >
                            <span
                              v-if="
                                lead.lead_status && lead.lead_status.id == 4
                              "
                              class="badge badge-pill badge-sm bg-info"
                              >{{
                                lead.lead_status && lead.lead_status.status_name
                              }}</span
                            >
                            <span
                              v-if="
                                lead.lead_status && lead.lead_status.id == 5
                              "
                              class="badge badge-pill badge-sm bg-success"
                              >{{
                                lead.lead_status && lead.lead_status.status_name
                              }}</span
                            >
                          </td>
                          <td>
                            {{ page * 15 - 15 + index + 1 }}
                          </td>
                          <td
                            style="position: relative;"
                            @click="openDrawer(lead.id)"
                          >
                            {{ lead.first_name }}
                            {{ lead.last_name }}
                            <span
                              class="preview-lead"
                              @click="openDrawer(lead.id)"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-eye"
                                data-v-26430ed1=""
                              >
                                <path
                                  d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                                  data-v-26430ed1=""
                                ></path>
                                <circle
                                  cx="12"
                                  cy="12"
                                  r="3"
                                  data-v-26430ed1=""
                                ></circle>
                              </svg>
                            </span>
                          </td>

                          <td>
                            {{ lead.phone }}
                          </td>
                          <td>
                            {{ lead.email }}
                          </td>
                          <td>
                            {{ lead.branch && lead.branch.branch_name }}
                          </td>
                          <td style="text-align: center">
                            <span
                              :class="
                                lead.lead_application_status == 1
                                  ? 'badge badge-pill badge-success'
                                  : 'badge badge-pill badge-danger'
                              "
                            >
                              {{
                                lead.lead_application_status == 1 ? "Yes" : "No"
                              }}
                            </span>
                          </td>

                          <td>
                            <router-link
                              v-if="
                                (user && user.user_type === 'superAdmin') ||
                                  (+lead.branch_id === user && +user.branch_id)
                              "
                              :to="{
                                name: 'Edit_Lead',
                                params: { id: lead.id }
                              }"
                            >
                              <FeatherEdit3Svg />
                            </router-link>

                            <router-link
                              :to="{
                                name: 'Edit_Lead',
                                params: { id: lead.id }
                              }"
                            >
                              <FeeatherEyeSvg />
                            </router-link>
                          </td>
                        </tr>
                        <tr v-if="index == isOpenIndex" class="sub-row-th">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>Category</th>
                          <th>Course Level</th>
                          <th>Intersted Country</th>
                          <th>Assigned Counselor</th>
                          <th>Lead Source</th>
                          <th>Lead Status</th>
                        </tr>
                        <tr v-if="index == isOpenIndex">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {{ lead.category && lead.category.category_name }}
                          </td>
                          <td>
                            {{
                              lead.course_level &&
                                lead.course_level.course_level_name
                            }}
                          </td>
                          <td>
                            {{ lead.country && lead.country.country.name }}
                          </td>

                          <td>
                            {{
                              lead.counselor && lead.counselor.counselor_name
                            }}
                          </td>

                          <td>
                            {{
                              lead.lead_source && lead.lead_source.source_name
                            }}
                          </td>
                          <td>
                            {{
                              lead.lead_status && lead.lead_status.status_name
                            }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-md-12">
              <div class="lms-pagination">
                <pagination
                  v-if="isSearch"
                  v-model="page"
                  :records="total_search_leads"
                  :per-page="15"
                  class="custom-pagination"
                  @paginate="searchit"
                />
                <pagination
                  v-if="isMyBranch"
                  v-model="page"
                  :records="total_my_branch_search_leads"
                  :per-page="15"
                  class="custom-pagination"
                  @paginate="myBranchLeads"
                />
                <pagination
                  v-if="isAllLeads"
                  v-model="page"
                  :records="total_leads"
                  :per-page="15"
                  class="custom-pagination"
                  @paginate="all_leads"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <lead-drawer
        :drawer-active="drawerActive"
        :lead-id="leadId"
        @close-drawer="closeDrawer"
        @getDrawerLead="getDrawerLead"
        @all_leads="all_leads"
      />
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import _ from "lodash";
import { mapGetters, mapState } from "vuex";

import Request from "../../apis/Request";
import LeadDrawer from "./LeadDrawer.vue";
import Notify from "../../helpers/Notify";
import FeatherEdit3Svg from "@/svg/FeatherEdit3Svg.vue";
import FeeatherEyeSvg from "@/svg/FeeatherEyeSvg.vue";
export default {
  name: "Leads",
  components: { Loader, LeadDrawer, FeatherEdit3Svg, FeeatherEyeSvg },
  emits: ["getDrawerLead"],
  data() {
    return {
      form: {
        country: ""
      },
      leads: [],
      errors: {},
      isLoading: false,
      drawerLoading: false,
      page: 1,
      total_leads: 0,
      total_search_leads: 0,
      total_my_branch_search_leads: 0,
      leadId: 0,
      drawerActive: false,
      selected: [],
      selectAll: false,
      isAssign: false,
      lazyLoadAssign: false,
      selectItems: "",
      selected_counselor: "",
      counselors: [],
      isOpen: -1,
      search: "",
      isAllLeads: true,
      isMyBranch: false,
      isSearch: false,
      searchLoading: false,
      isSelectBranch: false,
      activeBranches: [],
      selected_a_branch: ""
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"]),
    ...mapState(["baseURL"]),
    totalSelected() {
      return this.selectItems;
    },
    isOpenIndex() {
      return this.isOpen;
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("user");
    this.all_leads();
    this.totalLeads();
    this.getCounselor();
    this.getActiveBranch();

    this.emitter.on("reloadLead", () => {
      this.all_leads();
      this.totalLeads();
    });
  },
  methods: {
    all_leads() {
      this.isMyBranch = false;
      this.isSearch = false;
      let branchId = this.user && this.user.branch_id;
      Request.GET_REQ(`/all-leads/${branchId}?page=${this.page}`)
        .then(res => {
          this.leads = res.data.data;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });
    },
    totalLeads() {
      let branchId = this.user && this.user.branch_id;
      Request.GET_REQ(`/total-leads/${branchId}`)
        .then(res => {
          this.total_leads = res.data;
          this.isLoading = false;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });

      this.emitter.on("reload", () => {
        this.all_leads();
      });

      this.emitter.on("reloadLeadTb", () => {
        this.all_leads();
      });
    },
    myBranchLeads() {
      this.isAllLeads = false;
      this.isSearch = false;
      this.isMyBranch = true;
      let branchId = this.user && this.user.branch_id;
      Request.GET_REQ(`/my-branch-leads/${branchId}?page=${this.page}`)
        .then(res => {
          this.leads = res.data.data;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });

      Request.GET_REQ(`/my-branch-total-leads/${branchId}`)
        .then(res => {
          this.total_my_branch_search_leads = res.data.length;
          this.isLoading = false;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });

      this.emitter.on("reload", () => {
        this.all_leads();
      });

      this.emitter.on("reloadLeadTb", () => {
        this.all_leads();
      });
    },
    allBranchLeads() {
      this.isAllLeads = true;
      this.isSearch = false;
      this.isMyBranch = false;
      this.all_leads();
      this.totalLeads();
    },
    openDrawer(leadId) {
      this.leadId = leadId;
      this.drawerActive = true;
      this.drawerLoading = true;
    },
    closeDrawer() {
      this.drawerActive = false;
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.leads) {
          this.selected.push(this.leads[i].id);
        }
      }
      this.selectItems = this.selected.length;

      if (this.selected.length) {
        this.isAssign = true;
      } else {
        this.isAssign = false;
      }
    },
    selectSingle(leadId) {
      let arr = this.selected;
      if (arr.includes(leadId)) {
        arr.splice(arr.indexOf(leadId), 1);
        this.selectItems = this.selected.length;

        if (this.selected.length) {
          this.isAssign = true;
        } else {
          this.isAssign = false;
        }
        return;
      }
      arr.push(leadId);
      this.selectItems = this.selected.length;
      if (this.selected.length) {
        this.isAssign = true;
      } else {
        this.isAssign = false;
      }
    },
    getCounselor() {
      if (this.user && this.user.user_type === "superAdmin") {
        Request.GET_REQ(`/active-counselor`)
          .then(res => {
            this.counselors = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      }
      if (
        (this.user && this.user.user_type === "branchManager") ||
        (this.user && this.user.user_type === "counselor")
      ) {
        Request.GET_REQ(`/active-counselor/${this.user && this.user.branch_id}`)
          .then(res => {
            this.counselors = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    handleAssignSubmit() {
      this.lazyLoadAssign = true;
      let data = {
        leadIds: this.selected,
        counselorId: this.selected_counselor,
        branchId: this.selected_a_branch || this.user.branch_id,
        assignById: this.user && this.user.id
      };

      Request.POST_REQ(data, `/lead-assign-counselor`)
        .then(() => {
          $("#exampleModal").modal("hide");
          this.selected = [];
          this.selectAll = false;
          this.isAssign = false;
          this.emitter.emit("reloadLead");
          this.lazyLoadAssign = false;
          Notify.success("Successfully Assigned to Counselor");
          this.errors = {};
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        });
    },
    assigntoCounselor() {
      $("#exampleModal").modal("show");
    },
    getActiveBranch() {
      Request.GET_REQ(`/active-branches`)
        .then(res => {
          this.activeBranches = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },

    counselorId(event) {
      let counselorId = event.target.value;
      this.selected_counselor = counselorId;
    },
    activeBranchId(branchId) {
      this.selected_a_branch = branchId;

      Request.GET_REQ(`/active-counselor/${branchId}`)
        .then(res => {
          this.counselors = res.data;
          this.isSelectBranch = true;
        })
        .catch(err => {
          console.log(err);
        });
    },
    toggle(index) {
      if (index == this.isOpen) {
        this.isOpen = -1;
      } else {
        this.isOpen = index;
      }
    },
    searchit: _.debounce(function() {
      this.searchLoading = true;
      const query = this.search;
      if (query.length > 0) {
        let branchId = this.user && this.user.branch_id;
        if (this.user && this.user.user_type === "superAdmin") {
          Request.GET_REQ(`/lead-search?q=${query}&page=${this.page}`)
            .then(res => {
              this.leads = res.data.data;

              this.searchLoading = false;
              this.isSearch = true;
              this.isMyBranch = false;
              this.isAllLeads = false;
            })
            .catch(err => {
              this.errors = err.res.data.errors;
            });
          //total search result count
          Request.GET_REQ(`/total-search-leads?q=${query}`)
            .then(res => {
              this.total_search_leads = res.data;
            })
            .catch(err => {
              this.errors = err.res.data.errors;
            });
        }
        if (branchId) {
          Request.GET_REQ(
            `/lead-search/${branchId}?q=${query}&page=${this.page}`
          )
            .then(res => {
              this.leads = res.data.data;
              this.isSearch = true;
              this.isMyBranch = false;
              this.isAllLeads = false;
              this.searchLoading = false;
            })
            .catch(err => {
              this.errors = err.res.data.errors;
            });
          //total search result count
          Request.GET_REQ(`/total-search-leads/${branchId}?q=${query}`)
            .then(res => {
              this.total_search_leads = parseInt(res.data);
            })
            .catch(err => {
              this.errors = err.res.data.errors;
            });
        }
      } else {
        this.searchLoading = false;
        this.isSearch = false;
        this.isMyBranch = false;
        this.isAllLeads = true;
        this.all_leads();
        this.totalLeads();
      }
    }, 2000)
  }
};
</script>
<style scoped lang="scss">
.component-card_4 {
  width: auto;
  .user-info {
    width: 100%;
    padding: 5px 0 20px 0px;
  }
  .logo-title-wrap {
    img {
      border-radius: unset;
      height: 80px;
    }
  }
  .custom-btn-branch {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  .custom-list-group-branch .list-group-item {
    background: transparent;
    padding: 0;
    border: unset;
    position: relative;
    margin: 7px 0;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background: #e0e6ed;
      left: 0;
      bottom: -7px;
    }
    &::last-child::before {
      background: transparent !important;
      content: unset;
    }
    h6 {
      margin: 0;
      position: relative;
      padding-left: 20px;
      font-size: 14px;

      &::before {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        left: 0;
        border-radius: 3px;
      }
    }
    h6.count-primary {
      &::before {
        background: #1b55e2;
      }
    }
    h6.count-info {
      &::before {
        background: #2196f3;
      }
    }
    h6.count-success {
      &::before {
        background: #8dbf42;
      }
    }
    h6.count-warning {
      &::before {
        background: #e2a03f;
      }
    }
    h6.count-danger {
      &::before {
        background: #e7515a;
      }
    }
    h6.count-secondary {
      &::before {
        background: #5c1ac3;
      }
    }
  }
  .card-user_name {
    font-size: 24px;
  }
}

body.dark .component-card_4 {
  .custom-list-group-branch .list-group-item {
    &::before {
      background: #1b2e4b;
    }
  }
}

ul.list-group.custom-list-group-branch li:last-child:before {
  background: transparent !important;
}
.page-link {
  background-color: #0e1726 !important;
}
.lead-row {
  cursor: pointer;
}
body.dark tr.lead-row:hover {
  background-color: #060818;
}
tr.lead-row:hover {
  background-color: #fafafa;
}
.assign-text {
  text-decoration: underline;
  cursor: pointer;
}
.modal-footer {
  display: block !important;
  padding: 15px 25px 15px;
}
.preview-lead {
  display: none;
  position: absolute;
  right: 0;
  top: 30%;
}
.lead-row:hover .preview-lead {
  display: block;
}
.plus-off {
  display: none;
}
body.dark .sub-row-th th {
  color: #d3d3d3;
}
.sub-row-th th {
  text-transform: uppercase;
  color: #1b55e2;
  font-size: 12px;
}
.search-icon {
  position: absolute;
  right: 5%;
  top: 15%;
}
</style>
