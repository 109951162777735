<template>
  <div v-if="pendingStatus || isLoading">
    <Loader />
  </div>
  <div v-else class="layout-px-spacing">
    <form enctype="multipart/form-data" @submit.prevent="handleSubmit">
      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row mb-4">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="d-flex align-items-start justify-content-between">
                  <h4>Edit Country Information</h4>
                  <router-link :to="{ name: 'Countries' }">
                    <button class="btn btn-info mb-2 mr-4 btn-rounded">
                      View Countries
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-eye"
                      >
                        <path
                          d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                        ></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </button>
                  </router-link>
                </div>

                <br />
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Country</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :value="country_name"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1"
                    >Monthly Living Cost</label
                  >
                  <input
                    v-model="monthly_living_cost"
                    type="text"
                    class="form-control"
                  />
                  <small
                    v-if="errors.monthly_living_cost"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.monthly_living_cost[0] }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="row d-flex align-items-center">
                  <div class="col col-md-8">
                    <div class="form-group mb-4">
                      <label>Upload Country Flag</label>
                      <label class="custom-file-container__custom-file">
                        <input
                          type="file"
                          class="form-control-file"
                          accept="image/*"
                          @change="onFileChange"
                        />
                      </label>
                      <div class="custom-file-container__image-preview"></div>
                    </div>
                  </div>
                  <div class="col col-md-4">
                    <div v-if="flag" class="office-logo">
                      <img alt="Country Flag" :src="baseURL + flag" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1"
                    >Visa Requirements</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="visa_requirements"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                  ></textarea>
                  <small
                    v-if="errors.visa_requirements"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.visa_requirements[0] }}</small
                  >
                </div>
              </div>

              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1"
                    >Part Time Work Details</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="part_time_work"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                  ></textarea>
                  <small
                    v-if="errors.part_time_work"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.part_time_work[0] }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1"
                    >Accommodation Details</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="accommodation_details"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                  ></textarea>
                  <small
                    v-if="errors.accommodation_details"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.accommodation_details[0] }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1"
                    >Country Benifits</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="country_benifits"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                  ></textarea>
                  <small
                    v-if="errors.country_benifits"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.country_benifits[0] }}</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row">
              <div class="col text-right">
                <div class="row">
                  <div class="col">
                    <router-link :to="{ name: 'Countries' }">
                      <button type="button" class="btn btn-warning mr-2 btn-lg">
                        Cancel
                      </button>
                    </router-link>
                    <button type="submit" class="btn btn-primary mr-2 btn-lg">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
      <div id="add-new-status-sec" class="statbox widget box box-shadow">
        <div class="widget-content widget-content-area">
          <div class="row mb-4">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12">
              <h4>Add Application Process</h4>
              <br />
            </div>
            <div class="col col-md-12">
              <div
                v-for="(input, index) in newStatuses"
                :key="index"
                class="form-group mb-4 d-flex align-items-end"
              >
                <div class="col col-md-8 pl-0">
                  <label for="exampleFormControlTextarea1">Status</label>
                  <input
                    v-model="input.status_name_new"
                    type="text"
                    class="form-control"
                  />
                  <small
                    v-if="statusErrors.has(`statuses.${index}.status_name_new`)"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{
                      statusErrors.get(`statuses.${index}.status_name_new`)
                    }}</small
                  >

                  <div class="d-flex align-items-center">
                    <label class="mt-2 mr-2"
                      >{{ input.status_name_new }} Status Color</label
                    >
                    <input
                      v-model="input.status_color_new"
                      style="cursor: pointer;"
                      type="color"
                    />
                  </div>
                </div>
                <div class="row ml-4 mt-4">
                  <div v-show="index == newStatuses.length - 1">
                    <button
                      class="btn btn-warning mb-2 mr-2 btn-rounded"
                      @click.prevent="add"
                    >
                      Add More
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-plus-circle"
                      >
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="8" x2="12" y2="16" />
                        <line x1="8" y1="12" x2="16" y2="12" />
                      </svg>
                    </button>
                  </div>
                  <div v-show="index || (!index && newStatuses.length > 1)">
                    <button
                      class="btn btn-danger mb-2 mr-2 btn-rounded"
                      @click.prevent="remove"
                    >
                      Remove
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-x-circle"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="15" y1="9" x2="9" y2="15"></line>
                        <line x1="9" y1="9" x2="15" y2="15"></line>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <button class="btn btn-primary" @click="addNewStatus">
                Save New Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ManageStatus
      :statuses="statuses"
      :country-id="country_id"
      @realodList="getData"
      @realodStatus="realodStatus"
    />
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import ManageStatus from "@/components/ManageStatus";
import { mapGetters, mapState } from "vuex";
import Notify from "../../helpers/Notify";
import Request from "../../apis/Request";
import baseURL from "../../apis/baseURL";

class Errors {
  constructor() {
    this.errors = {};
  }

  has(field) {
    return Object.prototype.hasOwnProperty.call(this.errors, field);
  }

  any() {
    return Object.keys(this.errors).length > 0;
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  record(errors) {
    this.errors = errors.errors;
  }

  clear(field) {
    delete this.errors[field];
  }
}

export default {
  name: "EditCountry",
  components: { Loader, ManageStatus },
  data() {
    return {
      country_id: "",
      country_name: "",
      monthly_living_cost: "",
      visa_requirements: "",
      part_time_work: "",
      accommodation_details: "",
      country_benifits: "",
      countries: [],
      flag: "",
      statuses: [
        {
          status_name: "",
          status_color: "#e2a03f"
        }
      ],
      newStatuses: [
        {
          status_name_new: "",
          status_color_new: "#e2a03f"
        }
      ],

      errors: {},
      isLoading: false,
      statusErrors: new Errors()
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"]),
    ...mapState(["baseURL"])
  },

  mounted() {
    this.isLoading = true;
    this.getData();
    this.emitter.on("reloadNewStatus", () => {
      this.realodStatus();
    });
  },
  methods: {
    getData() {
      let slug = this.$route.params.slug;
      Request.GET_REQ(`/edit-country/${slug}`).then(res => {
        this.country_id = res.data.id;
        this.country_name = res.data.country.name;
        this.flag = res.data.flag;
        this.monthly_living_cost = res.data.monthly_living_cost;
        this.visa_requirements = res.data.visa_requirements;
        this.part_time_work = res.data.part_time_work;
        this.country_benifits = res.data.country_benifits;
        this.accommodation_details = res.data.accommodation_details;
        this.statuses = res.data.statuses;
        this.isLoading = false;
        console.log("Country ID", res.data.id);
      });
    },
    handleSubmit() {
      this.isLoading = true;
      let slug = this.$route.params.slug;
      let data = {
        flag: this.flag,
        monthly_living_cost: this.monthly_living_cost,
        visa_requirements: this.visa_requirements,
        part_time_work: this.part_time_work,
        accommodation_details: this.accommodation_details,
        country_benifits: this.country_benifits,
        statuses: this.statuses
      };

      Request.POST_REQ(data, `/update-country/${slug}`)
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch("baseurl", baseURL.baseURL);
          this.$router.push({ name: "Countries" });
          Notify.success("The Country Successfully Updated");
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.isLoading = false;

          Notify.error(this.errors.country_id && this.errors.country_id[0]);
          Notify.error(
            this.errors.monthly_living_cost &&
              this.errors.monthly_living_cost[0]
          );
          Notify.error(
            this.errors.visa_requirements && this.errors.visa_requirements[0]
          );
          Notify.error(
            this.errors.part_time_work && this.errors.part_time_work[0]
          );
          Notify.error(
            this.errors.accommodation_details &&
              this.errors.accommodation_details[0]
          );
          Notify.error(
            this.errors.country_benifits && this.errors.country_benifits[0]
          );
        });
      // console.log(this.form);
    },
    onFileChange(event) {
      this.$store.dispatch("baseurl", "");
      this.logo = null;
      let file = event.target.files[0];
      if (file.size > 1048770) {
        console.log("file is bigger than 2mb");
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.flag = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    realodStatus(id) {
      Request.GET_REQ(
        `/check-status-is-publish/${id && id ? id : this.country_id}`
      )
        .then(res => {
          this.statuses = res.data;
          console.log(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    add() {
      this.newStatuses.push({
        status_name_new: "",
        status_color_new: "#e2a03f"
      });
    },
    remove(index) {
      this.newStatuses.splice(index, 1);
    },
    addNewStatus() {
      let data = {
        country_id: this.country_id,
        statuses: this.newStatuses
      };
      Request.POST_REQ(data, "/add-new-status")
        .then(() => {
          this.isLoading = false;
          // this.$router.push({ name: "Countries" });
          this.emitter.emit("reloadNewStatus");
          Notify.success("Status Created Successfully");
          this.newStatuses = [(this.status_name_new = "")];
        })
        .catch(error => {
          if (this.statusErrors) {
            this.statusErrors.record(error.response.data);
          }
          console.log(this.statusErrors);
          this.isLoading = false;
          this.statusErrors.has("status_name_new") &&
            Notify.error(this.statusErrors.get("status_name_new"));
          this.statusErrors.has("status_color_new") &&
            Notify.error(this.statusErrors.get("status_color_new"));
        });
    }
  }
};
</script>
<style scoped lang="scss">
.component-card_1 {
  width: auto;
}
body.dark .office-logo img {
  border: 1px solid #1b2e4b;
}
.office-logo img {
  width: 100px;
  border: 1px solid #bfc9d4;
  border-radius: 5px;
  padding: 5px;
}
.office-logo {
  width: 100%;
  text-align: right;
}
.switch.s-icons {
  width: auto;
  height: 10px;
}
</style>
