<template>
  <div id="lead-drwer-wrap" :class="drawerActive ? 'show' : ''">
    <loader v-if="loading" />
    <div class="d-flex justify-content-between align-items-center py-2 px-2">
      <div>
        <h5>
          {{
            !lead.first_name
              ? "Loading...."
              : lead.first_name + " " + lead.last_name
          }}
        </h5>
      </div>
      <div class="drawer-close badge badge-pill" @click="$emit('close-drawer')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-x"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
    </div>
    <div class="card component-card_4">
      <div class="card-body">
        <div class="user-profile">
          <img
            v-if="lead.photo"
            :src="baseURL + lead.photo"
            class=""
            alt="..."
            width="90"
          />
          <img v-else src="@/assets/img/90x90.jpg" class="" alt="..." />
        </div>
        <div class="user-info">
          <h5 class="card-user_name">
            {{
              !lead.first_name
                ? "Loading...."
                : lead.first_name + " " + lead.last_name
            }}
          </h5>

          <div
            class="card-icon d-flex justify-content-between align-item-center text-center"
          >
            <span style="cursor: pointer;" @click="clickNotes">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-edit-3 icon custom-edit-icon"
              >
                <path d="M12 20h9"></path>
                <path
                  d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"
                ></path>
              </svg>
              <br />
              Notes
            </span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-mail"
              >
                <path
                  d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                ></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>
              <br />
              Email
            </span>
            <span>
              <a href="tel:{{lead.phone}}" class="lead-phone">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-phone-call"
                >
                  <path
                    d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                  ></path>
                </svg>
                <br />
                Call
              </a>
            </span>

            <span style="cursor: pointer;" @click="clickMeeting">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-calendar"
              >
                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="16" y1="2" x2="16" y2="6"></line>
                <line x1="8" y1="2" x2="8" y2="6"></line>
                <line x1="3" y1="10" x2="21" y2="10"></line>
              </svg>
              <br />
              Meeting
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="col-lg-12 p-0">
      <div class="statbox box box-shadow">
        <div class="icon-accordion-content">
          <div id="withoutSpacing">
            <div class="card no-outer-spacing">
              <div id="headingOne2" class="card-header">
                <section class="mb-0 mt-0">
                  <div
                    role="menu"
                    class=""
                    data-toggle="collapse"
                    data-target="#withoutSpacingAccordionOne"
                    aria-expanded="true"
                    aria-controls="withoutSpacingAccordionOne"
                  >
                    About This Contact
                    <div class="icons">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevron-down"
                      >
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>
                    </div>
                  </div>
                </section>
              </div>

              <div
                id="withoutSpacingAccordionOne"
                class="collapse show"
                aria-labelledby="headingOne2"
                data-parent="#withoutSpacing"
              >
                <div class="card-body custom-card-body">
                  <form @submit.prevent="handleSubmit">
                    <div class="row">
                      <div class="col col-md-12">
                        <div class="form-group lead-drawer-form">
                          <label for="exampleFormControlInput1"
                            >First Name</label
                          >
                          <input
                            id="exampleFormControlInput1"
                            v-model="first_name"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>

                      <div class="col col-md-12">
                        <div class="form-group lead-drawer-form">
                          <label for="exampleFormControlInput1"
                            >Last Name</label
                          >
                          <input
                            id="exampleFormControlInput1"
                            v-model="last_name"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>

                      <div class="col col-md-12">
                        <div class="form-group lead-drawer-form">
                          <label for="exampleFormControlInput1">Email</label>
                          <input
                            id="exampleFormControlInput1"
                            v-model="email"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col col-md-12">
                        <div class="form-group lead-drawer-form">
                          <label for="exampleFormControlInput1">Phone</label>
                          <input
                            id="exampleFormControlInput1"
                            v-model="phone"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>

                      <div class="col col-md-12">
                        <div class="form-group lead-drawer-form">
                          <label>Counselor</label>
                          <select v-model="counselor_id" class="form-control">
                            <option value="" disabled>Select Counselor</option>
                            <option
                              v-for="counselor in counselors"
                              :key="counselor.id"
                              :value="counselor.id"
                              >{{ counselor.counselor_name }}</option
                            >
                          </select>
                          <small
                            v-if="errors.counselor_id"
                            id="sh-text1"
                            class="form-text text-danger"
                            >{{ errors.counselor_id[0] }}</small
                          >
                        </div>
                      </div>

                      <div class="col col-md-12">
                        <button class="btn badge badge-info btn-sm">
                          Update
                          <span v-if="lazyLoadContact">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-loader spin mr-2"
                            >
                              <line x1="12" y1="2" x2="12" y2="6"></line>
                              <line x1="12" y1="18" x2="12" y2="22"></line>
                              <line
                                x1="4.93"
                                y1="4.93"
                                x2="7.76"
                                y2="7.76"
                              ></line>
                              <line
                                x1="16.24"
                                y1="16.24"
                                x2="19.07"
                                y2="19.07"
                              ></line>
                              <line x1="2" y1="12" x2="6" y2="12"></line>
                              <line x1="18" y1="12" x2="22" y2="12"></line>
                              <line
                                x1="4.93"
                                y1="19.07"
                                x2="7.76"
                                y2="16.24"
                              ></line>
                              <line
                                x1="16.24"
                                y1="7.76"
                                x2="19.07"
                                y2="4.93"
                              ></line>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="text-right pr-3 pb-2">
                  <router-link
                    :to="{
                      name: 'Edit_Lead',
                      params: { id: leadId }
                    }"
                  >
                    <button
                      class="btn btn-sm badge badge-pill bg-secondary"
                      style="color: #fff"
                    >
                      More Edit
                    </button>
                  </router-link>
                </div>
              </div>
            </div>

            <lead-status-component
              :lead-id="leadId"
              :lead-status-id="leadStatusId"
            />
            <follow-up-component :lead-id="leadId" />
            <meeting-component :lead-id="leadId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Request from "@/apis/Request.js";
import Loader from "../../components/Loader.vue";
import FollowUpComponent from "../../components/leads/FollowUpComponent.vue";
import MeetingComponent from "../../components/leads/MeetingComponent.vue";
import LeadStatusComponent from "../../components/leads/LeadStatusComponent.vue";
import { mapGetters, mapState } from "vuex";
import Notify from "../../helpers/Notify";
export default {
  components: {
    Loader,
    FollowUpComponent,
    MeetingComponent,
    LeadStatusComponent
  },
  props: {
    leadId: {
      type: Number,
      required: true
    },
    drawerActive: {
      type: Boolean,
      required: true
    },
    drawerLoading: {
      type: Boolean,
      required: true
    }
  },
  emits: ["close-drawer", "all_leads"],
  data() {
    return {
      lead: "",
      follow_up: "",
      counselor_id: "",
      loading: false,
      lazyLoadContact: false,
      errors: {},
      counselors: [],
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      leadStatusId: ""
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"]),
    ...mapState(["baseURL"])
  },
  watch: {
    leadId: {
      handler() {
        this.getLead();
        this.getCounselor();
      }
    }
  },
  mounted() {
    this.emitter.on("leadUpdate", () => {
      this.getLead();
      this.getCounselor();
    });
  },
  methods: {
    getLead() {
      this.loading = true;
      Request.GET_REQ(`/get-lead/${this.leadId}`)
        .then(res => {
          this.lead = res.data;
          (this.first_name = res.data.first_name),
            (this.last_name = res.data.last_name),
            (this.email = res.data.email),
            (this.phone = res.data.phone),
            (this.counselor_id = res.data.counselor_id);
          this.leadStatusId = res.data.lead_status_id;
          this.loading = false;

          // this.drawerLoading = false;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
          console.log(this.errors);
        });
    },
    getCounselor() {
      Request.GET_REQ(`/active-counselor`)
        .then(res => {
          this.counselors = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleSubmit() {
      this.lazyLoadContact = true;
      let data = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone: this.phone,
        counselor_id: this.counselor_id
      };
      Request.POST_REQ(data, `/update-contact/${this.leadId}`)
        .then(() => {
          this.lazyLoadContact = false;
          this.$emit("all_leads");
          this.emitter.emit("leadUpdate");
          Notify.success("The Lead Contact Successfully Updated");
          this.errors = {};
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.lazyLoadContact = false;
          this.errors.first_name && Notify.error(this.errors.first_name[0]);
          this.errors.last_name && Notify.error(this.errors.last_name[0]);
          this.errors.email && Notify.error(this.errors.email[0]);
          this.errors.phone && Notify.error(this.errors.phone[0]);
          this.errors.counselor_id && Notify.error(this.errors.counselor_id[0]);
        });
    },
    clickNotes() {
      const aboutElm = document.getElementById("withoutSpacingAccordionOne");
      const noteElm = document.getElementById("withoutSpacingAccordionTwo");
      const meetingElm = document.getElementById(
        "withoutSpacingAccordionThree"
      );
      if (
        aboutElm.classList.contains("show") ||
        meetingElm.classList.contains("show")
      ) {
        aboutElm.classList.remove("show");
        meetingElm.classList.remove("show");
      }
      noteElm.classList.add("show");
      noteElm.scrollIntoView({ behavior: "smooth", block: "end" });
    },
    clickMeeting() {
      const aboutElm = document.getElementById("withoutSpacingAccordionOne");
      const noteElm = document.getElementById("withoutSpacingAccordionTwo");
      const meetingElm = document.getElementById(
        "withoutSpacingAccordionThree"
      );
      if (
        aboutElm.classList.contains("show") ||
        noteElm.classList.contains("show")
      ) {
        aboutElm.classList.remove("show");
        noteElm.classList.remove("show");
      }
      meetingElm.classList.add("show");
      noteElm.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }
};
</script>

<style lang="scss">
div#lead-drwer-wrap.show {
  visibility: visible;
  transform: translateX(0);
  right: 0;
  padding: 5px;
}
body.dark div#lead-drwer-wrap {
  background: #060818;
  border: 1px solid #191e3a;
}
div#lead-drwer-wrap {
  background: #fff;
  width: 350px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -105%;
  border-radius: 5px;
  border: 1px solid #ddd;
  visibility: hidden;
  transition: visibility 0.5s;
  transition: right 0.5s;
  transform: translateX(-30%);
  z-index: 999;
  overflow-y: scroll;
  overflow-x: hidden;
  .card.component-card_4 {
    width: 100%;
  }
  .component-card_4 {
    border: none !important;
    border-radius: unset !important;
    widows: 100% !important;
    .user-profile {
      padding: 10px;
    }
  }
}
.drawer-close {
  width: 30px;
  height: 30px;
  background: #e7515a;
  line-height: 1.3;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem !important;
  cursor: pointer;
  padding: 0 !important;
}
.card-icon {
  span {
    margin: 0 3px;
    position: relative;
  }
}
.form-group.lead-drawer-form {
  margin-bottom: 5px;
  label {
    font-size: 11px;
    padding: 0 !important;
    margin: 0 !important;
  }
  input {
    padding: 5px;
    border-radius: 0;
    font-size: 12px;
  }
  input.form-control {
    height: 28px;
  }
  select.form-control.form-control-sm {
    height: 30px;
    padding: 0;
    font-size: 12px;
    border-radius: 0;
  }
  .custom-lead-drawer-wrap {
    .card-body {
      padding: 0px 18px;
    }
  }
}

.card-body.custom-card-body {
  padding: 0 18px 18px;
}
.form-group.lead-drawer-form select {
  height: 28px;
  padding: 0;
  font-size: 11px;
}
body.dark a.lead-phone {
  color: #888ea8;
}
a.lead-phone {
  color: #888ea8;
}
</style>
