<template>
  <div v-if="pendingStatus || isLoading">
    <Loader />
  </div>
  <div v-else class="layout-px-spacing">
    <form enctype="multipart/form-data" @submit.prevent="handleSubmit">
      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row mb-4">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="d-flex align-items-start justify-content-between">
                  <h4>Edit Counselor Information</h4>
                  <router-link :to="{ name: 'Counselors' }">
                    <button class="btn btn-info mb-2 mr-4 btn-rounded">
                      View Counselors
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-eye"
                      >
                        <path
                          d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                        ></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </button>
                  </router-link>
                </div>

                <br />
              </div>
              <div v-if="user.user_type == 'superAdmin'" class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1"
                    >Couselor for Branch</label
                  >
                  <select v-model="branch_id" class="form-control">
                    <option disabled value="">Select a Branch</option>
                    <option
                      v-for="branch in branches"
                      :key="branch.id"
                      :value="branch.id"
                      >{{ branch.branch_name }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Counselor Name</label>
                  <input
                    v-model="counselor_name"
                    type="text"
                    class="form-control"
                  />
                  <small
                    v-if="errors.counselor_name"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.counselor_name[0] }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Counselor Phone</label>
                  <input
                    v-model="counselor_phone"
                    type="text"
                    class="form-control"
                  />
                  <small
                    v-if="errors.counselor_phone"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.counselor_phone[0] }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Counselor Email</label>
                  <input
                    v-model="counselor_email"
                    type="email"
                    class="form-control"
                  />
                  <small
                    v-if="errors.counselor_email"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.counselor_email[0] }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1"
                    >Alternative Person Contact</label
                  >
                  <input
                    v-model="alternative_contact"
                    type="text"
                    class="form-control"
                  />
                  <small
                    v-if="errors.alternative_contact"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.alternative_contact[0] }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1"
                    >National ID or Passport</label
                  >
                  <input
                    v-model="nid_or_passport"
                    type="text"
                    class="form-control"
                  />
                  <small
                    v-if="errors.nid_or_passport"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.nid_or_passport[0] }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Nationality</label>
                  <input
                    v-model="nationality"
                    type="text"
                    class="form-control"
                  />
                  <small
                    v-if="errors.nationality"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.nationality[0] }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Country</label>
                  <select v-model="country" class="form-control">
                    <option disabled value="">Select Country</option>
                    <option
                      v-for="countri in countries"
                      :key="countri.id"
                      :value="countri.id"
                      >{{ countri.name }}</option
                    >
                  </select>
                  <small
                    v-if="errors.country"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.country[0] }}</small
                  >
                </div>
              </div>

              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">City</label>
                  <input v-model="city" type="text" class="form-control" />
                  <small
                    v-if="errors.city"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.city[0] }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">State</label>
                  <input v-model="state" type="text" class="form-control" />
                  <small
                    v-if="errors.state"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.state[0] }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col col-md-8">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1"
                    >Address in Details</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="address"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                  ></textarea>
                  <small
                    v-if="errors.address"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.address[0] }}</small
                  >
                </div>
              </div>
              <div class="col col-md-4">
                <div class="row d-flex align-items-center">
                  <div class="col col-md-8">
                    <div class="form-group mb-4">
                      <label>Upload Counselor Photo</label>
                      <label class="custom-file-container__custom-file">
                        <input
                          type="file"
                          class="form-control-file"
                          accept="image/*"
                          @change="onFileChange"
                        />
                      </label>
                      <div class="custom-file-container__image-preview"></div>
                    </div>
                  </div>
                  <div class="col col-md-4">
                    <div v-if="photo" class="office-photo">
                      <img alt="Counselor photo" :src="baseURL + photo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row">
              <div class="col text-right">
                <div class="row">
                  <div class="col">
                    <router-link :to="{ name: 'Counselors' }"></router-link>
                    <button type="button" class="btn btn-warning mr-2 btn-lg">
                      Cancel
                    </button>

                    <button type="submit" class="btn btn-primary mr-2 btn-lg">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import { mapGetters, mapState } from "vuex";
import Notify from "../../helpers/Notify";
import Request from "../../apis/Request";
import baseURL from "../../apis/baseURL";
export default {
  name: "EditCounselor",
  components: { Loader },
  data() {
    return {
      branch_id: "",
      counselor_name: "",
      counselor_phone: "",
      counselor_email: "",
      country: "",
      city: "",
      state: "",
      address: "",
      alternative_contact: "",
      nid_or_passport: "",
      nationality: "",
      photo: "",
      errors: {},
      isLoading: false,
      countries: [],
      branches: [],
      check_counselor_id: ""
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"]),
    ...mapState(["baseURL"])
  },

  async mounted() {
    this.isLoading = true;
    let slug = this.$route.params.slug;
    await Request.GET_REQ(`/edit-counselor/${slug}`)
      .then(res => {
        this.check_counselor_id = res.data.id;
      })
      .catch(err => {
        console.log(err);
      });

    if (
      this.user.user_type === "superAdmin" ||
      this.user.user_type === "branchManager" ||
      this.user.counselor_id == this.check_counselor_id
    ) {
      Request.GET_REQ("/branches")
        .then(res => {
          this.branches = res.data;
          console.log(res.data);
        })
        .catch(err => {
          this.errors = err.res.data.errors;
          console.log(this.errors);
        });

      Request.GET_REQ(`/edit-counselor/${slug}`)
        .then(res => {
          this.branch_id = res.data.branch_id;
          this.address = res.data.address_details;
          this.alternative_contact = res.data.alternative_person_contact;
          this.city = res.data.city;
          this.counselor_email = res.data.counselor_email;
          this.counselor_name = res.data.counselor_name;
          this.counselor_phone = res.data.counselor_phone;
          this.country = res.data.country;
          this.nationality = res.data.nationality;
          this.nid_or_passport = res.data.nid_or_passport;
          this.photo = res.data.photo;
          this.state = res.data.state;
          this.isLoading = false;
        })
        .catch(err => {
          this.errors = err.res.data.errors;
        });

      Request.GET_REQ("/countries").then(res => {
        this.countries = res.data;
      });
    } else {
      this.$router.push({ name: "Counselors" });
    }
  },

  methods: {
    handleSubmit() {
      let user = this.$store.state.user;
      let slug = this.$route.params.slug;
      this.isLoading = true;
      let data = {
        branch_id: this.branch_id || user.branch_id,
        counselor_name: this.counselor_name,
        counselor_phone: this.counselor_phone,
        counselor_email: this.counselor_email,
        country: this.country,
        city: this.city,
        state: this.state,
        address: this.address,
        alternative_contact: this.alternative_contact,
        nid_or_passport: this.nid_or_passport,
        nationality: this.nationality,
        photo: this.photo
      };
      Request.POST_REQ(data, `/update-counselor/${slug}`)
        .then(() => {
          this.isLoading = false;
          // this.resetForm();
          this.$store.dispatch("baseurl", baseURL.baseURL);
          this.$router.push({ name: "Counselors" });
          Notify.success("The Counselor Successfully Updated");
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.isLoading = false;

          if (this.errors) {
            Notify.error(
              this.errors.counselor_email && this.errors.counselor_email[0]
            );
            Notify.error(
              this.errors.counselor_name && this.errors.counselor_name[0]
            );
            Notify.error(
              this.errors.counselor_phone && this.errors.counselor_phone[0]
            );
            Notify.error(this.errors.city && this.errors.city[0]);
            Notify.error(this.errors.country && this.errors.country[0]);
            Notify.error(this.errors.address && this.errors.address[0]);
            Notify.error(this.errors.state && this.errors.state[0]);
            Notify.error(this.errors.nationality && this.errors.nationality[0]);
            Notify.error(
              this.errors.nid_or_passport && this.errors.nid_or_passport[0]
            );
            Notify.error(this.errors.photo && this.errors.photo[0]);
            Notify.error(
              this.errors.alternative_contact &&
                this.errors.alternative_contact[0]
            );
            Notify.error(this.errors.email && this.errors.email[0]);
            Notify.error(this.errors.name && this.errors.name[0]);
            Notify.error(this.errors.password && this.errors.password[0]);
          }
        });
    },
    onFileChange(event) {
      this.$store.dispatch("baseurl", "");
      this.logo = null;
      let file = event.target.files[0];
      if (file.size > 1048770) {
        console.log("file is bigger than 2mb");
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.photo = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.component-card_1 {
  width: auto;
}
body.dark .office-photo img {
  border: 1px solid #1b2e4b;
}
.office-photo img {
  width: 100px;
  border: 1px solid #bfc9d4;
  border-radius: 5px;
  padding: 5px;
}
.office-photo {
  width: 100%;
  text-align: right;
}
</style>
