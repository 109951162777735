<template>
  <div v-if="pendingStatus || isLoading">
    <Loader />
  </div>
  <div class="layout-px-spacing layout-top-spacing">
    <div class="text-right">
      <button class="btn btn-info mb-2 mr-4 btn-rounded">
        View Send History
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-eye"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
          <circle cx="12" cy="12" r="3"></circle>
        </svg>
      </button>
    </div>
    <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
      <div class="statbox widget box box-shadow">
        <div class="widget-content widget-content-area">
          <div class="row">
            <div class="col-md-12">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col col-md-6">
                    <label for="to">Send To:</label>
                    <input
                      v-model="send_to"
                      type="email"
                      class="form-control"
                      placeholder="Email Address"
                    />
                    <small
                      v-if="errors.send_to"
                      id="sh-text1"
                      type="text"
                      class="form-text text-danger"
                      >{{ errors.send_to[0] }}</small
                    >
                  </div>
                  <div class="col col-md-6">
                    <label for="to">Subject</label>
                    <input
                      v-model="subject"
                      class="form-control"
                      placeholder="Subject"
                    />
                    <small
                      v-if="errors.subject"
                      id="sh-text1"
                      class="form-text text-danger"
                      >{{ errors.subject[0] }}</small
                    >
                  </div>
                  <div class="col col-md-12 mt-2">
                    <label for="exampleFormControlTextarea1">Body</label>
                    <textarea
                      id="exampleFormControlTextarea1"
                      v-model="mail_body"
                      class="form-control"
                      rows="5"
                      spellcheck="false"
                    ></textarea>
                    <small
                      v-if="errors.mail_body"
                      id="sh-text1"
                      class="form-text text-danger"
                      >{{ errors.mail_body[0] }}</small
                    >
                  </div>
                  <div class="col col-md-12 mt-2">
                    <button type="submit" class="btn btn-primary">
                      Send Email
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <application-lead-info :lead-id="leadId" />
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Request from "../../apis/Request";
import Notify from "../../helpers/Notify";
import ApplicationLeadInfo from "./ApplicationLeadInfo.vue";
import Loader from "@/components/Loader.vue";
export default {
  components: { ApplicationLeadInfo, Loader },
  data() {
    return {
      send_to: "",
      subject: "",
      mail_body: "",
      errors: {},
      application: "",
      leadId: "",
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"]),
    ...mapState(["baseURL"])
  },
  mounted() {
    this.leadId = this.$route.params.id;
  },
  methods: {
    handleSubmit() {
      this.isLoading = true;
      let leadId = this.$route.params.id;
      let data = {
        send_to: this.send_to,
        subject: this.subject,
        mail_body: this.mail_body
      };

      Request.POST_REQ(data, `/application-email-sender/${leadId}`)
        .then(res => {
          if (res.status === 200) {
            this.isLoading = false;
            Notify.success("Email Has sent Successfully");
            this.$router.push({ name: "Application_Leads" });
          }
          console.log("success");
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          console.log(this.errors);
        });
    }
  }
};
</script>
