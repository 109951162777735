<template>
  <div v-if="pendingStatus || isLoading">
    <Loader />
  </div>
  <div v-else class="layout-px-spacing">
    <form
      enctype="multipart/form-data"
      @submit.prevent="handleSubmit"
      @change="errors.clear($event.target.name)"
    >
      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row mb-4">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="d-flex align-items-start justify-content-between">
                  <h4>Edit Institute Information</h4>
                  <router-link :to="{ name: 'Institutes' }">
                    <button class="btn btn-info mb-2 mr-4 btn-rounded">
                      View Institutes
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-eye"
                      >
                        <path
                          d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                        ></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </button>
                  </router-link>
                </div>

                <br />
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1"
                    >Represent Country</label
                  >
                  <select
                    v-model="represent_country_id"
                    class="form-control"
                    @change="errors.clear('represent_country_id')"
                  >
                    <option disabled value="">Select Represent Country</option>
                    <option
                      v-for="countri in countries"
                      :key="countri.id"
                      :value="countri.id"
                      >{{ countri.country.name }}</option
                    >
                  </select>
                  <small
                    v-if="errors.has('represent_country_id')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("represent_country_id") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Institute Name</label>
                  <input
                    v-model="institute_name"
                    type="text"
                    class="form-control"
                    name="institute_name"
                  />
                  <small
                    v-if="errors.has('institute_name')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("institute_name") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Campus Name</label>
                  <input
                    v-model="campus_name"
                    type="text"
                    class="form-control"
                    name="campus_name"
                  />
                  <small
                    v-if="errors.has('campus_name')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("campus_name") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Website</label>
                  <input
                    v-model="website"
                    type="text"
                    class="form-control"
                    name="website"
                  />
                  <small
                    v-if="errors.has('website')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("website") }}</small
                  >
                </div>
              </div>

              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1"
                    >Monthly Living Cost</label
                  >
                  <input
                    v-model="monthly_living_cost"
                    type="text"
                    class="form-control"
                    name="monthly_living_cost"
                  />
                  <small
                    v-if="errors.has('monthly_living_cost')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("monthly_living_cost") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1"
                    >Funds Requirements for Visa</label
                  >
                  <input
                    v-model="funds_requirements_for_visa"
                    type="text"
                    class="form-control"
                    name="funds_requirements_for_visa"
                  />
                  <small
                    v-if="errors.has('funds_requirements_for_visa')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("funds_requirements_for_visa") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Application Fees</label>
                  <input
                    v-model="application_fee"
                    type="text"
                    class="form-control"
                    name="application_fee"
                  />
                  <small
                    v-if="errors.has('application_fee')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("application_fee") }}</small
                  >
                </div>
              </div>

              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1">Currency</label>
                  <input
                    v-model="currency"
                    type="text"
                    class="form-control"
                    name="currency"
                  />
                  <small
                    v-if="errors.has('currency')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("currency") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlInput1"
                    >Is Language Mendatory</label
                  >
                  <input
                    v-model="is_lang_mendatory"
                    type="text"
                    class="form-control"
                    placeholder="Yes or No"
                    name="is_lang_mendatory"
                  />
                  <small
                    v-if="errors.has('is_lang_mendatory')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("is_lang_mendatory") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1"
                    >Language Requirements</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="lang_requirements"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                    name="lang_requirements"
                  ></textarea>
                  <small
                    v-if="errors.has('lang_requirements')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("lang_requirements") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1"
                    >Institute Benifits</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="institute_benifits"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                    name="institute_benifits"
                  ></textarea>
                  <small
                    v-if="errors.has('institute_benifits')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("institute_benifits") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1">
                    Part Time Work Details</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="per_time_work_details"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                    name="per_time_work_details"
                  ></textarea>
                  <small
                    v-if="errors.has('per_time_work_details')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("per_time_work_details") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1"
                    >Scholarship Policy</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="scholarship_policy"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                    name="scholarship_policy"
                  ></textarea>
                  <small
                    v-if="errors.has('scholarship_policy')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("scholarship_policy") }}</small
                  >
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1">
                    Institute Important Notes</label
                  >
                  <textarea
                    id="exampleFormControlTextarea1"
                    v-model="institute_important_notes"
                    class="form-control"
                    rows="3"
                    spellcheck="false"
                    name="institute_important_notes"
                  ></textarea>
                  <small
                    v-if="errors.has('institute_important_notes')"
                    id="sh-text1"
                    class="form-text text-danger"
                    >{{ errors.get("institute_important_notes") }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="row d-flex align-items-center">
                  <div class="col col-md-8">
                    <div class="form-group mb-4">
                      <label>Upload Institute Logo</label>
                      <label class="custom-file-container__custom-file">
                        <input
                          type="file"
                          class="form-control-file"
                          accept="image/*"
                          name="institute_logo"
                          @change="onFileChange"
                        />
                      </label>
                      <small
                        v-if="errors.has('institute_logo')"
                        id="sh-text1"
                        class="form-text text-danger"
                        >{{ errors.get("institute_logo") }}</small
                      >
                      <div class="custom-file-container__image-preview"></div>
                    </div>
                  </div>
                  <div class="col col-md-4">
                    <div v-if="institute_logo" class="office-logo">
                      <img
                        alt="Institute Logo"
                        :src="baseURL + institute_logo"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="row d-flex align-items-center">
                  <div class="col col-md-8">
                    <div class="form-group mb-4">
                      <label>Institute Prospectus</label>
                      <label class="custom-file-container__custom-file">
                        <input
                          type="file"
                          class="form-control-file"
                          @change="onChangeProspectus"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="row d-flex align-items-center">
                  <div class="col col-md-8">
                    <div class="form-group mb-4">
                      <label>Institute Course Module</label>
                      <label class="custom-file-container__custom-file">
                        <input
                          type="file"
                          class="form-control-file"
                          @change="onChangeCourseModule"
                        />
                      </label>
                      <div class="custom-file-container__image-preview"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row mb-4">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <h4>University Contact Person</h4>
                <br />
              </div>
              <div class="col col-md-12">
                <div
                  v-for="(contact, index) in contacts"
                  :key="index"
                  class="row"
                >
                  <div class="col col-md-3">
                    <div class="form-group mb-4">
                      <label for="personName">Contact Person Name</label>
                      <input
                        id="personName"
                        v-model="contact.name"
                        type="text"
                        class="form-control"
                        :name="`contacts.${index}.name`"
                      />
                      <small
                        v-if="errors.has(`contacts.${index}.name`)"
                        id="sh-text1"
                        class="form-text text-danger"
                        >{{ errors.get(`contacts.${index}.name`) }}</small
                      >
                    </div>
                  </div>
                  <div class="col col-md-3">
                    <div class="form-group mb-4">
                      <label for="email">Email</label>
                      <input
                        v-model="contact.email"
                        type="email"
                        class="form-control"
                        :name="`contacts.${index}.email`"
                      />
                      <small
                        v-if="errors.has(`contacts.${index}.email`)"
                        id="sh-text1"
                        class="form-text text-danger"
                        >{{ errors.get(`contacts.${index}.email`) }}</small
                      >
                    </div>
                  </div>
                  <div class="col col-md-3">
                    <div class="form-group mb-4">
                      <label for="phone">Phone</label>
                      <input
                        v-model="contact.phone"
                        type="text"
                        class="form-control"
                        :name="`contacts.${index}.phone`"
                      />
                      <small
                        v-if="errors.has(`contacts.${index}.phone`)"
                        id="sh-text1"
                        class="form-text text-danger"
                      >
                        {{ errors.get(`contacts.${index}.phone`) }}</small
                      >
                    </div>
                  </div>
                  <div class="col col-md-3">
                    <div class="form-group mb-4">
                      <label for="alternate_phone">Alternate Phone</label>
                      <input
                        v-model="contact.alternate_phone"
                        type="text"
                        class="form-control"
                        :name="`contacts.${index}.alternate_phone`"
                      />
                      <small
                        v-if="errors.has(`contacts.${index}.alternate_phone`)"
                        id="sh-text1"
                        class="form-text text-danger"
                        >{{
                          errors.get(`contacts.${index}.alternate_phone`)
                        }}</small
                      >
                    </div>
                  </div>
                  <div class="col col-md-12 text-right">
                    <div class="row ml-4">
                      <div v-show="index == contacts.length - 1">
                        <button
                          class="btn btn-warning mb-2 mr-2 btn-rounded"
                          @click.prevent="add"
                        >
                          Add More
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-plus-circle"
                          >
                            <circle cx="12" cy="12" r="10" />
                            <line x1="12" y1="8" x2="12" y2="16" />
                            <line x1="8" y1="12" x2="16" y2="12" />
                          </svg>
                        </button>
                      </div>
                      <div v-show="index || (!index && contacts.length > 1)">
                        <button
                          class="btn btn-danger mb-2 mr-2 btn-rounded"
                          @click.prevent="remove"
                        >
                          Remove
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-x-circle"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="15" y1="9" x2="9" y2="15"></line>
                            <line x1="9" y1="9" x2="15" y2="15"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="card_1" class="col-lg-12 layout-spacing layout-top-spacing">
        <div class="statbox widget box box-shadow">
          <div class="widget-content widget-content-area">
            <div class="row">
              <div class="col text-right">
                <div class="row">
                  <div class="col">
                    <router-link :to="{ name: 'Institutes' }">
                      <button type="button" class="btn btn-warning mr-2 btn-lg">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      type="submit"
                      class="btn btn-primary mr-2 btn-lg"
                      :disabled="errors.any()"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import { mapGetters, mapState } from "vuex";
import Notify from "../../helpers/Notify";
import Request from "../../apis/Request";
import baseURL from "../../apis/baseURL";

class Errors {
  constructor() {
    this.errors = {};
  }

  has(field) {
    return Object.prototype.hasOwnProperty.call(this.errors, field);
  }

  any() {
    return Object.keys(this.errors).length > 0;
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  record(errors) {
    this.errors = errors.errors;
  }

  clear(field) {
    delete this.errors[field];
  }
}

export default {
  name: "EditInstitute",
  components: { Loader },
  data() {
    return {
      represent_country_id: "",
      institute_name: "",
      campus_name: "",
      website: "",
      monthly_living_cost: "",
      funds_requirements_for_visa: "",
      application_fee: "",
      currency: "",
      is_lang_mendatory: "",
      lang_requirements: "",
      institute_benifits: "",
      per_time_work_details: "",
      scholarship_policy: "",
      institute_important_notes: "",
      institute_logo: "",
      institute_prospectus: "",
      institute_course_pdf: "",
      countries: [],
      contacts: [
        {
          name: "",
          email: "",
          phone: "",
          alternate_phone: ""
        }
      ],

      errors: new Errors(),
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"]),
    ...mapState(["baseURL"])
  },

  mounted() {
    this.isLoading = true;
    const id = this.$route.params.id;
    Request.GET_REQ("/active-represent-countries").then(res => {
      this.countries = res.data;
      console.log(this.countries);
    });
    Request.GET_REQ(`/institute/${id}`)
      .then(res => {
        this.represent_country_id = res.data.represent_country_id;
        this.institute_name = res.data.institute_name;
        this.campus_name = res.data.campus_name;
        this.website = res.data.website;
        this.monthly_living_cost = res.data.monthly_living_cost;
        this.funds_requirements_for_visa = res.data.funds_requirements_for_visa;
        this.application_fee = res.data.application_fee;
        this.currency = res.data.currency;
        this.is_lang_mendatory = res.data.is_lang_mendatory;
        this.lang_requirements = res.data.lang_requirements;
        this.institute_benifits = res.data.institute_benifits;
        this.per_time_work_details = res.data.per_time_work_details;
        this.scholarship_policy = res.data.scholarship_policy;
        this.institute_important_notes = res.data.institute_important_notes;
        this.institute_logo = res.data.institute_logo;
        this.institute_prospectus = res.data.institute_prospectus;
        this.institute_course_pdf = res.data.institute_course_pdf;
        this.contacts = res.data.contacts;
        this.isLoading = false;
      })
      .catch(err => {
        console.log(err);
      });
  },

  methods: {
    handleSubmit() {
      this.isLoading = true;
      const id = this.$route.params.id;
      let data = new FormData();

      data.append("represent_country_id", this.represent_country_id);
      data.append("institute_name", this.institute_name);
      data.append("campus_name", this.campus_name);
      data.append("website", this.website);
      data.append("monthly_living_cost", this.monthly_living_cost);
      data.append(
        "funds_requirements_for_visa",
        this.funds_requirements_for_visa
      );
      data.append("application_fee", this.application_fee);
      data.append("currency", this.currency);
      data.append("is_lang_mendatory", this.is_lang_mendatory);
      data.append("lang_requirements", this.lang_requirements);
      data.append("institute_benifits", this.institute_benifits);
      data.append("per_time_work_details", this.per_time_work_details);
      data.append("scholarship_policy", this.scholarship_policy);
      data.append("institute_important_notes", this.institute_important_notes);
      data.append("institute_logo", this.institute_logo);
      data.append("institute_prospectus", this.institute_prospectus);
      data.append("institute_course_pdf", this.institute_course_pdf);
      data.append("contacts", JSON.stringify(this.contacts));

      Request.POST_REQ(data, `/update-institute/${id}`)
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch("baseurl", baseURL.baseURL);
          this.$router.push({ name: "Institutes" });
          Notify.success("The Institute Successfully Updated");
        })
        .catch(error => {
          this.errors.record(error.response.data);
          console.log(this.errors);

          this.isLoading = false;

          this.errors.has("represent_country_id") &&
            Notify.error(this.errors.get("represent_country_id"));

          this.errors.has("institute_name") &&
            Notify.error(this.errors.get("institute_name"));

          this.errors.has("campus_name") &&
            Notify.error(this.errors.get("campus_name"));

          this.errors.has("website") &&
            Notify.error(this.errors.get("website"));

          this.errors.has("monthly_living_cost") &&
            Notify.error(this.errors.get("monthly_living_cost"));

          this.errors.has("funds_requirements_for_visa") &&
            Notify.error(this.errors.get("funds_requirements_for_visa"));

          this.errors.has("application_fee") &&
            Notify.error(this.errors.get("application_fee"));

          this.errors.has("currency") &&
            Notify.error(this.errors.get("currency"));

          this.errors.has("is_lang_mendatory") &&
            Notify.error(this.errors.get("is_lang_mendatory"));

          this.errors.has("lang_requirements") &&
            Notify.error(this.errors.get("lang_requirements"));

          this.errors.has("institute_benifits") &&
            Notify.error(this.errors.get("institute_benifits"));
          this.errors.has("per_time_work_details") &&
            Notify.error(this.errors.get("per_time_work_details"));

          this.errors.has("scholarship_policy") &&
            Notify.error(this.errors.get("scholarship_policy"));
          this.errors.has("institute_important_notes") &&
            Notify.error(this.errors.get("institute_important_notes"));

          this.errors.has("institute_logo") &&
            Notify.error(this.errors.get("institute_logo"));
          this.errors.has("institute_prospectus") &&
            Notify.error(this.errors.get("institute_prospectus"));

          this.errors.has("institute_course_pdf") &&
            Notify.error(this.errors.get("institute_course_pdf"));
        });
    },
    getErrors(index, field) {
      let property = `contacts.${index}.${field}`;
      return this.errors[property];
    },
    onFileChange(event) {
      this.$store.dispatch("baseurl", "");
      this.institute_logo = null;
      let file = event.target.files[0];
      if (file.size > 1048770) {
        console.log("file is bigger than 2mb");
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.institute_logo = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    onChangeProspectus(event) {
      let file = event.target.files[0];
      console.log(file);
      if (file.size > 1048770) {
        console.log("file is bigger than 2mb");
      } else {
        this.institute_prospectus = event.target.files[0];
      }
    },
    onChangeCourseModule(event) {
      let file = event.target.files[0];
      console.log(file);
      if (file.size > 1048770) {
        console.log("file is bigger than 2mb");
      } else {
        this.institute_course_pdf = event.target.files[0];
      }
    },

    add() {
      this.contacts.push({
        name: "",
        email: "",
        phone: "",
        alternate_phone: ""
      });
    },
    remove(index) {
      this.contacts.splice(index, 1);
    }
  }
};
</script>
<style scoped lang="scss">
.component-card_1 {
  width: auto;
}
body.dark .office-logo img {
  border: 1px solid #1b2e4b;
}
.office-logo img {
  width: 100px;
  border: 1px solid #bfc9d4;
  border-radius: 5px;
  padding: 5px;
}
.office-logo {
  width: 100%;
  text-align: right;
}
</style>
