<template>
  <div v-if="pendingStatus || isLoading">
    <Loader />
  </div>
  <div class="container-fluid error-content error404 text-center">
    <div class="">
      <h1 class="error-number">404</h1>
      <p class="mini-text">Ooops!</p>
      <p class="error-text mb-4 mt-1">The page you requested was not found!</p>
      <a href="index.html" class="btn btn-primary mt-5">Go Back</a>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";

import { mapGetters, mapState } from "vuex";
export default {
  components: { Loader },
  computed: {
    ...mapGetters(["user", "pendingStatus"]),
    ...mapState(["baseURL"])
  }
};
</script>
<style scoped>
/* @import "/assets/css/pages/error/style-400.css"; */
</style>
