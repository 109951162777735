import axios from "axios";

import baseURL from "./baseURL"

let Api = axios.create({
  baseURL: baseURL.baseApiURL
});

Api.defaults.withCredentials = true;
// Api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default Api;
