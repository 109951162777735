<template>
  <Navigation />

  <div id="container" class="main-container">
    <div id="content" class="main-content">
      <bread-crumb @thememode="thememode" />
      <transition name="fade" mode="out-in">
        <router-view :theme-mode-data="themeModeData" />
      </transition>
    </div>
  </div>
  <StickyBranchName />
</template>

<script>
import Navigation from "@/components/Navigation.vue";
// import User from "./apis/User";
import BreadCrumb from "./components/BreadCrumb.vue";
import StickyBranchName from "@/components/StickyBranchName.vue";
import { mapGetters } from "vuex";
// import Echo from "laravel-echo";
export default {
  components: { Navigation, BreadCrumb, StickyBranchName },
  data() {
    return {
      loading: false,
      themeModeData: false,
      branchName:
        JSON.parse(localStorage.getItem("branch")) &&
        JSON.parse(localStorage.getItem("branch")).branch_name
          ? JSON.parse(localStorage.getItem("branch")).branch_name
          : "",
      branch_id: ""
    };
  },
  computed: {
    ...mapGetters(["user"])
  },
  async mounted() {
    const $ = window.$;
    await this.$store.dispatch("user");
    if (!localStorage.getItem("user")) {
      localStorage.setItem("user", false);
    }

    let body = document.getElementsByTagName("body")[0];
    if (localStorage.getItem("darker") == "true") {
      body.classList.add("dark");
      this.themeModeData = true;
    } else {
      body.classList.remove("dark");
    }
    this.$nextTick(() => {
      $(document).click(function() {
        $(".topbar-nav .collapse").collapse("hide");
      });
      $(
        ".navbar .dropdown.notification-dropdown > .dropdown-menu, .navbar .dropdown.message-dropdown > .dropdown-menu "
      ).click(function(e) {
        e.stopPropagation();
      });
    });

    await this.$store.dispatch("unreadMsg");
    await this.$store.dispatch("unreadNotify");
  },
  methods: {
    thememode(getMode) {
      this.themeModeData = getMode;
    }
  }
};
</script>

<style>
/* #app {
  overflow: hidden;
  width: 100vw;
} */
.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active {
  transition: all 2s ease;
}

.fade-leave-from {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all 2s ease;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #292929;
  outline: 1px solid #292929;
}
body.dark
  .topbar-nav.header
  nav#topbar
  ul.menu-categories
  li.menu
  > a.router-link-active.router-link-exact-active
  > div
  svg,
body.dark
  .topbar-nav.header
  nav#topbar
  ul.menu-categories
  li.menu
  > a.router-link-active.router-link-exact-active
  > div
  span,
body.dark
  .topbar-nav.header
  nav#topbar
  ul.menu-categories
  li
  > a.router-link-exact-active {
  color: #25d5e4 !important;
}

.topbar-nav.header
  nav#topbar
  ul.menu-categories
  li.menu
  > a.router-link-active.router-link-exact-active
  > div
  svg,
.topbar-nav.header
  nav#topbar
  ul.menu-categories
  li.menu
  > a.router-link-active.router-link-exact-active
  > div
  span,
.topbar-nav.header
  nav#topbar
  ul.menu-categories
  li
  > a.router-link-exact-active {
  color: #474fb1 !important;
}

body.dark .page-link {
  background-color: #0e1726 !important;
  border: 1px solid #1b2e4b;
  color: #5cb7ff;
}
body.dark .page-item.disabled .page-link {
  border: 1px solid #1b2e4b;
}
.lms-pagination {
  text-align: right;
}
.pagination {
  justify-content: flex-end;
}

li.breadcrumb-item.active > a {
  color: #474fb1 !important;
}
li.page-item.active .page-link {
  background-color: #0095cc !important;
  border-color: #0095cc !important;
}
body.dark li.breadcrumb-item.active > a {
  color: #25d5e4 !important;
}
.vue-select {
  border: 1px solid #bfc9d4 !important;
  width: 100% !important;
  background: transparent;
}
body.dark .vue-select {
  border: 0 !important;
  background: #1b2e4b;
}
body.dark .vue-input input {
  height: calc(1.4em + 1.4rem + 2px);
  padding: 0.75rem 1.25rem;
  background: transparent;
  font-size: 15px;
  color: #009688 !important;
}
.vue-input input {
  height: calc(1.4em + 1.4rem + 2px);
  padding: 0.75rem 1.25rem !important;
  background: transparent;
  font-size: 15px !important;
  color: #3b3f5c !important;
}
.vue-input {
  padding: 0 !important;
}
body.dark .vue-dropdown {
  background-color: #1b2e4b;
}
body.dark .vue-dropdown-item.highlighted {
  background-color: #23cada;
  color: #000;
}
.vue-dropdown-item.highlighted {
  background-color: #23cada;
  color: #000;
}
span.assign-lead-number {
  background: #1aa1d9;
  border-radius: 50%;
  width: 23px;
  height: 20px;
  display: inline-block;
  text-align: center;
  line-height: 1.5;
}
.dropdown-menu.notification-dropdown {
  max-height: 550px;
  overflow-y: auto;
}

.dropdown-menu.notification-dropdown::-webkit-scrollbar {
  width: 0.5em;
}
*::-webkit-scrollbar {
  width: 0.5em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.dropdown-menu.notification-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #292929;
  outline: 1px solid #292929;
}
.dropdown-menu.notification-dropdown::-webkit-scrollbar-thumb {
  background-color: #292929;
  outline: 1px solid #292929;
}
</style>
