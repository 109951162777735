<template>
  <div class="card no-outer-spacing no-border-custom">
    <div class="card-header">
      <section class="mb-0 mt-0">
        <h5>
          Follow up
        </h5>
      </section>
    </div>
    <div class="application-follow-up-wrap">
      <div class="card-body custom-card-body p-0">
        <form
          @submit.prevent="
            editFollow == true ? handleUpdateFollowUp() : handleFollowUp()
          "
        >
          <div class="col col-md-12 p-0">
            <div class="form-group lead-drawer-form">
              <textarea
                v-model="follow_up"
                name="follow_up"
                cols="30"
                rows="3"
                placeholder="Type here..."
                class="form-control"
              ></textarea>
              <small
                v-if="errors.follow_up"
                id="sh-text1"
                class="form-text text-danger"
                >{{ errors.follow_up[0] }}</small
              >
            </div>
            <button class="btn badge badge-info btn-sm">
              {{ editFollow == true ? "Update" : "Save" }}
              <span v-if="lazyLoadFollowup">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-loader spin mr-2"
                >
                  <line x1="12" y1="2" x2="12" y2="6"></line>
                  <line x1="12" y1="18" x2="12" y2="22"></line>
                  <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                  <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                  <line x1="2" y1="12" x2="6" y2="12"></line>
                  <line x1="18" y1="12" x2="22" y2="12"></line>
                  <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                  <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                </svg>
              </span>
            </button>
          </div>
          <hr />
          <div class="col col-md-12 p-0">
            <!-- Images -->
            <ul class="list-group list-group-media drawer-follow-up-list">
              <li
                v-for="followup in followUps"
                :key="followup.id"
                class="list-group-item list-group-item-action"
              >
                <span
                  v-if="user.id == followup.user_id"
                  class="follow-edit"
                  @click="editFollowup(followup.id)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit-3 icon custom-edit-icon"
                  >
                    <path d="M12 20h9"></path>
                    <path
                      d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"
                    ></path>
                  </svg>
                </span>
                <div class="media">
                  <div class="mr-2">
                    <img
                      alt="avatar"
                      src="@/assets/img/90x90.jpg"
                      class="img-fluid rounded-circle"
                    />
                  </div>
                  <div class="media-body">
                    <h6 class="tx-inverse">
                      {{ followup.user && followup.user.name }}
                    </h6>
                    <p class="mg-b-0">{{ followup.follow_up }}</p>
                    <small class="text-left">
                      {{ dateTime(followup.created_at) }}
                    </small>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </form>
        <br />
        <div class="row">
          <div class="col col-md-12">
            <div class="lms-pagination">
              <pagination
                v-model="page"
                :records="totalFollowUps"
                :per-page="3"
                class="custom-pagination"
                @paginate="followUpInfo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Request from "@/apis/Request.js";
import { mapGetters } from "vuex";
import Notify from "../../helpers/Notify";
import moment from "moment";
export default {
  props: {
    leadId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      follow_up: "",
      followup_id: "",
      page: 1,
      totalFollowUps: 0,
      errors: {},
      followUps: [],
      lazyLoadFollowup: false,
      editFollow: false
    };
  },
  computed: {
    ...mapGetters(["user", "pendingStatus"])
  },
  /* watch: {
    leadId: {
      handler() {
        this.followUpInfo();
        this.totalFollowup();
      }
    }
  }, */
  mounted() {
    if (this.leadId) {
      this.followUpInfo();
      this.totalFollowup();
    }
    this.emitter.on("appFollowupReload", () => {
      this.followUpInfo();
      this.totalFollowup();
    });
  },
  methods: {
    handleFollowUp() {
      this.lazyLoadFollowup = true;
      let data = {
        lead_id: this.leadId,
        user_id: this.user.id,
        follow_up: this.follow_up
      };
      Request.POST_REQ(data, "/add-follow-up")
        .then(() => {
          this.lazyLoadFollowup = false;
          this.follow_up = "";
          this.emitter.emit("appFollowupReload");
          Notify.success("The Lead Successfully Created");
          this.errors = {};
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.lazyLoadFollowup = false;
          this.errors.follow_up && Notify.error(this.errors.follow_up[0]);
        });
    },

    followUpInfo() {
      Request.GET_REQ(`/follow-up-info/${this.leadId}?page=${this.page}`)
        .then(res => {
          this.followUps = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    totalFollowup() {
      Request.GET_REQ(`/follow-up-info-total/${this.leadId}`)
        .then(res => {
          this.totalFollowUps = res.data.length;
        })
        .catch(err => {
          console.log(err);
        });
    },
    dateTime(value) {
      return moment(value).format("Do MMMM YYYY, h:mm:ss a");
    },
    editFollowup(id) {
      Request.GET_REQ(`/follow-up-info-edit/${id}`)
        .then(res => {
          this.editFollow = true;
          this.follow_up = res.data.follow_up;
          this.followup_id = id;
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleUpdateFollowUp() {
      this.lazyLoadFollowup = true;
      let data = {
        follow_up: this.follow_up
      };
      Request.POST_REQ(data, `update-follow-up/${this.followup_id}`)
        .then(() => {
          this.lazyLoadFollowup = false;
          this.follow_up = "";
          this.editFollow = false;
          this.emitter.emit("appFollowupReload");
          Notify.success("The Lead Successfully Updated");
          this.errors = {};
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.lazyLoadFollowup = false;
          this.errors.follow_up && Notify.error(this.errors.follow_up[0]);
        });
    }
  }
};
</script>

<style lang="scss">
.list-group.list-group-media.drawer-follow-up-list {
  .list-group-item .media .media-body {
    h6 {
      font-size: 12px;
      margin-bottom: 3px;
    }
    p {
      font-size: 11px;
      word-break: break-all;
    }
  }
}
.drawer-follow-up-list {
  small {
    display: block;
  }
}
span.follow-edit {
  position: absolute;
  right: 3px;
  top: 3px;
}
.no-border-custom {
  border: none !important;
}
</style>
